import React from 'react';
import {
  Col,
  Button
} from "reactstrap";
import { withTranslation } from "react-i18next";
import { Field } from "redux-form";
import renderSelectField from "../../../../components/form/Select";

const ChooseOptionCreateMedia = ({
  t,
  onChangeSelect,
  toggleCreateMediaRef,
  confirmSelection
}) => (
  <div>
    <div className="card__title">
      <h5 className="bold-text">{t("MEDIAS.ADD_MEDIA_REFERENCE")}</h5>
      <h5 className="subhead">
        {t("MEDIAS.PLEASE_CHOOSE_OPTION_OF_CREATING_MEDIA_REFERENCE")}
      </h5>
    </div>

    <div className="form__form-group-field">
      <Field
        name="create_media_ref_options"
        component={renderSelectField}
        options={[
          {
            value: "choose_existing",
            label: "Choose existing media",
          },
          {
            value: "create_new",
            label: "Create new media reference",
          },
        ]}
        onChange={(val) =>
          onChangeSelect(val, "create_media_ref_options")
        }
      />
    </div>
    <Col md={12} className="p-0">
      <div className="d-flex justify-content-end mt-3">
        <Button
          className="mb-0"
          onClick={() => toggleCreateMediaRef()}
        >
          {t("BUTTON.CANCEL")}
        </Button>
        <Button
          color="primary"
          className="mb-0"
          onClick={() => confirmSelection()}
        >
          {t("BUTTON.CONFIRM")}
        </Button>
      </div>
    </Col>
  </div>
);

export default withTranslation("common")(ChooseOptionCreateMedia);