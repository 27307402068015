import React from "react";
import { NavLink } from "react-router-dom";
import { withTranslation } from "react-i18next";
import PhoneInputForm from "./PhoneInputForm";

const ResetPassword = (props) => {
  const { t } = props;
  return (
    <div className="account account--not-photo">
      <div className="account__wrapper">
        <div className="account__card">
          <div className="account__head">
            <h3 className="account__title">
              {t("GENERAL.WELCOME_TO")}
              <span className="account__logo"> E-Library</span>
            </h3>
            <h4 className="account__subhead subhead">
              {t("FORGET_PASSWORD.RESET_PASSWORD")}
            </h4>
          </div>
          <PhoneInputForm />
          <div className="account__have-account">
            <p>
              {t("FORGET_PASSWORD.REMEMBER_YOUR_PASSWORD")}{" "}
              <NavLink to="/login">{t("LOGIN.SIGN_IN")}</NavLink>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation("common")(ResetPassword);
