import axios from "axios";
import authHeader from "./authHeader";

// const API_URL = "http://elibrary.camemis-learn.home:8888/api";

//const API_URL = "https://elibrary.camemis-learn.com/api";
const API_URL = "https://dlibrary-api.mptc.gov.kh/api";
const getSearch = (endpoint, params) => {
  return axios.get(API_URL + "/" + endpoint + `?${params}`, { headers: authHeader() });
};

const getAll = (endpoint) => {
  return axios.get(API_URL + "/" + endpoint, { headers: authHeader() });
};

const post = (endpoint, data) => {
  return axios.post(API_URL + "/" + endpoint, data, { headers: authHeader() });
};

const register = (endpoint, data) => {
  return axios.post(API_URL + "/" + endpoint, data, { headers: { 'Content-Type': 'application/json' } });
};

const get = (endpoint, id) => {
  return axios.get(API_URL + "/" + endpoint + `/${id}`, { headers: authHeader(), timeout: 6000 });
};

const update = (endpoint, id, data) => {
  return axios({
    url: API_URL + "/" + endpoint + '/' + id
    , method: 'put'
    , data: data
    , headers: authHeader()
  })
};

const remove = (endpoint, id) => {
  return axios.delete(API_URL + "/" + endpoint + `/${id}`, { headers: authHeader() });
};

const removeAll = (endpoint) => {
  return axios.delete(API_URL + "/" + endpoint, { headers: authHeader() });
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const getToken = () => {
  let data = JSON.parse(localStorage.getItem("user"));
  return data ? data.token : null;
};

const logout = () => {
  return localStorage.removeItem("user");
};

export default {
  getAll,
  get,
  post,
  update,
  remove,
  removeAll,
  getToken,
  getCurrentUser,
  logout,
  getSearch,
  register
};
