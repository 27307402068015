import React, { PureComponent } from "react";
import { Redirect } from "react-router-dom";
import { reduxForm } from "redux-form";
import { Col, Container, Row } from "reactstrap";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import TableView from "./components/TableCategory";
import FilterView from "./components/FilterCategory";
import DetailView from "./components/DetailCategory";
import { RTLProps, ThemeProps } from "../../shared/prop-types/ReducerProps";
import { fetchActionCategories } from "../../redux/actions/fetchApiActions";
import {
  setActionHistory,
  setTypeComponentView,
} from "../../redux/actions/storeActions";
import Service from "../../config/Service";
import Utilities from "../../shared/helpers/Utilities";
import LoadingView from "../../components/LoadingView/LoadingView";

class Index extends PureComponent {
  static propTypes = {
    theme: ThemeProps.isRequired,
    t: PropTypes.func.isRequired,
    rtl: RTLProps.isRequired,
  };

  constructor(props) {
    super(props);
    const { t } = this.props;
    this.state = {
      COMPONENT_VIEW: false,
      CLICK_HISTORY_DATA: [
        {
          id: 0,
          name: t("SIDE_BAR.LIST_OF_CATEGORIES"),
          parent: "x",
        },
      ],
      clickId: 0,
      itemsPerPage: 12,
      pageOfItems: 1,
      isFetchingData: true,
    };
  }

  componentDidMount() {
    const permissionData = Utilities.getPermission(
      this.props.user,
      "categories"
    );
    this.setState({
      permissions: permissionData,
    });
    const { dispatch } = this.props;
    dispatch(setTypeComponentView("DEFAULT_VIEW"));
    this.getCategoryList();
  }

  getCategoryList = () => {
    const { pageOfItems, itemsPerPage, clickId } = this.state;
    const params = `perPage=${itemsPerPage}&page=${pageOfItems}&parent=${clickId}`;
    this.fetchCategories(params);
  };

  refreshAfterCreate = () => {
    const { itemsPerPage, clickId } = this.state;
    this.setState({ pageOfItems: 1 });
    const params = `perPage=${itemsPerPage}&parent=${clickId}`;
    this.fetchCategories(params);
  };

  onChangePage = async (pageOfItems) => {
    if (pageOfItems) {
      await this.setState({ pageOfItems });
      await this.getCategoryList();
    }
  };

  defaultView = (facette) => {
    const { itemsPerPage, pageOfItems } = this.state;
    const { dispatch } = this.props;
    dispatch(setTypeComponentView("DEFAULT_VIEW"));

    let CLICK_HISTORY_DATA = this.state.CLICK_HISTORY_DATA;
    Utilities.clearArray(CLICK_HISTORY_DATA);
    const searchParams = `perPage=${itemsPerPage}&page=${pageOfItems}&parent=0`;

    this.fetchCategories(searchParams);
    this.setState({
      pageOfItems,
    });
  };

  goBackToView = (facette) => {
    if (!facette.id) {
      this.setState({
        clickId: 0,
        pageOfItems: 1,
      });
      this.defaultView(facette);
    } else {
      this.detailView(facette);
    }
  };

  /**
   * BEGIN DETAIL CATEGORY
   */

  detailView = (facette) => {
    const { t, dispatch } = this.props;
    const { CLICK_HISTORY_DATA, itemsPerPage } = this.state;
    const index = CLICK_HISTORY_DATA.findIndex(
      (item) => item.id === facette.id
    );
    let CLICK_HISTORY_DATA_TEMP = [
      ...this.state.CLICK_HISTORY_DATA,
      {
        id: 0,
        name: t("SIDE_BAR.LIST_OF_CATEGORIES"),
        parent: "x",
      },
      {
        id: facette.id,
        name: facette.name,
        parent: facette.parent,
      },
    ];
    if (index > -1) {
      CLICK_HISTORY_DATA_TEMP = CLICK_HISTORY_DATA.filter((item, itemIndex) => {
        if (itemIndex <= index) {
          return item;
        }
      });
    }

    const newClick = Utilities.getUniqueListBy(CLICK_HISTORY_DATA_TEMP, "id");
    this.setState({
      CLICK_HISTORY_DATA: newClick,
    });

    dispatch(setActionHistory(newClick));
    dispatch(setTypeComponentView("DETAIL_VIEW"));

    this.getDetailSelectedCategory(facette.id);
    this.setState({ clickId: facette.id });

    const searchParams = `perPage=${itemsPerPage}&parent=${facette.id}`;
    this.fetchCategories(searchParams);
  };

  getDetailSelectedCategory = (id) => {
    Service.get("categories", id)
      .then((response) => {
        const data = response.data.data;
        if (response) {
          this.props.initialize({
            name: data.name,
            name_en: data.name_en,
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  actionSearch = (value) => {
    console.log("action search");
    const { itemsPerPage } = this.state;
    let name = value.filter_name ? value.filter_name : "";
    let name_en = value.filter_name_en ? value.filter_name_en : "";
    let filterParams = `perPage=${itemsPerPage}&parent=0&name=${name}&name_en=${name_en}`;

    this.setState({ value, pageOfItems: 1 });
    this.fetchCategories(filterParams);
  };

  fetchCategories = (params) => {
    const { dispatch } = this.props;
    Service.getSearch("categories", params)
      .then((response) => {
        if (response) {
          this.setState({
            isFetchingData: false,
          });
          dispatch(fetchActionCategories(response.data, params));
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  render() {
    const { t, theme, rtl } = this.props;
    const {
      itemsPerPage,
      pageOfItems,
      isFetchingData,
      permissions,
      value,
    } = this.state;
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      const permissions = user.permissions.split(",");
      if (!permissions.includes("GXJyvs92fVBU3mk8DhzN")) {
        return <Redirect push to="/module/permission" />;
      }
    }

    if (isFetchingData) {
      return (
        <div style={{ margin: "0 15px" }}>
          <LoadingView />
        </div>
      );
    } else {
      switch (this.props.COMPONENT_VIEW) {
        case "DETAIL_VIEW":
          return (
            <Container className="todo-app">
              <Row>
                <DetailView
                  theme={theme}
                  rtl={rtl.direction}
                  goBackToView={this.goBackToView}
                  clickId={this.state.clickId}
                  itemsPerPage={itemsPerPage}
                  pageOfItems={pageOfItems}
                  permissions={permissions}
                  refreshAfterCreate={this.refreshAfterCreate}
                />
              </Row>
              <Row>
                <TableView
                  detailView={this.detailView}
                  onChangePage={this.onChangePage}
                  itemsPerPage={itemsPerPage}
                  pageOfItems={pageOfItems}
                  permissions={permissions}
                  filterValue={null}
                />
              </Row>
            </Container>
          );
        default:
          return (
            <Container className="todo-app">
              <Row>
                <Col md={12}>
                  <h3 className="page-title">
                    {t("SIDE_BAR.LIST_OF_CATEGORIES")}
                  </h3>
                </Col>
              </Row>
              <Row>
                <FilterView
                  theme={theme}
                  rtl={rtl.direction}
                  actionSearch={this.actionSearch}
                  permissions={permissions}
                  refreshAfterCreate={this.refreshAfterCreate}
                />
              </Row>
              <Row>
                <TableView
                  detailView={this.detailView}
                  onChangePage={this.onChangePage}
                  itemsPerPage={itemsPerPage}
                  pageOfItems={pageOfItems}
                  permissions={permissions}
                  filterValue={value}
                />
              </Row>
            </Container>
          );
      }
    }
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.theme,
    rtl: state.rtl,
    COMPONENT_VIEW: state.getData.COMPONENT_VIEW,
    AUTH_USER: state.getData.AUTH_USER,
    user: state.user,
  };
};

Index = reduxForm({
  shouldValidate: () => true, // to alway validation form even user cancel the process
  form: "horizontal_form",
  enableReinitialize: true,
  destroyOnUnmount: false,
  keepDirtyOnReinitialize: true,
})(Index);

export default connect(mapStateToProps)(withTranslation("common")(Index));
