import React, { PureComponent } from "react";
import {
  Card,
  CardBody,
  Col,
  Button,
  Container,
  Row,
  Modal,
  Spinner,
} from "reactstrap";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import PlusIcon from "mdi-react/PlusIcon";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import NotificationSystem from "rc-notification";
import classNames from "classnames";
import { BasicNotification } from "../../../components/Notification";
import { fetchActionCategories } from "../../../redux/actions/fetchApiActions";
import Service from "../../../config/Service";
import validate from "./validate";
import { RenderInputField } from "../../../components/RenderField";

let notification = null;
class AddForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
  };

  constructor(props) {
    super();
    this.state = {
      isUploading: false,
      isEditLoading: false,
      modal: false,
    };
  }

  componentDidMount() {
    NotificationSystem.newInstance(
      { style: { top: 65 } },
      (n) => (notification = n)
    );
  }

  toggleGoBack = (clickObj) => {
    this.props.goBackToView(clickObj);
  };

  toggleModal = () => {
    this.props.destroy();
    this.loadinData(this.props.clickId);
    this.setState((prevState) => ({ modal: !prevState.modal }));
  };

  showNotification = (rtl) => {
    const { t } = this.props;
    notification.notice({
      content: (
        <BasicNotification
          title={t("GENERAL.NOTIFICATION")}
          color="primary"
          message={t("GENERAL.THE_ACTION_HAS_BEEN_COMPLETED_SUCCESSFULLY")}
        />
      ),
      duration: 5,
      closable: true,
      style: { top: 0, left: "calc(100vw - 100%)" },
      className: `right-up ${rtl}-support`,
    });
  };

  loadinData = (id) => {
    Service.get("categories", id)
      .then((response) => {
        const data = response.data.data;
        if (response) {
          this.props.initialize({
            name: data.name,
            name_en: data.name_en,
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  onCancel = () => {
    this.toggleModal();
    this.props.destroy();
    this.loadinData(this.props.clickId);
  };

  actionSubmit = (value) => {
    const { dispatch, clickId, itemsPerPage, pageOfItems } = this.props;
    let name = value.name;
    let name_en = value.name_en;

    const data = {
      name: name,
      name_en: name_en,
    };
    this.setState({ isEditLoading: true });
    Service.update("categories", clickId, data)
      .then((response) => {
        if (response) {
          const searchParams = `perPage=${itemsPerPage}&page=${pageOfItems}&parent=${clickId}`;
          Service.getSearch("categories", searchParams)
            .then((response) => {
              this.showNotification();
              this.setState({ isEditLoading: false });
              dispatch(fetchActionCategories(response.data, searchParams));
            })
            .catch((e) => {
              console.log(e);
            });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  actionAddCetegory = (value) => {
    const { refreshAfterCreate, clickId } = this.props;

    let name = value.post_name;
    let name_en = value.post_name_en;

    const data = {
      name: name,
      name_en: name_en,
      parent: clickId,
    };
    this.setState({ isUploading: true });
    Service.post("categories", data)
      .then((response) => {
        if (response) {
          refreshAfterCreate();
          this.setState({ isUploading: false });
          this.showNotification();
          this.toggleModal();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  render() {
    const { modal, isUploading, isEditLoading } = this.state;
    const {
      t,
      CLICK_HISTORY_DATA,
      theme,
      rtl,
      handleSubmit,
      permissions,
    } = this.props;
    const modalClasses = classNames(
      {
        "todo__add-modal": true,
      },
      theme.className,
      `${rtl}-support`
    );

    return (
      <Container className="todo-app">
        <Row>
          <Col md={12}>
            <h3 className="page-title">{t("GENERAL.DETAIL_INFORMATION")}</h3>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                {CLICK_HISTORY_DATA.map((item, i) => (
                  <li className="breadcrumb-item" key={i}>
                    <button
                      style={{
                        color: "#70bbfd",
                        transition: "all 0.3s",
                        background: "transparent",
                        border: "none",
                      }}
                      onClick={() => this.toggleGoBack(item)}
                    >
                      {item.name}
                    </button>
                  </li>
                ))}
              </ol>
            </nav>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Card>
              <CardBody>
                <form
                  className="form"
                  onSubmit={handleSubmit(this.actionSubmit)}
                >
                  <Col md={6} sm={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t("GENERAL.NAME")}
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="name"
                          component={RenderInputField}
                          type="text"
                          placeholder={t("GENERAL.NAME")}
                        />
                      </div>
                    </div>
                  </Col>

                  <Col md={6} sm={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t("GENERAL.NAME_EN")}
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="name_en"
                          component={RenderInputField}
                          type="text"
                          placeholder={t("GENERAL.NAME_EN")}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="d-flex justify-content-end">
                      {permissions.edit && (
                        <Button color="primary" type="submit">
                          {isEditLoading ? (
                            <Spinner
                              size="sm"
                              animation="border"
                              varaint="primary"
                            />
                          ) : (
                            t("GENERAL.SAVE")
                          )}
                        </Button>
                      )}
                      {permissions.create && (
                        <Button
                          className="icon"
                          onClick={this.toggleModal}
                          color="success"
                        >
                          <p>
                            <PlusIcon /> {t("GENERAL.CREATE_A_NEW")}
                          </p>
                        </Button>
                      )}
                    </div>
                  </Col>
                </form>
              </CardBody>
            </Card>
          </Col>

          <Modal isOpen={modal} toggle={this.onCancel} className={modalClasses}>
            <Row>
              <Col md={12}>
                <h3 className="page-title">
                  {t("GENERAL.CREATE_NEW_CATEGORY")}
                </h3>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <form
                  className="form"
                  onSubmit={handleSubmit(this.actionAddCetegory)}
                >
                  <div className="form__form-group">
                    <span className="form__form-group-label typography-message">
                      {t("GENERAL.NAME")}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="post_name"
                        component={RenderInputField}
                        type="text"
                        placeholder={t("GENERAL.NAME")}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label typography-message">
                      {t("GENERAL.NAME_EN")}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="post_name_en"
                        component={RenderInputField}
                        type="text"
                        placeholder={t("GENERAL.NAME_EN")}
                      />
                    </div>
                  </div>
                  <Col md={12}>
                    <div className="d-flex justify-content-end">
                      {permissions.edit && (
                        <Button color="primary" type="submit">
                          {isUploading ? (
                            <Spinner
                              size="sm"
                              animation="border"
                              varaint="primary"
                            />
                          ) : (
                            t("GENERAL.SAVE")
                          )}
                        </Button>
                      )}
                      <Button type="button" onClick={this.onCancel}>
                        {t("BUTTON.CANCEL")}
                      </Button>
                    </div>
                  </Col>
                </form>
              </Col>
            </Row>
          </Modal>
        </Row>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    CLICK_HISTORY_DATA: state.getData.CLICK_HISTORY_DATA,
  };
}

AddForm = reduxForm({
  shouldValidate: () => true, // to alway validation form even user cancel the process
  form: "horizontal_form",
  validate,
  enableReinitialize: true,
  destroyOnUnmount: false,
  keepDirtyOnReinitialize: true,
})(AddForm);

export default connect(mapStateToProps)(withTranslation("common")(AddForm));
