import React from "react";
import { Route, Switch } from "react-router-dom";
import { Redirect } from "react-router-dom";
import DetailUser from "../../../UserManagement/components/DetailUser";
import SystemUserList from "../../../UserManagement/index";
import NotFound404 from "../../../DefaultPage/404page";

export default () => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (!user) {
    return <Redirect push to="/login" />;
  } else if (user) {
    const permissions = user.permissions.split(",");
    if (!permissions.includes("3Jprb7khmADSEgwGNjwJ")) {
      return <Redirect push to="/module/permission" />;
    } else {
      return (
        <Switch>
          <Route path="/module/systemuser" component={SystemUserList} exact />
          <Route
            path="/module/systemuser/:userId/edit"
            component={DetailUser}
          />
          <Route component={NotFound404} />
        </Switch>
      );
    }
  }
};
