import React from 'react';
import { PureComponent } from 'react';
import { Col, Container, CardTitle, Card, CardBody } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { PlaceholderProfile } from "../../../shared/img";
import ChangePasswordForm from './components/ChangePasswordForm';
import Image, { Shimmer } from 'react-shimmer';
class ChangePassword extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      user: {}
    }
  }

  render() {
    const { user, t } = this.props;
    return (
      <Container>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <div className="text-center p-3">
                <div className="justify-content-md-center row">
                  <div className="account__avatar">
                    <Image
                      src={user.s3_photo_url || PlaceholderProfile} alt="avatar"
                      style={{ objectFit: "cover" }}
                      fallback={<Shimmer width={300} height={300} />}
                    />
                  </div>
                </div>
                <CardTitle style={{ fontSize: 16 }} className="mb-0 mt-1">{user.fullname}</CardTitle>
                <p className="account__sub">{t("CHANGE_PASSWORD.CHANGE_YOUR_PASSWORD")}</p>
                <div class="row justify-content-md-center">
                  <ChangePasswordForm onSubmit />
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Container>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.detail
  }
}

export default connect(mapStateToProps)(withTranslation("common")(ChangePassword));
