import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, ButtonToolbar, Modal, Spinner } from "reactstrap";
import classNames from "classnames";
import { withTranslation } from "react-i18next";
import { RTLProps } from "../../shared/prop-types/ReducerProps";

const CustomActionModal = ({
  t,
  color,
  btn,
  title,
  message,
  colored,
  btnOutline,
  header,
  rtl,
  isAbleToDelete,
  unableToDeleteMessage,
  size,
  actionCallApi,
  toggle,
  modal,
  loading,
}) => {
  let Icon;

  switch (color) {
    case "primary":
      Icon = <span className="lnr lnr-lock modal__title-icon" />;
      break;
    case "success":
      Icon = <span className="lnr lnr-thumbs-up modal__title-icon" />;
      break;
    case "warning":
      Icon = <span className="lnr lnr-flag modal__title-icon" />;
      break;
    case "danger":
      Icon = <span className="lnr lnr-trash modal__title-icon" />;
      break;
    default:
      break;
  }
  const modalClass = classNames({
    "modal-dialog--colored": colored,
    "modal-dialog--header": header,
  });
  return (
    <div>
      <Button color={color} onClick={toggle} size={size} outline={btnOutline}>
        {btn}
      </Button>
      <Modal
        isOpen={modal}
        toggle={toggle}
        modalClassName={`${rtl.direction}-support`}
        className={`modal-dialog--${color} ${modalClass}`}
      >
        {isAbleToDelete && (
          <div className="modal__header">
            <button
              className="lnr lnr-cross modal__close-btn"
              type="button"
              onClick={toggle}
            />
            {header ? "" : Icon}
            <h4 className="text-modal  modal__title">{title}</h4>
          </div>
        )}
        <div className="modal__body">
          {isAbleToDelete ? message : unableToDeleteMessage}
        </div>
        {isAbleToDelete ? (
          <ButtonToolbar className="modal__footer">
            <Button className="modal_cancel" onClick={toggle}>
              {t("BUTTON.CANCEL")}
            </Button>
            <Button
              className="modal_ok"
              outline={colored}
              color={color}
              onClick={actionCallApi}
            >
              {loading ? (
                <Spinner animation="border" variant="primary" size="sm" />
              ) : (
                t("GENERAL.OK")
              )}
            </Button>
          </ButtonToolbar>
        ) : (
          <ButtonToolbar className="modal__footer">
            <Button
              className="modal_ok"
              outline={colored}
              color={color}
              onClick={toggle}
            >
              {t("GENERAL.OK")}
            </Button>
          </ButtonToolbar>
        )}
      </Modal>
    </div>
  );
};

CustomActionModal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  color: PropTypes.string.isRequired,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  btn: PropTypes.string.isRequired,
  rtl: RTLProps.isRequired,
  isAbleToDelete: PropTypes.bool,
  btnOutline: PropTypes.bool,
};

CustomActionModal.defaultProps = {
  title: "",
  message: "",
  colored: false,
  header: false,
  callbackApi: "",
  callBackFilter: "",
  isAbleToDelete: true,
  btnOutline: false,
};

export default connect((state) => ({
  rtl: state.rtl,
  t: state.t,
}))(withTranslation("common")(CustomActionModal));
