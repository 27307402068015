import React from 'react';
import PropTypes from 'prop-types';

const RenderTextArea = ({
  input, placeholder, type, meta: { touched, error },
}) => {
  return (
    <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
      <textarea {...input} placeholder={placeholder} type={type} />
      {touched && error && <span className="form__form-group-error">{error}</span>}
    </div>
  )
};

RenderTextArea.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

RenderTextArea.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'textarea',
};

export default RenderTextArea;