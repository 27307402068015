import React from "react";
import PropTypes from "prop-types";
import { BsCardImage } from "react-icons/bs";
import _ from "lodash";
import Resizer from "react-image-file-resizer";

let previewImage = null;
let tempImgFile = null;

const resizeFile = (file) => new Promise(resolve => {
  Resizer.imageFileResizer(file, 852, 480, 'JPEG', 80, 0,
  uri => {
    resolve(uri);
  },
  'file'
  );
});

const RetangleChooseImage = ({ onChange, name, value }) => {
  if (!_.isObject(value)) {
    previewImage = value;
  }

  const handleChange = async (e) => {
    e.preventDefault();
    const imgLength = e.target.files.length;
    const files = [...e.target.files];
    tempImgFile = imgLength === 1 ? files : tempImgFile;
    const file = tempImgFile[0];
    const compressImage = await resizeFile(file);
    previewImage = URL.createObjectURL(file);
    onChange({
      file: compressImage,
      name: file.name,
    });
  };
  return (
    <div className="media">
      <div className="media__choose-image">
        <div className="form__form-group-file">
          <label className="media__label-input " htmlFor={name}>
            {previewImage == null && _.isEmpty(value) ? (
              <div className="media__image-view">
                <BsCardImage
                  htmlFor={name}
                  className="media__choose-image-icon "
                />
              </div>
            ) : (
                <div>
                  <div className="media__image-view">
                    <BsCardImage
                      color="white"
                      htmlFor={name}
                      className="media__choose-image-icon "
                    />
                  </div>
                  <img
                    className="media__preview-image"
                    src={!_.isNull(previewImage) ? previewImage : value}
                    alt=""
                  />
                </div>
              )}
          </label>
          <input
            type="file"
            accept="image/*"
            name={name}
            id={name}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
};

RetangleChooseImage.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.shape({
      name: PropTypes.string,
    }),
    PropTypes.string,
  ]),
};

RetangleChooseImage.defaultProps = {
  value: null,
};

const renderFileInputField = ({ input, meta }) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <RetangleChooseImage {...input} />
    {meta.touched && meta.error && (
      <span className="form__form-group-error">{meta.error}</span>
    )}
  </div>
);

renderFileInputField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderFileInputField.defaultProps = {
  meta: null,
};

export default renderFileInputField;
