import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, ButtonToolbar, Modal, Spinner } from "reactstrap";
import classNames from "classnames";
import { withTranslation } from "react-i18next";
import { RTLProps } from "../../shared/prop-types/ReducerProps";
import {
  fetchActionMedias,
  fetchActionCategories,
  fetchActionPublishers,
  fetchActionPublishyears,
  fetchActionAuthors,
  fetchActionUsers,
  fetchSelectedMedias,
} from "../../redux/actions/fetchApiActions";
import Service from "../../config/Service";

const ModalComponent = ({
  t,
  dispatch,
  color,
  btn,
  title,
  message,
  colored,
  btnOutline,
  header,
  rtl,
  actionKey,
  actionModule,
  actionId,
  filterParentId,
  isAbleToDelete,
  data,
  page,
  unableToDeleteMessage,
  size,
  itemsPerPage,
  filterValue,
  itemsCount,
  selectedMediaId,
  isPublic,
}) => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggle = () => {
    setModal((prevState) => !prevState);
  };

  let Icon;

  const getParamsForApi = (type) => {
    let callBackFilter = `perPage=12&page=${page}`;
    let count = Math.ceil(itemsCount - 1 / itemsPerPage);
    switch (type) {
      case "publishers":
        callBackFilter = `perPage=${itemsPerPage}&page=${page}&name=${
          (filterValue && filterValue.filter_name) || ""
        }&name_en=${(filterValue && filterValue.filter_name_en) || ""}`;
        if (count < page) {
          callBackFilter = `perPage=${itemsPerPage}&page=${count}&name=${
            (filterValue && filterValue.filter_name) || ""
          }&name_en=${(filterValue && filterValue.filter_name_en) || ""}`;
        }
        return callBackFilter;
      case "authors":
        callBackFilter = `perPage=${itemsPerPage}&page=${page}&fullname=${
          filterValue.fullname || ""
        }&fullname_en=${filterValue.fullname_en || ""}`;
        if (count < page) {
          callBackFilter = `perPage=${itemsPerPage}&page=${count}&fullname=${
            filterValue.fullname || ""
          }&fullname_en=${filterValue.fullname_en || ""}`;
        }
        return callBackFilter;

      case "publishyears":
        callBackFilter = `perPage=${itemsPerPage}&page=${page}&name=${
          (filterValue && filterValue.filter_name) || ""
        }&name_en=${(filterValue && filterValue.filter_name_en) || ""}`;
        if (count < page) {
          callBackFilter = `perPage=${itemsPerPage}&page=${count}&name=${
            (filterValue && filterValue.filter_name) || ""
          }&name_en=${(filterValue && filterValue.filter_name_en) || ""}`;
        }
        return callBackFilter;
      case "users":
        const name = filterValue.filter_name || "";
        const email = filterValue.filter_email || "";
        const phone = filterValue.filter_phone || "";
        const role_id = filterValue.role_id ? filterValue.role_id.value : "";
        callBackFilter = `perPage=${itemsPerPage}&page=${page}&name=${name}&phone=${phone}&email=${email}&role_id=${role_id}`;
        if (count < page) {
          callBackFilter = `perPage=${itemsPerPage}&page=${count}&name=${name}&phone=${phone}&email=${email}&role_id=${role_id}`;
        }
        return callBackFilter;
      case "categories":
        callBackFilter = `perPage=${itemsPerPage}&page=${page}&parent=${filterParentId}&name=${
          (filterValue && filterValue.name) || ""
        }&name_en=${(filterValue && filterValue.name_en) || ""}`;
        if (count < page) {
          callBackFilter = `perPage=${itemsPerPage}&page=${count}&parent=${filterParentId}&name=${
            (filterValue && filterValue.name) || ""
          }&name_en=${(filterValue && filterValue.name_en) || ""}`;
        }
      default:
        return callBackFilter;
    }
  };

  const actionCallApi = () => {
    let callBackFilter = `perPage=12&page=${page}`;
    let mediaCallbackFilter = `perPage=12&page=${page}&is_public=${isPublic}`;

    switch (actionKey) {
      case "delete":
        switch (actionModule) {
          case "medias":
            setLoading(true);
            Service.remove("medias", actionId)
              .then((response) => {
                if (response.data.success) {
                  Service.getSearch("searchAllMedias", mediaCallbackFilter)
                    .then((response) => {
                      dispatch(fetchActionMedias(response.data));
                      setModal((prevState) => !prevState);
                      setLoading(false);
                    })
                    .catch((e) => {
                      console.log(e);
                    });
                }
              })
              .catch((e) => {
                console.log(e);
                setLoading(false);
              });
            break;
          case "medias_reference":
            setLoading(true);
            Service.remove("deleteMediaReference", actionId)
              .then(async (response) => {
                if (response.data.success) {
                  Service.get("getDetailMedia", selectedMediaId)
                    .then((response) => {
                      if (response.data.success) {
                        const selectedMedia = response.data.data;
                        dispatch(fetchSelectedMedias(selectedMedia));
                        setModal((prevState) => !prevState);
                        setLoading(false);
                      }
                    })
                    .catch((e) => {
                      console.log(e);
                      setLoading(false);
                    });
                }
              })
              .catch((e) => {
                console.log(e);
              });
            break;
          case "categories":
            setLoading(true);
            Service.remove("categories", actionId)
              .then((response) => {
                Service.getSearch("categories", getParamsForApi(actionModule))
                  .then((response) => {
                    dispatch(
                      fetchActionCategories(
                        response.data,
                        getParamsForApi(actionModule)
                      )
                    );
                    setModal((prevState) => !prevState);
                    setLoading(false);
                  })
                  .catch((e) => {
                    console.log(e);
                    setLoading(false);
                  });
              })
              .catch((e) => {
                console.log(e);
              });
            break;
          case "publishers":
            setLoading(true);
            Service.remove("publishers", actionId)
              .then((response) => {
                Service.getSearch("publishers", getParamsForApi(actionModule))
                  .then((response) => {
                    dispatch(
                      fetchActionPublishers(
                        response.data,
                        getParamsForApi(actionModule)
                      )
                    );
                    setModal((prevState) => !prevState);
                    setLoading(false);
                  })
                  .catch((e) => {
                    console.log(e);
                    setLoading(false);
                  });
              })
              .catch((e) => {
                console.log(e);
              });
            break;
          case "publishyears":
            setLoading(true);
            Service.remove("publishyears", actionId)
              .then((response) => {
                Service.getSearch("publishyears", getParamsForApi(actionModule))
                  .then((response) => {
                    dispatch(
                      fetchActionPublishyears(
                        response.data,
                        getParamsForApi(actionModule)
                      )
                    );
                    setModal((prevState) => !prevState);
                    setLoading(false);
                  })
                  .catch((e) => {
                    console.log(e);
                    setLoading(false);
                  });
              })
              .catch((e) => {
                console.log(e);
              });
            break;
          case "authors":
            setLoading(true);
            Service.remove("authors", actionId)
              .then((response) => {
                if (response) {
                  Service.getSearch("authors", getParamsForApi(actionModule))
                    .then((response) => {
                      dispatch(
                        fetchActionAuthors(
                          response.data,
                          getParamsForApi(actionModule)
                        )
                      );
                      setModal((prevState) => !prevState);
                      setLoading(false);
                    })
                    .catch((e) => {
                      console.log(e);
                      setLoading(false);
                    });
                }
              })
              .catch((e) => {
                console.log(e);
                setLoading(false);
              });
            break;
          case "users":
            setLoading(true);
            Service.remove("users", actionId)
              .then((response) => {
                if (response) {
                  Service.getSearch("users", getParamsForApi(actionModule))
                    .then((response) => {
                      dispatch(
                        fetchActionUsers(
                          response.data,
                          getParamsForApi(actionModule)
                        )
                      );
                      setLoading(false);
                      setModal((prevState) => !prevState);
                    })
                    .catch((e) => {
                      console.log(e);
                      setLoading(false);
                    });
                }
              })
              .catch((e) => {
                console.log(e);
              });
            break;
          default:
            break;
        }
        break;
      case "approve":
        switch (actionModule) {
          case "users":
            setLoading(true);
            Service.post("user/actionApproveUnApproveUser", data)
              .then((response) => {
                if (response.data.success) {
                  Service.getSearch("users", callBackFilter)
                    .then((response) => {
                      dispatch(fetchActionUsers(response.data, callBackFilter));
                      setModal((prevState) => !prevState);
                      setLoading(false);
                    })
                    .catch((e) => {
                      console.log(e);
                      setLoading(false);
                    });
                }
              })
              .catch((e) => {
                console.log(e);
                setLoading(false);
              });
            break;
          case "public_unpublic_medias":
            setLoading(true);
            Service.post("user/publicMedia", data)
              .then((response) => {
                if (response.data.success) {
                  Service.getSearch("searchAllMedias", mediaCallbackFilter)
                    .then((response) => {
                      dispatch(fetchActionMedias(response.data));
                      setModal((prevState) => !prevState);
                      setLoading(false);
                    })
                    .catch((e) => {
                      console.log(e);
                      setLoading(false);
                    });
                }
              })
              .catch((e) => {
                console.log(e);
                setLoading(false);
              });
            break;
          case "suspend_unsuspend_user":
            setLoading(true);
            Service.post("user/setUserSuspend", data)
              .then((response) => {
                console.log("response suspend user =>", response);
                if (response.data.success) {
                  Service.getSearch("users", callBackFilter)
                    .then((response) => {
                      dispatch(fetchActionUsers(response.data, callBackFilter));
                      setModal((prevState) => !prevState);
                      setLoading(false);
                    })
                    .catch((e) => {
                      console.log(e);
                      setLoading(false);
                    });
                }
              })
              .catch((e) => {
                console.log(e);
                setLoading(false);
              });
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  };

  switch (color) {
    case "primary":
      Icon = <span className="lnr lnr-lock modal__title-icon" />;
      break;
    case "success":
      Icon = <span className="lnr lnr-thumbs-up modal__title-icon" />;
      break;
    case "warning":
      Icon = <span className="lnr lnr-flag modal__title-icon" />;
      break;
    case "danger":
      Icon = <span className="lnr lnr-trash modal__title-icon" />;
      break;
    default:
      break;
  }
  const modalClass = classNames({
    "modal-dialog--colored": colored,
    "modal-dialog--header": header,
  });
  return (
    <div>
      <Button color={color} onClick={toggle} size={size} outline={btnOutline}>
        {btn}
      </Button>
      <Modal
        isOpen={modal}
        toggle={toggle}
        modalClassName={`${rtl.direction}-support`}
        className={`modal-dialog--${color} ${modalClass}`}
      >
        {isAbleToDelete && (
          <div className="modal__header">
            <button
              className="lnr lnr-cross modal__close-btn"
              type="button"
              onClick={toggle}
            />
            {header ? "" : Icon}
            <h4 className="text-modal  modal__title">{title}</h4>
          </div>
        )}
        <div className="modal__body">
          {isAbleToDelete ? message : unableToDeleteMessage}
        </div>
        {isAbleToDelete ? (
          <ButtonToolbar className="modal__footer">
            <Button className="modal_cancel" onClick={toggle}>
              {t("BUTTON.CANCEL")}
            </Button>
            <Button
              className="modal_ok"
              outline={colored}
              color={color}
              onClick={actionCallApi}
            >
              {loading ? (
                <Spinner animation="border" variant="primary" size="sm" />
              ) : (
                t("GENERAL.OK")
              )}
            </Button>
          </ButtonToolbar>
        ) : (
          <ButtonToolbar className="modal__footer">
            <Button
              className="modal_ok"
              outline={colored}
              color={color}
              onClick={toggle}
            >
              {t("GENERAL.OK")}
            </Button>
          </ButtonToolbar>
        )}
      </Modal>
    </div>
  );
};

ModalComponent.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  color: PropTypes.string.isRequired,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  btn: PropTypes.string.isRequired,
  rtl: RTLProps.isRequired,
  actionKey: PropTypes.string,
  actionModule: PropTypes.string,
  actionId: PropTypes.number,
  isAbleToDelete: PropTypes.bool,
};

ModalComponent.defaultProps = {
  title: "",
  message: "",
  colored: false,
  header: false,
  actionKey: "",
  actionModule: "",
  actionId: 0,
  callbackApi: "",
  callBackFilter: "",
  isAbleToDelete: true,
};

export default connect((state) => ({
  rtl: state.rtl,
  t: state.t,
}))(withTranslation("common")(ModalComponent));
