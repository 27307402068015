import React from "react";
import { Route, Switch } from "react-router-dom";
import MainWrapper from "../MainWrapper";
import LogIn from "../../Account/LogIn/index";
import Register from "../../Account/Register/index";
import ResetPassword from "../../Account/ResetPassword/index";
import WrappedRoutes from "./WrappedRoutes";
import Privacy from "../../Account/Privacy/index";
import ContactUs from "../../Account/ContactUs/index";
const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route path="/Privacy" component={Privacy} />
        <Route path="/contact_us" component={ContactUs} />
        <Route exact path="/" component={LogIn} />
        <Route exact path="/main" component={LogIn} />
        <Route path="/login" component={LogIn} />
        <Route path="/register" component={Register} />
        <Route path="/reset_password" component={ResetPassword} />
        <Route path="/" component={WrappedRoutes} />
       
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;
