/* eslint-disable react/prop-types */
import React, { PureComponent } from "react";
import { withRouter } from "react-router";
import {
  Col,
  Container,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import AllMedia from "./components/AllMedia";
import FilterInput from "./components/FilterMedia";
import "./medias.scss";
import { fetchActionMedias } from "../../redux/actions/fetchApiActions";
import Service from "../../config/Service";
import Utilities from "../../shared/helpers/Utilities";
import classnames from "classnames";

class MediaIndex extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showMediaComponent: 0,
      selectedMediaId: null,
      selectedMedia: {},
      pageOfItems: 1,
      itemsPerPage: 12,
      isFetchingMedias: false,
      searchParams: "",
      permissions: {},
      filterValue: null,
      activeTab: "public_media",
      isPublic: "true",
    };
  }

  componentDidMount() {
    const {
      location: { state },
    } = this.props.history;
    const permissionData = Utilities.getPermission(this.props.user, "medias");
    const activeTab = (state && state.activeTab) || "public_media";
    this.setState(
      {
        permissions: permissionData,
        pageOfItems: (state && state.pageOfItems) || 1,
        filterValue: (state && state.filterValue) || {},
        activeTab: activeTab,
        isPublic: activeTab === "public_media" ? "yes" : "none",
      },
      () => {
        this.getMediaList();
      }
    );
  }

  getMediaList = () => {
    const { pageOfItems, itemsPerPage, filterValue, isPublic } = this.state;
    let searchParams = "";
    if (filterValue) {
      const { itemsPerPage } = this.state;
      const title = filterValue.title || "";
      const title_en = filterValue.title_en || "";
      const isbn = filterValue.isbn || "";
      const author = filterValue.author || "";
      const category = filterValue.category || "";
      const publishYear = filterValue.publish_year || "";
      const publisher = filterValue.publisher || "";
      const id = filterValue.id || "";
      searchParams = `perPage=${itemsPerPage}&page=${pageOfItems}&id=${id}&title=${title}&title_en=${title_en}&isbn=${isbn}&category=${category}&author=${author}&is_public=${isPublic}&publish_year=${publishYear}&publisher_id=${publisher}`;
    } else {
      searchParams = `perPage=${itemsPerPage}&page=${pageOfItems}&is_public=${isPublic}`;
    }
    this.getSearchMedia(searchParams);
  };

  handleFilter = (data) => {
    const { itemsPerPage, isPublic } = this.state;
    const title = data.title || "";
    const title_en = data.title_en || "";
    const isbn = data.isbn || "";
    const author = data.author || "";
    const category = data.category || "";
    const publishYear = data.publish_year || "";
    const publisher = data.publisher || "";
    const id = data.id || "";
    const searchParams = `perPage=${itemsPerPage}&id=${id}&title=${title}&title_en=${title_en}&isbn=${isbn}&category=${category}&author=${author}&is_public=${isPublic}&publish_year=${publishYear}&publisher_id=${publisher}`;
    this.setState({
      filterValue: data,
      pageOfItems: 1,
    });
    this.getSearchMedia(searchParams);
  };

  getSearchMedia = (searchParams) => {
    const { dispatch } = this.props;

    Service.getSearch("searchAllMedias", searchParams)
      .then((response) => {
        if (response) {
          this.setState({
            isFetchingMedias: false,
          });
          dispatch(fetchActionMedias(response.data));
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  onChangePage = async (pageOfItems) => {
    if (pageOfItems) {
      await this.setState({ pageOfItems });
      await this.getMediaList();
    }
  };

  toggle = async (tab) => {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      await this.setState({
        activeTab: tab,
        isPublic: tab === "public_media" ? "yes" : "none",
        pageOfItems: 1,
      });
      await this.getMediaList();
    }
  };

  render() {
    const { t } = this.props;
    const {
      itemsPerPage,
      isFetchingMedias,
      pageOfItems,
      permissions,
      filterValue,
      activeTab,
    } = this.state;
    return (
      <Container className="todo-app">
        <Row>
          <Col md={12}>
            <h3 className="page-title">{t("SIDE_BAR.LIST_OF_MEDIAS")}</h3>
          </Col>
        </Row>
        <Row>
          <FilterInput
            handleFilter={(data) => this.handleFilter(data)}
            permissions={permissions}
            filterValue={filterValue}
            activeTab={activeTab}
          />
        </Row>
        <div className="profile__card tabs tabs--bordered-bottom">
          <div className="tabs__wrap">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "public_media",
                  })}
                  onClick={() => this.toggle("public_media")}
                >
                  {t("MEDIAS.PUBLIC_MEDIAS")}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "penging_medias",
                  })}
                  onClick={() => this.toggle("penging_medias")}
                >
                  {t("MEDIAS.PENDIGN_MEDIAS")}
                </NavLink>
              </NavItem>
            </Nav>

            <TabContent style={{ overflow: "hidden" }} activeTab={activeTab}>
              <TabPane tabId="public_media">
                {activeTab === "public_media" && (
                  <Row>
                    <AllMedia
                      itemsPerPage={itemsPerPage}
                      page={pageOfItems}
                      isFetchingMedias={isFetchingMedias}
                      onChangePage={this.onChangePage}
                      permissions={permissions}
                      refreshData={this.getMediaList}
                      filterValue={filterValue}
                      activeTab={activeTab}
                    />
                  </Row>
                )}
              </TabPane>

              <TabPane tabId="penging_medias">
                {activeTab === "penging_medias" && (
                  <Row>
                    <AllMedia
                      itemsPerPage={itemsPerPage}
                      page={pageOfItems}
                      isFetchingMedias={isFetchingMedias}
                      onChangePage={this.onChangePage}
                      permissions={permissions}
                      refreshData={this.getMediaList}
                      filterValue={filterValue}
                      activeTab={activeTab}
                    />
                  </Row>
                )}
              </TabPane>
            </TabContent>
          </div>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.theme,
    rtl: state.rtl,
    user: state.user,
  };
};

export default connect(mapStateToProps)(
  withRouter(withTranslation("common")(MediaIndex))
);
