import React, { PureComponent } from "react";
import {
  Card,
  CardBody,
  Col,
  Button,
  Container,
  Row,
  Spinner,
  Input,
  Alert,
} from "reactstrap";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import NotificationSystem from "rc-notification";
import { BasicNotification } from "../../../components/Notification";
import { fetchActionPublishyears } from "../../../redux/actions/fetchApiActions";
import { RenderInputField } from "../../../components/RenderField";
import Service from "../../../config/Service";

let notification = null;
let oldSortKey = 0;

class DetailForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      publishyearData: {
        name: "",
        name_en: "",
        sortkey: "",
      },
      isUploading: false,
      showAlert: false,
    };
    this.getPublishyear();
  }

  componentDidMount() {
    NotificationSystem.newInstance(
      { style: { top: 65 } },
      (n) => (notification = n)
    );
  }

  getPublishyear = () => {
    Service.get("publishyears", this.props.clickId)
      .then((response) => {
        const data = response.data.data;
        oldSortKey = data.sortkey;
        if (response.data.success) {
          this.setState({
            publishyearData: data,
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  showNotification = (rtl) => {
    const { t } = this.props;
    notification.notice({
      content: (
        <BasicNotification
          title={t("GENERAL.NOTIFICATION")}
          color="primary"
          message={t("GENERAL.THE_ACTION_HAS_BEEN_COMPLETED_SUCCESSFULLY")}
        />
      ),
      duration: 5,
      closable: true,
      style: { top: 0, left: "calc(100vw - 100%)" },
      className: `right-up ${rtl}-support`,
    });
  };

  actionSubmit = () => {
    const { t } = this.props;
    let { publishyearData } = this.state;
    if (
      !publishyearData.name ||
      !publishyearData.name_en ||
      !publishyearData.sortkey
    ) {
      this.setState({
        alertMessage: t("GENERAL.ALL_FIELDS_ARE_REQURED"),
        showAlert: true,
        alertColor: "danger",
      });
    } else {
      if (parseInt(publishyearData.sortkey) === oldSortKey) {
        publishyearData = {
          name: publishyearData.name,
          name_en: publishyearData.name_en,
        };
      }
      this.setState({
        isUploading: true,
      });
      this.postPublishyear(publishyearData);
    }
  };

  postPublishyear = (data) => {
    const { dispatch, clickId, pageOfItems, itemsPerPage } = this.props;
    const searchParams = `perPage=${itemsPerPage}&page=${pageOfItems}`;
    Service.update("publishyears", clickId, data)
      .then((response) => {
        if (response.data.success) {
          Service.getSearch("publishyears", searchParams)
            .then((response) => {
              dispatch(fetchActionPublishyears(response.data));
              this.setState({ isUploading: false });
              this.showNotification();
              this.toggleGobackTo();
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          this.setState({
            showAlert: true,
            alertMessage: response.data.message,
            isUploading: false,
          });
        }
      })
      .catch((e) => {
        console.log(e);
        this.setState({ isUploading: false });
      });
  };

  toggleGobackTo = () => {
    this.props.goBackToView();
  };

  onTextChange = (event) => {
    const fieldName = event.target.name;
    const value = event.target.value;
    let publishyearData = { ...this.state.publishyearData };
    publishyearData[fieldName] = value;
    this.setState({
      publishyearData,
    });
  };

  render() {
    const { t, pristine, permissions } = this.props;
    const {
      isUploading,
      publishyearData,
      alertMessage,
      showAlert,
    } = this.state;

    return (
      <Container className="todo-app">
        <Row>
          <Col md={12}>
            <h3 className="page-title">{t("GENERAL.DETAIL_INFORMATION")}</h3>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Card>
              <CardBody>
                <form className="form login-form">
                  <Col md={12}>
                    <Alert color="danger" isOpen={showAlert}>
                      {alertMessage}
                    </Alert>
                  </Col>
                  <Col md={6} sm={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t("GENERAL.NAME")}
                      </span>
                      <span className="red-text">*</span>
                      <div className="form__form-group-field">
                        <Input
                          name="name"
                          component={RenderInputField}
                          type="text"
                          value={publishyearData.name || ""}
                          placeholder={t("GENERAL.NAME")}
                          onChange={this.onTextChange}
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t("GENERAL.SORT_KEY")}
                      </span>
                      <span className="red-text">*</span>
                      <div className="form__form-group-field">
                        <Input
                          name="sortkey"
                          component={RenderInputField}
                          type="number"
                          value={publishyearData.sortkey || ""}
                          placeholder={t("GENERAL.SORT_KEY")}
                          onChange={this.onTextChange}
                        />
                      </div>
                    </div>
                  </Col>

                  <Col md={6} sm={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t("GENERAL.NAME_EN")}
                      </span>
                      <span className="red-text">*</span>
                      <div className="form__form-group-field">
                        <Input
                          name="name_en"
                          component={RenderInputField}
                          type="text"
                          value={publishyearData.name_en || ""}
                          placeholder={t("GENERAL.NAME_EN")}
                          onChange={this.onTextChange}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="d-flex justify-content-end mt-3">
                      {permissions.edit && (
                        <Button
                          color="primary"
                          disabled={pristine || isUploading}
                          onClick={this.actionSubmit}
                        >
                          {isUploading ? (
                            <Spinner
                              animation="border"
                              varaint="primary"
                              size="sm"
                            />
                          ) : (
                            t("GENERAL.SAVE")
                          )}
                        </Button>
                      )}
                      <Button className="icon" onClick={this.toggleGobackTo}>
                        {t("GENERAL.BACK")}
                      </Button>
                    </div>
                  </Col>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    initialValues: state.getData.PUBLISHER_DATA,
  };
}

export default connect(mapStateToProps)(withTranslation("common")(DetailForm));
