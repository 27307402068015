import React, { PureComponent } from "react";
import { Col, Button, Row, Spinner } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import NotificationSystem from "rc-notification";
import { BasicNotification } from "../../../components/Notification";
import Service from "../../../config/Service";
// import { fetchActionCategories } from "../../../redux/actions/fetchApiActions";
import validate from "./validate";
import { RenderInputField } from "../../../components/RenderField";

let notification = null;
class AddForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      isUploading: false,
    };
  }

  componentDidMount() {
    NotificationSystem.newInstance(
      { style: { top: 65 } },
      (n) => (notification = n)
    );
    this.props.initialize();
  }

  showNotification = (rtl) => {
    const { t } = this.props;
    notification.notice({
      content: (
        <BasicNotification
          title={t("GENERAL.NOTIFICATION")}
          color="primary"
          message={t("GENERAL.THE_ACTION_HAS_BEEN_COMPLETED_SUCCESSFULLY")}
        />
      ),
      duration: 5,
      closable: true,
      style: { top: 0, left: "calc(100vw - 100%)" },
      className: `right-up ${rtl}-support`,
    });
  };

  actionAddCetegory = (value) => {
    const { refreshAfterCreate } = this.props;
    let name = value.post_parent_name;
    let name_en = value.post_parent_name_en;

    const data = {
      name: name,
      name_en: name_en,
      parent: 0,
    };
    this.setState({ isUploading: true });

    Service.post("categories", data)
      .then((response) => {
        if (response) {
          refreshAfterCreate();
          this.showNotification();
          this.props.onCancel();
          this.setState({ isUploading: false });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  render() {
    const { t, handleSubmit, pristine, onCancel } = this.props;
    const { isUploading } = this.state;

    return (
      <Col md={12}>
        <Row>
          <Col md={12}>
            <h3 className="page-title">{t("GENERAL.CREATE_NEW_CATEGORY")}</h3>
          </Col>
        </Row>
        <form className="form" onSubmit={handleSubmit(this.actionAddCetegory)}>
          <Col md={12}>
            <div className="form__form-group">
              <span className="form__form-group-label typography-message">
                {t("GENERAL.NAME")}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="post_parent_name"
                  component={RenderInputField}
                  type="text"
                  placeholder={t("GENERAL.NAME")}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label typography-message">
                {t("GENERAL.NAME_EN")}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="post_parent_name_en"
                  component={RenderInputField}
                  type="text"
                  placeholder={t("GENERAL.NAME_EN")}
                />
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <Button
                color="primary"
                type="submit"
                disabled={pristine || isUploading}
              >
                {isUploading ? (
                  <Spinner animation="border" varaint="primary" size="sm" />
                ) : (
                  t("GENERAL.SAVE")
                )}
              </Button>
              <Button type="button" onClick={onCancel} disabled={isUploading}>
                {t("BUTTON.CANCEL")}
              </Button>
            </div>
          </Col>
        </form>
      </Col>
    );
  }
}

AddForm = reduxForm({
  shouldValidate: () => true,
  form: "add_category_form",
  validate,
  enableReinitialize: true,
  destroyOnUnmount: false,
})(AddForm);

export default withTranslation("common")(AddForm);
