import {
  USER_LOGIN,
  GET_USER_DETAIL
} from '../actions/actionTypes';

const initialState = {
  data: {},
  detail: {}
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN:
      return {
        ...state,
        ...{ data: action.data }
      }
    case GET_USER_DETAIL:
      return {
        ...state,
        ...{ detail: action.data }
      };
    default:
      return state;
  }
};


export default authReducer;
