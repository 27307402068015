import React, { PureComponent } from "react";
import { Button, Col, Spinner } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import chooseProfileImage from "../../../../../components/ChooseImage/RoundedChooseImage";
import { withTranslation } from "react-i18next";
import NotificationSystem from "rc-notification";
import { BasicNotification } from "../../../../../components/Notification";
import { getUserDetail } from '../../../../../redux/actions/authActions';
import {
  RenderInputField
} from "../../../../../components/RenderField";
import validate from "../validate";
import { connect } from "react-redux";
import VerifyPhoneNumber from "./VerifyPhoneNumber";
import firebase from "../../../../../config/firebase";

let notification = null;

class UserDetail extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      s3_photo_url: {},
      isUploading: false,
      isVerifyPhone: false,
    };
  }

  componentDidMount() {
    const { user } = this.props;
    this.props.initialize({
      fullname: user.fullname,
      fullname_en: user.fullname_en,
      phone: user.phone,
      email: user.email,
      file: user.s3_photo_url,
    });
    NotificationSystem.newInstance({ style: { top: 10 } }, n => notification = n);
  }

  sendNotification = (title, smg, color = "danger") => {
    if (smg == "TOO_SHORT") smg = "Phone number is too short";
    else if (smg == "TOO_LONG") smg = "Phone number is too long";
    return notification.notice({
      content: <BasicNotification title={title} color={color} message={smg} />,
      duration: 5,
      closable: true,
      style: { top: 0, left: "calc(100vw - 100%)" },
      className: `right-up `,
    });
  };

  // set up recaptcha checking robotic security
  setUpRecaptcha = () => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "captchaid001",
      {
        size: "invisible",
      }
    );
  };

  onSendSMSCode = (value) => {
    this.setState({ isUploading: true });
    this.setUpRecaptcha();

    const appVerifier = window.recaptchaVerifier;
    firebase
      .auth()
      .signInWithPhoneNumber("+855" + parseInt(value.phone), appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        const { s3_photo_url } = this.state;
        const { user } = this.props;
        let data = {
          id: user.id,
          fullname: value.fullname,
          fullname_en: value.fullname_en,
          phone: value.phone,
          email: value.email,
        }

        if (s3_photo_url.name) {
          data = {
            id: user.id,
            fullname: value.fullname,
            fullname_en: value.fullname_en,
            phone: value.phone,
            email: value.email,
            s3_photo_url: s3_photo_url.file
          }
        }

        this.setState({
          isVerifyPhone: true,
          userData: data,
          isUploading: false
        })
      })
      .catch((error) => {
        this.setState({ isUploading: false, isVerifyPhone: false });
        this.sendNotification("SMS phone code message: ", error.message);
      });
  }

  onChooseImage = (event) => {
    this.setState({
      s3_photo_url: event,
    });
  };

  onCancel = () => {
    this.props.toggleModal();
  };

  render() {
    const { t, handleSubmit, pristine, toggleModal } = this.props;
    const { isUploading, isVerifyPhone, userData } = this.state;
    return (
      <>
        {!isVerifyPhone && (
          <div className="create-author">
            <Col md={12} lg={12} xl={12}>
              <div className="create-author_profile-view">
                <Field
                  name="file"
                  component={chooseProfileImage}
                  onChange={this.onChooseImage}
                />
              </div>
              <div className="card__title">
                <h5 className="bold-text">{t("USER_PROFILE.EDIT_USER_INFO")}</h5>
                <h5 className="subhead">{t("USER_PROFILE.EDIT_USER_DES")}</h5>
              </div>
              <form
                className="form form--vertical"
                onSubmit={handleSubmit(
                  this.onSendSMSCode
                )}
              >
                <Col md={6} lg={6} xl={6}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t("INFO_FIELDS.FULLNAME")}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="fullname"
                        component={RenderInputField}
                        type="text"
                        placeholder={t("INFO_FIELDS.FULLNAME")}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t("INFO_FIELDS.PHONE")}
                    </span>
                    <span className="red-text">*</span>
                    <div className="form__form-group-field">
                      <Field
                        name="phone"
                        component={RenderInputField}
                        type="text"
                        placeholder={t("INFO_FIELDS.PHONE")}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={6} lg={6} xl={6}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t("INFO_FIELDS.FULLNAME_EN")}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="fullname_en"
                        component={RenderInputField}
                        type="text"
                        placeholder={t("INFO_FIELDS.FULLNAME_EN")}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t("INFO_FIELDS.EMAIL")}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="email"
                        component={RenderInputField}
                        type="text"
                        placeholder={t("INFO_FIELDS.EMAIL")}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="d-flex justify-content-end">
                    <Button
                      type="button"
                      onClick={this.onCancel}
                      disabled={isUploading}
                    >
                      {t("BUTTON.CANCEL")}
                    </Button>
                    <Button
                      color="primary"
                      type="submit"
                      disabled={pristine || isUploading}
                    >
                      {isUploading ? (
                        <Spinner animation="border" variant="primary" size="sm" />
                      ) : (
                          t("BUTTON.SEND_CODE")
                        )}
                    </Button>
                  </div>
                </Col>
              </form>
            </Col>
            <div id="captchaid001" />
          </div>
        )}
        {isVerifyPhone && (
          <VerifyPhoneNumber
            userData={userData}
            toggleModal={toggleModal}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.detail
  }
}
const mapDispatchToProps = {
  getUserDetail
}

UserDetail = reduxForm({
  shouldValidate: () => true, // to alway validation form even user cancel the process
  form: "edit_user_main",
  validate,
  enableReinitialize: true,
  destroyOnUnmount: false,
  keepDirtyOnReinitialize: true,
})(UserDetail);

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("common")(UserDetail));
