import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import SidebarLink from "./SidebarLink";
import SidebarCategory from "./SidebarCategory";
import { Redirect } from "react-router-dom";
import Modules from "../../../module.json";
import Utilities from "../../../shared/helpers/Utilities";

class SidebarContent extends Component {
  static propTypes = {
    changeToDark: PropTypes.func.isRequired,
    changeToLight: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {};
  }

  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };

  render() {
    const { t, changeToLight, changeToDark } = this.props;
    const user = JSON.parse(localStorage.getItem("user"));
    if (!user) {
      return <Redirect push to="/login" />;
    } else {
      const permissions = user.permissions.split(",");
      const menuData = Utilities.getMatch(permissions, Modules);
      return (
        <div className="sidebar__content">
          {menuData.map((item, i) => (
            <SidebarLink
              icon={item.icon}
              title={t(`SIDE_BAR.${item.name}`)}
              route={item.route}
              key={i}
              onClick={this.hideSidebar}
            />
          ))}
          <ul className="sidebar__block">
            <SidebarCategory title={t("GENERAL.LAYOUT")} icon="layers">
              <button
                className="sidebar__link"
                type="button"
                onClick={changeToLight}
              >
                <p className="sidebar__link-title">
                  {t("GENERAL.LAYOUT_LIGHT_THEME")}
                </p>
              </button>
              <button
                className="sidebar__link"
                type="button"
                onClick={changeToDark}
              >
                <p className="sidebar__link-title">
                  {t("GENERAL.LAYOUT_DARK_THEME")}
                </p>
              </button>
            </SidebarCategory>
          </ul>
        </div>
      );
    }
  }
}

export default withTranslation("common")(SidebarContent);
