import React from "react";
import LogInForm from "./LogInForm";
import { withTranslation } from "react-i18next";

const LogIn = ({ t }) => {
  return (
   
    <div className="account account--not-photo">
      <div className="account__wrapper">
          <ul class="nav">
            <li class="nav-item">
              <a class="nav-link active" href="/login">Login</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/Contact_Us">Contact Us</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/Privacy">Privacy</a>
            </li>
          </ul>
        <div className="account__card">
          <div className="account__head">
            <h3 className="account__title">
              {t("GENERAL.WELCOME_TO")}
              <span className="account__logo"> D-Library</span>
            </h3>
          </div>
          <LogInForm onSubmit form="log_in_form" />
        </div>
      </div>
    </div>
  );
};

export default withTranslation("common")(LogIn);
