import React, { PureComponent } from "react";
import { Field, reduxForm, Form } from "redux-form";
import EyeIcon from "mdi-react/EyeIcon";
import KeyVariantIcon from "mdi-react/KeyVariantIcon";
import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
import { actionLogin, getUserDetail } from '../../../redux/actions/authActions';
import { NavLink } from "react-router-dom";
import { withRouter } from "react-router";
import { Alert, Button, Spinner } from "reactstrap";
import renderCheckBoxField from "../../../components/form/CheckBox";
import { withTranslation } from "react-i18next";
import validate from "./validate";
import Service from "../../../config/Service";
import Modules from '../../../module.json';
import Utilities from '../../../shared/helpers/Utilities';
import { connect } from 'react-redux';
import _ from 'lodash';

class LogInForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      credentailData: {},
      error: false,
      errorMessage: "",
      loading: false,
      isRememberMe: false
    }
    props.initialize();
  }

  componentDidMount() {
    localStorage.removeItem("main");
    localStorage.removeItem("user");
    this.getUserCredentail();
  }

  getUserCredentail = () => {
    let data = JSON.parse(localStorage.getItem("user_credential"));
    if (!_.isNull(data)) {
      this.setState({
        isRememberMe: true,
        credentailData: data
      });
      this.props.initialize({
        phone: data.phone,
        password: data.password
      })
    }
  }

  showPasswordToggle = () => {
    const { showPassword } = this.state;
    this.setState({
      showPassword: !showPassword
    });
  };

  loginHandle = (event) => {
    event.preventDefault();
    const { t } = this.props;
    const { credentailData } = this.state;

    if (!credentailData.phone && !credentailData.password) {
      this.setState({
        errorMessage: t("LOGIN.PlEASE_ENTER_BOTH_PHONE_PASSWORD"),
        error: true
      });
    }

    if (!credentailData.phone && credentailData.password) {
      this.setState({
        errorMessage: t("LOGIN.PLAESE_ENTER_PHONE"),
        error: true
      });
    }

    if (credentailData.phone && !credentailData.password) {
      this.setState({
        errorMessage: t("LOGIN.PLEASE_ENTER_PASSWORD"),
        error: true
      });
    }

    if (credentailData.phone && credentailData.password) {
      this.loginValid(credentailData);
    }
  }

  loginValid = (data) => {
    const { history, dispatch } = this.props;
    const { isRememberMe } = this.state;
    this.setState({ loading: true });

    Service.post("login", data)
      .then((response) => {
        this.setState({ loading: false });
        if (!response.data.success) {
          this.setState({
            errorMessage: response.data.message,
            error: true
          });
        } else {
          if (response.data.data.token) {
            let user = response.data.data;
            const permissionsArr = user.permissions.split(",");
            const modulePermission = Utilities.getMatch(permissionsArr, Modules)

            localStorage.setItem("user", JSON.stringify(user));
            history.push(modulePermission[0].route);
            dispatch(actionLogin(response.data.data));
            this.getUser();
            if (isRememberMe) {
              this.saveUserCredentail();
            } else {
              localStorage.removeItem("user_credential");
            }
          }
        }
      })
      .catch((e) => {
        this.setState({ loading: false });
        console.log(e);
      });
  }

  getUser = () => {
    const { dispatch } = this.props;
    Service.getAll("user/getUser")
      .then((response) => {
        if (response.data.success) {
          const user = response.data.data;
          dispatch(getUserDetail(user));
          this.setState({ user });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  saveUserCredentail = () => {
    const { credentailData } = this.state;
    localStorage.setItem("user_credential", JSON.stringify(credentailData));
  }

  onTextInput = (event) => {
    const fieldName = event.target.name;
    const credentailData = { ...this.state.credentailData };
    credentailData[fieldName] = event.target.value;

    this.setState({ credentailData });
  };

  onCheckbox = (event) => {
    if (event.currentTarget) {
      const isRememberMe = event.target.checked;
      this.setState({
        isRememberMe
      })
    }
  }

  render() {
    const { t, form } = this.props;
    const { errorMessage, error, isRememberMe, showPassword, loading } = this.state;
    return (
      <Form className="form login-form" onSubmit={this.loginHandle}>
        <Alert color="danger" isOpen={error}>
          {errorMessage}
        </Alert>
        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("LOGIN.PHONE")}
            <span className="red-text">*</span>
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AccountOutlineIcon />
            </div>
            <Field
              name="phone"
              component="input"
              type="number"
              placeholder={t("LOGIN.PHONE")}
              className="input-without-border-radius"
              onChange={this.onTextInput}
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("LOGIN.PASSWORD")}
            <span className="red-text">*</span>
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <Field
              name="password"
              component="input"
              type={showPassword ? "text" : "password"}
              placeholder={t("LOGIN.PASSWORD")}
              className="input-without-border-radius"
              onChange={this.onTextInput}
            />
            <button
              type="button"
              className={`form__form-group-button${showPassword ? " active" : ""
                }`}
              onClick={this.showPasswordToggle}
            >
              <EyeIcon />
            </button>
            <div className="account__forgot-password">
              <NavLink to="/reset_password">
                {t("LOGIN.FORGOT_A_PASSWORD")}
              </NavLink>
            </div>
          </div>
        </div>

        <div className="form__form-group">
          <div className="form__form-group form__form-group-field">
            <Field
              name={`remember_me-${form}`}
              component={renderCheckBoxField}
              label={t("LOGIN.REMEMBER_ME")}
              onChange={this.onCheckbox}
              defaultChecked={isRememberMe}
            />
          </div>
        </div>

        <div className="account__btns">
          <Button className="account__btn" color="primary">
            {loading ? (
              <Spinner size="sm" animation="border" varaint="primary" />
            ) : (
              t("LOGIN.SIGN_IN")
            )}
          </Button>
          <NavLink
            className="btn btn-outline-primary account__btn"
            to="/register"
          >
            {t("LOGIN.CREATE_ACCOUNT")}
          </NavLink>
        </div>
      </Form>
    )
  }
}

const mapStateToProps = (state) => {
  //return { ... }
}

const mapDispatchToProps = {
  actionLogin
}

LogInForm = reduxForm({
  form: "login_form_validation", // a unique identifier for this form
  validate,
})(LogInForm);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation("common")(LogInForm)));
