/* eslint-disable camelcase */
import common_en from './en/common.json';
import common_kh from './kh/common.json';

export default {
  en: {
    common: common_en,
  },
  kh: {
    common: common_kh,
  }
};
