import {
  FETCH_COMBO_AUTHORS,
  FETCH_MEDIAS,
  FETCH_COMBO_PUBLISHERS,
  FETCH_COMBO_PUBLISHYEARS,
  FETCH_COMBO_CATEGORIES,
  FETCH_CATEGORIES,
  FETCH_PUBLISHERS,
  FETCH_PUBLISHYEARS,
  FETCH_AUTHORS,
  FETCH_CATEGORY_TREE,
  FETCH_USERS,
  FETCH_SELECTED_MEDIA
} from "../actions/actionTypes";

const initialState = {
  FETCH_MEDIAS: {},
  FETCH_AUTHORS: {},
  FETCH_CATEGORIES: {},
  FETCH_USERS: {},
  FETCH_PUBLISHERS: {},
  FETCH_PUBLISHYEARS: {},
  FETCH_COMBO_PUBLISHYEARS: {},
  FETCH_COMBO_PUBLISHERS: {},
  FETCH_COMBO_CATEGORIES: {},
  FETCH_CATEGORY_TREE: {},
  FETCH_COMBO_AUTHORS: {},
  FETCH_SELECTED_MEDIA: {},
  FETCH_PUBLISHYEAR_PARAMS: '',
  FETCH_PUBLISHER_PARAMS: '',
  FETCH_CATEGORY_PARAMS: '',
  FETCH_AUTHOR_PARAMS: '',
  FETCH_USER_PARAMS: '',
  error: null
};

const fetchApiReducers = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COMBO_AUTHORS:
      return {
        ...state,
        ...{ FETCH_COMBO_AUTHORS: action.data }
      };
    case FETCH_MEDIAS:
      return {
        ...state,
        ...{ FETCH_MEDIAS: action.data }
      };
    case FETCH_AUTHORS:
      return {
        ...state,
        ...{ FETCH_AUTHORS: action.data },
        ...{ FETCH_AUTHOR_PARAMS: action.params }
      };
    case FETCH_COMBO_PUBLISHYEARS:
      return {
        ...state,
        ...{ FETCH_COMBO_PUBLISHYEARS: action.data },
      };
    case FETCH_COMBO_PUBLISHERS:
      return {
        ...state,
        ...{ FETCH_COMBO_PUBLISHERS: action.data },
      };
    case FETCH_COMBO_CATEGORIES:
      return {
        ...state,
        ...{ FETCH_COMBO_CATEGORIES: action.data },
      };
    case FETCH_CATEGORIES:
      return {
        ...state,
        ...{ FETCH_CATEGORIES: action.data },
        ...{ FETCH_CATEGORY_PARAMS: action.params }
      };
    case FETCH_PUBLISHERS:
      return {
        ...state,
        ...{ FETCH_PUBLISHERS: action.data },
        ...{ FETCH_PUBLISHER_PARAMS: action.params }
      };
    case FETCH_PUBLISHYEARS:
      return {
        ...state,
        ...{ FETCH_PUBLISHYEARS: action.data },
        ...{ FETCH_PUBLISHYEAR_PARAMS: action.params }
      };
    case FETCH_CATEGORY_TREE:
      return {
        ...state,
        ...{ FETCH_CATEGORY_TREE: action.data }
      }
    case FETCH_USERS:
      return {
        ...state,
        ...{ FETCH_USERS: action.data },
        ...{ FETCH_USER_PARAMS: action.params }
      };

    case FETCH_SELECTED_MEDIA:
      return {
        ...state,
        ...{ FETCH_SELECTED_MEDIA: action.data }
      };

    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}

export default fetchApiReducers;