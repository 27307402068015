import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { withTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  Button,
} from "reactstrap";
import MediaForm from "./MediaGeneralForm";
import Service from "../../../config/Service";
import _ from "lodash";
import classnames from "classnames";
import NotificationSystem from "rc-notification";
import { BasicNotification } from "../../../components/Notification";
import Utilities from "../../../shared/helpers/Utilities";
import { fetchSelectedMedias } from "../../../redux/actions/fetchApiActions";
import MediaReferenceList from "./MediaReference/MediaReferenceList";
import ChooseOptionCreateMedia from "./MediaReference/ChooseOptionCreateMedia";
import SearchExistingMedias from "./MediaReference/SearchExistingMedias";
import TimeLineItem from "../../../components/TimeLineItem";
import "../medias.scss";

let notification = null;

class EditMedia extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "media_info",
      isCreateMediaRef: false,
      isEditMediaRef: false,
      category_name: "",
      selectedMedia: [],
      selectedMediaData: {},
      selectedMediaId: 0,
      isToggleModalCreateMediaRef: false,
      isChooseExistingMedia: false,
      createMediaOption: "",
      searchMediaData: [],
      searchTitle: "",
      searchId: "",
      is_public: null,
      isEmptySearchData: false,
      isUploadingMediaRef: false,
      permissions: {},
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (!_.isEqual(state.selectedMedia, props.selectedMedia)) {
      return {
        selectedMedia: props.selectedMedia,
      };
    }
  }

  componentDidMount() {
    NotificationSystem.newInstance(
      { style: { top: 65 } },
      (n) => (notification = n)
    );
    const permissionData = Utilities.getPermission(this.props.user, "medias");
    const { history } = this.props;
    const {
      location: { state },
    } = history;
    const id = state && state.media.id;
    this.getDetailMedia(id);

    this.setState({
      permissions: permissionData,
      selectedMediaId: id,
    });
  }

  getDetailMedia = (id) => {
    const { dispatch } = this.props;
    Service.get("getDetailMedia", id)
      .then((response) => {
        if (response.data.success) {
          const selectedMedia = response.data.data;
          this.setState({ isUploadingMediaRef: false, selectedMedia });
          dispatch(fetchSelectedMedias(selectedMedia));
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  showNotification = (rtl) => {
    const { t } = this.props;
    notification.notice({
      content: (
        <BasicNotification
          title={t("GENERAL.NOTIFICATION")}
          color="primary"
          message={t("GENERAL.THE_ACTION_HAS_BEEN_COMPLETED_SUCCESSFULLY")}
        />
      ),
      duration: 5,
      closable: true,
      style: { top: 0, left: "calc(100vw - 100%)" },
      className: `right-up ${rtl}-support`,
    });
  };

  toggle = (tab) => {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
        isCreateMediaRef: false,
        isEditMediaRef: false,
      });
    }
  };

  onCancelEditMedia = () => {
    const { history } = this.props;
    const {
      location: { state },
    } = history;
    this.props.destroy();
    this.props.initialize();

    if (state && state.isFromAuthor) {
      const {
        isFromAuthor: { selectedAuthor, activeTab },
      } = state;
      history.push({
        pathname: `/module/authors/${selectedAuthor.id}/edit`,
        state: {
          activeTab,
          selectedAuthor,
        },
      });
    } else if (state && state.isFromUser) {
      const {
        isFromUser: { activeTab },
      } = state;
      history.push({
        pathname: "/account/profile",
        state: {
          activeTab,
        },
      });
    } else {
      history.push({
        pathname: "/module/medias",
        state: {
          pageOfItems: state && state.pageOfItems,
          filterValue: state && state.filterValue,
          activeTab: state && state.activeTab,
        },
      });
    }
  };

  editMediaRef = (media) => {
    Service.get("getDetailMedia", media.id).then((response) => {
      if (response.data.success) {
        const selectedMedia = response.data.data;
        this.setState({
          selectedMediaData: selectedMedia,
          isEditMediaRef: true,
          isCreateMediaRef: false,
        });
      }
    });
  };

  clearPrefillInput = async () => {
    await this.setState({
      category_name: "",
    });
    await this.props.initialize();
  };

  createMediaRef = async () => {
    await this.setState({
      isCreateMediaRef: true,
      isEditMediaRef: false,
    });
    await this.clearPrefillInput();
  };

  onCancelCreateMediaRef = async () => {
    await this.setState({
      isCreateMediaRef: false,
    });
    await this.clearPrefillInput();
  };

  onCancelEditMediaRef = async () => {
    await this.setState({
      isEditMediaRef: false,
    });
    await this.clearPrefillInput();
  };

  toggleCreateMediaRef = () => {
    const { isToggleModalCreateMediaRef, activeTab } = this.state;
    if (activeTab === "media_series") {
      this.createMediaRef();
    } else {
      this.setState({
        isToggleModalCreateMediaRef: !isToggleModalCreateMediaRef,
        searchMediaData: [],
        isEmptySearchData: false,
        isChooseExistingMedia: false,
      });
    }
    this.props.initialize();
  };

  onChangeSelect = (val, key) => {
    if (key === "is_public") {
      this.setState({
        is_public: val.value,
      });
      return;
    }
    this.setState({
      createMediaOption: val.value,
    });
  };

  confirmSelection = () => {
    const { createMediaOption } = this.state;
    if (createMediaOption !== "") {
      if (createMediaOption === "choose_existing") {
        this.setState({
          isChooseExistingMedia: true,
        });
      } else {
        this.createMediaRef();
        this.toggleCreateMediaRef();
      }
    }
  };

  onTextChange = (event) => {
    const fieldName = event.target.name;
    const value = event.target.value;
    this.setState({
      [fieldName]: value,
    });
  };

  handleSearchMedia = () => {
    const { searchTitle, searchId, is_public } = this.state;
    const params = `perPage=100&title=${searchTitle}&id=${searchId}&is_public=${is_public}`;
    if (searchTitle !== "" || searchId !== "" || is_public !== null) {
      this.getSearchMedia(params);
    } else {
      this.setState({
        searchMediaData: [],
        isEmptySearchData: true,
      });
    }
  };

  getSearchMedia = (searchParams) => {
    const { selectedMedia } = this.state;
    Service.getSearch("searchAllMedias", searchParams)
      .then((response) => {
        if (response.data.success) {
          let searchMediaData = [];
          const data = response.data.data;

          searchMediaData = _.filter(data, (media) => {
            // remove main selected media, to not display
            return media.id !== selectedMedia.id;
          });

          searchMediaData = _.filter(searchMediaData, (media) => {
            // select only media that is not series
            return media.serie !== 1;
          });

          if (!_.isEmpty(selectedMedia.media_reference_data)) {
            // remove media ref which is already added, to not display
            const mediaRef = selectedMedia.media_reference_data;
            searchMediaData = searchMediaData.filter((media) => {
              return !mediaRef.some((ref) => {
                return media.id === ref.id;
              });
            });
          }
          if (selectedMedia.media_type === "mp3") {
            searchMediaData = _.filter(searchMediaData, (media) => {
              // get only media type mp3 to display
              return media.media_type === "mp3";
            });
          }

          this.setState({
            searchMediaData: _.isEmpty(searchMediaData) ? [] : searchMediaData,
            isEmptySearchData: _.isEmpty(searchMediaData) ? true : false,
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  viewMediaContent = (mediaURL) => {
    window.open(mediaURL);
  };

  createMediaRefByExisting = (media) => {
    let { selectedMedia, selectedMediaId } = this.state;
    const reference_id =
      _.isNull(selectedMedia.reference_id) || selectedMedia.reference_id === 0 // if null reference_id, mean this is main media, so get that main media
        ? selectedMedia.id
        : selectedMedia.reference_id;
    const media_id = media.id;
    const data = {
      reference_id: reference_id,
      media_id: media_id,
    };
    this.setState({ isUploadingMediaRef: true, uploadingMediaId: media_id });

    Service.post("addMediaReferenceForExistingMedia", data)
      .then(async (response) => {
        if (response.data.success) {
          await this.getDetailMedia(selectedMediaId);
          await this.showNotification();
          await this.toggleCreateMediaRef();
        }
      })
      .catch((e) => {
        console.log(e);
        this.setState({ isUploadingMediaRef: false });
      });
  };

  render() {
    const { t } = this.props;
    const {
      activeTab,
      isEditMediaRef,
      isCreateMediaRef,
      selectedMedia,
      selectedMediaData,
      isToggleModalCreateMediaRef,
      isChooseExistingMedia,
      searchMediaData,
      isEmptySearchData,
      isUploadingMediaRef,
      permissions,
      uploadingMediaId,
    } = this.state;
    return (
      <Col md={12} lg={12}>
        {!_.isEmpty(selectedMedia) && (
          <Card>
            <CardBody>
              <div className="profile__card tabs tabs--bordered-bottom">
                <div className="tabs__wrap">
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "media_info",
                        })}
                        onClick={() => this.toggle("media_info")}
                      >
                        {t("GENERAL.DETAIL_INFORMATION")}
                      </NavLink>
                    </NavItem>
                    {selectedMedia && selectedMedia.serie === 1 && (
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === "media_series",
                          })}
                          onClick={() => this.toggle("media_series")}
                        >
                          {t("MEDIAS.MEDIA_SERIES")}
                        </NavLink>
                      </NavItem>
                    )}
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "media_reference",
                        })}
                        onClick={() => this.toggle("media_reference")}
                      >
                        {t("MEDIAS.MEDIAS_REFERENCE")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "media_logs",
                        })}
                        onClick={() => this.toggle("media_logs")}
                      >
                        {t("MEDIAS.MEDIA_LOGS")}
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent
                    style={{ overflow: "hidden" }}
                    activeTab={activeTab}
                  >
                    <TabPane tabId="media_info">
                      {activeTab === "media_info" && (
                        <MediaForm
                          isEditingMedia={true}
                          onCancel={this.onCancelEditMedia}
                          permissions={permissions}
                          selectedMedia={selectedMedia}
                        />
                      )}
                    </TabPane>
                    <TabPane tabId="media_series">
                      {activeTab === "media_series" && (
                        <div>
                          {!isEditMediaRef && !isCreateMediaRef && (
                            <MediaReferenceList
                              toggleCreateMediaRef={this.toggleCreateMediaRef}
                              selectedMedia={selectedMedia}
                              editMediaRef={this.editMediaRef}
                              mediaSeries={true}
                              onCancelEditMedia={this.onCancelEditMedia}
                            />
                          )}
                          {isCreateMediaRef && (
                            <MediaForm
                              isEditingMedia={false}
                              isCreateMediaRef={isCreateMediaRef}
                              mediaSeries={true}
                              selectedMedia={selectedMedia}
                              onCancel={this.onCancelCreateMediaRef}
                              permissions={permissions}
                            />
                          )}
                          {isEditMediaRef && (
                            <MediaForm
                              isEditingMedia={true}
                              onCancel={this.onCancelEditMediaRef}
                              permissions={permissions}
                              mediaSeries={true}
                              selectedMediaData={selectedMediaData}
                            />
                          )}
                        </div>
                      )}
                    </TabPane>

                    <TabPane tabId="media_reference">
                      {activeTab === "media_reference" && (
                        <div>
                          {!isEditMediaRef && !isCreateMediaRef && (
                            <MediaReferenceList
                              toggleCreateMediaRef={this.toggleCreateMediaRef}
                              selectedMedia={selectedMedia}
                              editMediaRef={this.editMediaRef}
                              mediaSeries={false}
                              onCancelEditMedia={this.onCancelEditMedia}
                            />
                          )}
                          {isCreateMediaRef && (
                            <MediaForm
                              isEditingMedia={false}
                              isCreateMediaRef={isCreateMediaRef}
                              mediaSeries={false}
                              selectedMedia={selectedMedia}
                              onCancel={this.onCancelCreateMediaRef}
                              permissions={permissions}
                            />
                          )}
                          {isEditMediaRef && (
                            <MediaForm
                              isEditingMedia={true}
                              onCancel={this.onCancelEditMediaRef}
                              permissions={permissions}
                              mediaSeries={false}
                              selectedMediaData={selectedMediaData}
                            />
                          )}
                        </div>
                      )}
                    </TabPane>
                    <TabPane tabId="media_logs">
                      {activeTab === "media_logs" && (
                        <div>
                          <div className="card__title">
                            <h5 className="bold-text">
                              {t("MEDIAS.MEDIA_LOGS")}
                            </h5>
                            <h5 className="subhead">{`${t(
                              "MEDIAS.BELOW_LIST_ALL_MEDIA_LOGS_OF"
                            )} ${selectedMedia.title}`}</h5>
                          </div>
                          {!_.isEmpty(selectedMedia.log_data) && (
                            <div className="timeline">
                              {_.map(selectedMedia.log_data, (item, index) => (
                                <TimeLineItem
                                  key={index.toString()}
                                  img={item.s3_photo_url}
                                  username={item.user_fullname}
                                  title={item.action_type}
                                  date={item.action_date}
                                ></TimeLineItem>
                              ))}
                            </div>
                          )}
                          {_.isEmpty(selectedMedia.log_data) && (
                            <div>
                              <h5 className="text-center p-3">
                                {t("GENERAL.EMPTY_DATA")}
                              </h5>
                            </div>
                          )}
                          <div className="d-flex justify-content-end">
                            <Button
                              type="button"
                              onClick={this.onCancelEditMedia}
                            >
                              {t("BUTTON.BACK")}
                            </Button>
                          </div>
                        </div>
                      )}
                    </TabPane>
                  </TabContent>
                </div>
              </div>
              <Modal
                isOpen={isToggleModalCreateMediaRef}
                toggle={this.toggleCreateMediaRef}
                style={{ padding: 0 }}
                className="todo__add-modal theme-light ltr-support"
              >
                <form>
                  {!isChooseExistingMedia && ( // choose to create new media
                    <ChooseOptionCreateMedia
                      onChangeSelect={this.onChangeSelect}
                      toggleCreateMediaRef={this.toggleCreateMediaRef}
                      confirmSelection={this.confirmSelection}
                    />
                  )}
                  {isChooseExistingMedia && ( // choose to add media by searching from existing media
                    <SearchExistingMedias
                      onTextChange={this.onTextChange}
                      onChangeSelect={this.onChangeSelect}
                      viewMediaContent={this.viewMediaContent}
                      toggleCreateMediaRef={this.toggleCreateMediaRef}
                      handleSearchMedia={this.handleSearchMedia}
                      // changeRating={this.changeRating}
                      createMediaRefByExisting={this.createMediaRefByExisting}
                      isEmptySearchData={isEmptySearchData}
                      isUploadingMediaRef={isUploadingMediaRef}
                      searchMediaData={searchMediaData}
                      permissions={permissions}
                      uploadingMediaId={uploadingMediaId}
                    />
                  )}
                </form>
              </Modal>
            </CardBody>
          </Card>
        )}
      </Col>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.theme,
    rtl: state.rtl,
    allComboCategories: state.fetchData.FETCH_COMBO_CATEGORIES,
    allComboAuthors: state.fetchData.FETCH_COMBO_AUTHORS,
    allComboPublishyears: state.fetchData.FETCH_COMBO_PUBLISHYEARS,
    allComboPublishers: state.fetchData.FETCH_COMBO_PUBLISHERS,
    categoryTree: state.fetchData.FETCH_CATEGORY_TREE,
    selectedMedia: state.fetchData.FETCH_SELECTED_MEDIA,
    user: state.user,
  };
};

EditMedia = reduxForm({
  form: "medias_form--vertical",
})(EditMedia);

export default connect(mapStateToProps)(withTranslation("common")(EditMedia));
