import React, { Component } from "react";
import { Col, Button, Row, Spinner, Modal } from "reactstrap";
import {
  RenderInputField,
  RenderTextArea,
  RenderFileInputField,
} from "../../../components/RenderField";
import renderCheckBoxField from "../../../components/form/CheckBox";
import { BsChevronRight } from "react-icons/bs";
import { BasicNotification } from "../../../components/Notification";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import Service from "../../../config/Service";
import renderSelectField from "../../../components/form/Select";
import validate from "./validate";
import chooseImage from "../../../components/ChooseImage/RetangleChooseImage";
import _ from "lodash";
import NotificationSystem from "rc-notification";
import renderInputField from "../../../components/RenderField/RenderInputField";
import {
  fetchSelectedMedias,
  fetchActionComboPublishers,
  fetchActionComboAuthors,
} from "../../../redux/actions/fetchApiActions";
import CustomActionButtonModal from "../../DeleteModal/CustomActionModal";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import CardSubtitle from "reactstrap/lib/CardSubtitle";
import CategoryTree from "./CategoryTree";
import CreateAuthor from "../../Author/components/CreateAuthor";
import CreatePublisher from "../../Publisher/components/AddPublisher";

let notification = null;
let endPoint = "medias";

let noneMp3Options = [
  {
    label: "pdf",
    value: "0",
  },
  {
    label: "epub",
    value: "3",
  },
  {
    label: "mp3",
    value: "1",
  },
  {
    label: "mp4",
    value: "2",
  },
];
const searchParams = "page=1&perPage=200";
class MediaGeneralForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isToggleModal: false,
      selectedCategory: "",
      media_cover_file: {},
      media_file: {},
      isUploading: false,
      mediaType: "pdf",
      acceptFileType: ".pdf",
      isSponsor: false,
      isMediaSerie: false,
      sponserData: {},
      public_criterias: "",
      category_name: "",
      selectedMedia: {},
      isOpenModalConfirm: false,
      isPublishingMedia: false,
      categoryTree: props.categoryTree,
      mediasType: noneMp3Options,
      isShowCreatePublisher: false,
      isShowCreateAuthor: false,
      allComboPublishers: props.allComboPublishers,
      allComboAuthors: props.allComboAuthors,
      formFields: [
        {
          label: "MEDIAS.MEDIAS_TYPE",
          name: "media_type",
          component: renderSelectField,
          options: noneMp3Options,
        },
        {
          label: "MEDIAS.CHOOSE_MEDIA",
          name: "media_file",
          component: RenderFileInputField,
        },
        {
          label: "GENERAL.TITLE",
          type: "text",
          name: "title",
          component: RenderInputField,
        },
        {
          label: "GENERAL.TITLE_EN",
          name: "title_en",
          type: "text",
          component: RenderInputField,
        },
        {
          label: "GENERAL.ISBN",
          name: "isbn",
          type: "text",
          component: RenderInputField,
        },
        {
          label: "GENERAL.SEARCH_KEYWORD",
          name: "search_keyword",
          type: "text",
          component: RenderInputField,
        },
        {
          label: "GENERAL.PUBLISHER",
          name: "publisher",
          field_type: "combo_box",
          component: renderSelectField,
          options: props.allComboPublishers,
        },
        {
          label: "GENERAL.AUTHOR",
          name: "author",
          field_type: "combo_box",
          component: renderSelectField,
          options: props.allComboAuthors,
        },
        {
          label: "GENERAL.DESCRIPTION",
          name: "description",
          type: "text",
          component: RenderTextArea,
        },
        {
          label: "GENERAL.DESCRIPTION_EN",
          name: "description_en",
          type: "text",
          component: RenderTextArea,
        },
        {
          label: "GENERAL.PUBLISH_YEAR",
          name: "publish_year",
          type: "number",
          component: RenderInputField,
        },
      ],
    };
  }

  componentDidMount() {
    NotificationSystem.newInstance(
      { style: { top: 65 } },
      (n) => (notification = n)
    );
    const { isCreateMainMedia } = this.props;
    if (!isCreateMainMedia) {
      this.setInitialValue();
    }
  }

  setInitialValue = async () => {
    const {
      isEditingMedia,
      selectedMedia,
      mediaSeries,
      selectedMediaData,
      isCreateMediaRef,
    } = this.props;
    const currentMedia = selectedMediaData ? selectedMediaData : selectedMedia;

    await this.checkMediaType(currentMedia);

    if (isEditingMedia || mediaSeries) {
      await this.addPrefillInput(currentMedia);
    }

    if (isCreateMediaRef) {
      endPoint = "addMediaReferenceForNewMedia";
    } else {
      endPoint = "medias";
    }

    await this.setState({
      mediaType: currentMedia.media_type,
      selectedMedia: currentMedia,
    });

    await this.checkFormFieldType(currentMedia);

    await this.checkPrefillCheckbox(isEditingMedia, currentMedia, mediaSeries);
  };

  checkFormFieldType = (currentMedia) => {
    const { isMediaRef } = this.state;
    const { isCreateMediaRef } = this.props;
    if (currentMedia.media_type === "mp3") {
      this.setState({
        acceptFileType: ".mp3",
      });
      if (isCreateMediaRef || isMediaRef) {
        this.mp3FieldForm();
      } else {
        this.noneMp3FieldForm();
      }
    } else {
      let acceptFileType = ".pdf";
      if (currentMedia.media_type === "epub") {
        acceptFileType = ".epub";
      } else if (currentMedia.media_type === "mp4") {
        acceptFileType = "video/*";
      } else {
        acceptFileType = ".pdf";
      }
      this.setState({
        mediasType: noneMp3Options,
        acceptFileType,
      });
      this.noneMp3FieldForm();
    }
  };

  checkPrefillCheckbox = (isEditingMedia, currentMedia, mediaSeries) => {
    if (!isEditingMedia && !mediaSeries) {
      this.setState({
        isMediaSerie: false,
        isSponsor: false,
        category_name: "",
      });
    } else {
      let { public_criterias } = this.state;
      if (!_.isEmpty(currentMedia.public_criteria_data)) {
        _.map(currentMedia.public_criteria_data, (item) => {
          if (item.selected) {
            public_criterias = public_criterias.concat(item.id, ",");
          }
          return public_criterias;
        });
      }
      this.setState({
        isMediaSerie: currentMedia.serie === 0 ? false : true,
        isSponsor: currentMedia.is_sponsor,
        category_name: currentMedia.category_name,
        public_criterias,
      });
    }
  };

  checkMediaType = (currentMedia) => {
    let isMediaRef = false;
    if (currentMedia.reference_id === 0) {
      isMediaRef = false;
    } else {
      if (currentMedia.reference_id === currentMedia.id) {
        isMediaRef = false;
      } else {
        isMediaRef = true;
      }
    }
    this.setState({
      isMediaRef,
    });
  };

  addPrefillInput = async (media) => {
    const { mediasType } = this.state;
    const { mediaSeries, isEditingMedia } = this.props;
    const currentMediaType = mediasType.find(
      (item) => item.label === media.media_type
    );
    await this.setState({
      category_name: media.category_name,
    });
    await this.props.initialize({
      is_sponsor: media.is_sponsor,
      serie: media.serie === 0 ? false : true,
      title: media.title,
      title_en: media.title_en,
      description: media.description,
      description_en: media.description_en,
      is_public: media.is_public,
      created_at: media.created_at,
      isbn: media.isbn,
      publish_year: media.publish_year,
      sortkey: media.serie === 1 ? media.sortkey : "",
      category: {
        label: media.category_name,
        value: media.category_id,
      },
      author: {
        label: media.author_fullname,
        value: media.author_id,
      },
      publisher: {
        label: media.publisher_name,
        value: media.publisher_id,
      },
      media_type: {
        label: currentMediaType.label,
        value: currentMediaType.value,
      },
      search_keyword: media.search_keyword,
      media_cover:
        !isEditingMedia && mediaSeries ? "" : media.s3_cover_media_url,
      media_file: !isEditingMedia && mediaSeries ? "" : media.s3_media_url,
      sponsor_organization: media.sponsor_organization,
      sponsor_url: media.sponsor_url,
    });
  };

  showNotification = (rtl) => {
    const { t } = this.props;
    notification.notice({
      content: (
        <BasicNotification
          title={t("GENERAL.NOTIFICATION")}
          color="primary"
          message={t("GENERAL.THE_ACTION_HAS_BEEN_COMPLETED_SUCCESSFULLY")}
        />
      ),
      duration: 5,
      closable: true,
      style: { top: 0, left: "calc(100vw - 100%)" },
      className: `right-up ${rtl}-support`,
    });
  };

  viewMediaContent = (url) => {
    window.open(url);
  };

  onSaveMedia = (value) => {
    const { mediaType } = this.state;
    const { isEditingMedia, isCreateMainMedia } = this.props;
    // onsubmit only call when it is not create new author/publisher
    if (mediaType === "mp3" && !isEditingMedia && !isCreateMainMedia) {
      this.createMediaRefTypeMp3(value);
    } else {
      this.createMedia(value);
    }
  };

  onTextChange = (event) => {
    const fieldName = event.target.name;
    const sponserData = { ...this.state.sponserData };
    sponserData[fieldName] = event.target.value;

    this.setState({ sponserData });
  };

  createMedia = (value) => {
    const { selectedCategory, public_criterias } = this.state;
    const { isEditingMedia, isCreateMainMedia, mediaSeries } = this.props;
    let category_id = 0;
    if (value.category) {
      category_id = _.isEmpty(selectedCategory)
        ? value.category.value
        : value.category;
    }
    const data = {
      title: value.title,
      title_en: value.title_en,
      description: value.description,
      description_en: value.description_en,
      author_id: value.author.value,
      publisher_id: value.publisher.value,
      search_keyword: value.search_keyword || "",
      publish_year: value.publish_year,
      isbn: value.isbn || "",
      category_id: category_id || 0,
      is_sponsor: value.is_sponsor ? 1 : 0,
      serie: mediaSeries ? 1 : value.serie ? 1 : 0,
      sponsor_url: value.sponsor_url || "",
      sponsor_organization: value.sponsor_organization || "",
    };

    const formData = new FormData();
    for (const [key, value] of Object.entries(data)) {
      formData.append(key, value);
    }

    if (!isCreateMainMedia) {
      //if not isCreateMainMedia, required id/reference_id
      const { selectedMedia } = this.state;
      if (isEditingMedia) {
        // if edit media, send that "id"
        formData.append("id", selectedMedia.id);
      } else {
        // if create media reference, send "reference_id"
        const referenceId =
          _.isNull(selectedMedia.reference_id) ||
          selectedMedia.reference_id === 0 // if null reference_id, mean this is main media, so get that main media
            ? selectedMedia.id
            : selectedMedia.reference_id; // if not null reference_id, get reference_id which is mean id of main media
        formData.append("reference_id", referenceId);
      }

      // if create media series
      if (mediaSeries) {
        formData.append("sortkey", value.sortkey);
      }
    }

    if (value.media_cover && value.media_cover.name) {
      formData.append("s3_media_cover", value.media_cover.file);
    }

    if (value.media_file && value.media_file.name) {
      formData.append("s3_media", value.media_file.file);
    }

    if (public_criterias !== "") {
      const trimPublicCriterias = public_criterias.slice(0, -1);
      formData.append("public_criterias", trimPublicCriterias);
    }

    // for (var pair of formData.entries()) {
    //   console.log(pair[0] + ", " + pair[1]);
    // }
    this.postMedia(formData);
  };

  createMediaRefTypeMp3 = (value) => {
    const { selectedMedia } = this.state;
    const { mediaSeries } = this.props;
    const referenceId =
      _.isNull(selectedMedia.reference_id) || selectedMedia.reference_id === 0
        ? selectedMedia.id
        : selectedMedia.reference_id;
    const data = {
      title: value.title,
      title_en: value.title_en,
      description: value.description,
      description_en: value.description_en,
      author_id: selectedMedia.author_id,
      publisher_id: selectedMedia.publisher_id,
      search_keyword: selectedMedia.search_keyword,
      publish_year: selectedMedia.publish_year,
      isbn: selectedMedia.isbn || "",
      category_id: selectedMedia.category_id,
      reference_id: referenceId,
      s3_media: value.media_file.file,
      is_sponsor: value.is_sponsor ? 1 : 0,
      serie: mediaSeries ? 1 : value.serie ? 1 : 0,
      sponsor_url: value.sponsor_url || "",
      sponsor_organization: value.sponsor_organization || "",
    };
    const formData = new FormData();

    for (const [key, value] of Object.entries(data)) {
      formData.append(key, value);
    }
    // if create media series
    if (mediaSeries) {
      formData.append("sortkey", value.sortkey);
    }
    // for (var pair of formData.entries()) {
    //   console.log("mp3 ref", pair[0] + ", " + pair[1]);
    // }
    this.postMedia(formData);
  };

  postMedia = (formData) => {
    this.setState({ isUploading: true });

    Service.post(endPoint, formData)
      .then((response) => {
        if (response.data.success) {
          const data = response.data.data;
          this.getDetailMedia(data);
        }
      })
      .catch((e) => {
        console.log(e);
        this.setState({ isUploading: false });
      });
  };

  getDetailMedia = (data) => {
    const { dispatch, history, isCreateMainMedia } = this.props;

    const {
      location: { state },
    } = history;
    const id = isCreateMainMedia ? data.id : state && state.media.id;
    Service.get("getDetailMedia", id)
      .then((response) => {
        if (response.data.success) {
          const selectedMedia = response.data.data;
          this.showNotification();
          this.setState({ isUploading: false, selectedMedia });
          dispatch(fetchSelectedMedias(selectedMedia));
          if (isCreateMainMedia) {
            history.push({
              pathname: "/module/medias/edit",
              state: {
                media: selectedMedia,
              },
            });
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  onToogleModal = () => {
    const { isToggleModal, isUploading } = this.state;
    if (!isUploading) {
      this.setState({
        isToggleModal: !isToggleModal,
      });
    }
  };

  onConfirmSelectedTree = () => {
    const { isToggleModal, selectedCategory } = this.state;
    const { categoryTree } = this.props;
    this.setState({
      isToggleModal: !isToggleModal,
      selectedCategory,
      categoryTree,
    });
  };

  onCancelSelectedTree = () => {
    const { isToggleModal } = this.state;
    const { categoryTree } = this.props;
    this.setState({
      isToggleModal: !isToggleModal,
      selectedCategory: "",
      categoryTree,
    });
  };

  onChangeCategory = (node) => {
    this.setState({
      selectedCategory: node.text,
    });
  };

  mp3FieldForm = () => {
    this.setState({
      formFields: [
        {
          label: "GENERAL.TITLE",
          name: "title",
          type: "text",
          component: RenderInputField,
        },
        {
          label: "GENERAL.TITLE_EN",
          name: "title_en",
          type: "text",
          component: RenderInputField,
        },
        {
          label: "GENERAL.DESCRIPTION",
          name: "description",
          type: "text",
          component: RenderTextArea,
        },
        {
          label: "GENERAL.DESCRIPTION_EN",
          name: "description_en",
          type: "text",
          component: RenderTextArea,
        },
        {
          label: "MEDIAS.CHOOSE_MEDIA",
          name: "media_file",
          component: RenderFileInputField,
        },
      ],
    });
  };

  noneMp3FieldForm = () => {
    const { mediasType, allComboPublishers, allComboAuthors } = this.state;
    // const selectFileComponent = isEditingMedia
    //   ? RenderInputField
    //   : RenderFileInputField;

    this.setState({
      formFields: [
        {
          label: "MEDIAS.MEDIAS_TYPE",
          name: "media_type",
          component: renderSelectField,
          options: mediasType,
        },
        {
          label: "MEDIAS.CHOOSE_MEDIA",
          name: "media_file",
          component: RenderFileInputField,
        },
        {
          label: "GENERAL.TITLE",
          name: "title",
          type: "text",
          component: RenderInputField,
        },
        {
          label: "GENERAL.TITLE_EN",
          name: "title_en",
          type: "text",
          component: RenderInputField,
        },
        {
          label: "GENERAL.ISBN",
          name: "isbn",
          type: "text",
          component: RenderInputField,
        },
        {
          label: "GENERAL.SEARCH_KEYWORD",
          name: "search_keyword",
          type: "text",
          component: RenderInputField,
        },
        {
          label: "GENERAL.PUBLISHER",
          name: "publisher",
          field_type: "combo_box",
          component: renderSelectField,
          options: allComboPublishers,
        },
        {
          label: "GENERAL.AUTHOR",
          name: "author",
          field_type: "combo_box",
          component: renderSelectField,
          options: allComboAuthors,
        },
        {
          label: "GENERAL.DESCRIPTION",
          name: "description",
          type: "text",
          component: RenderTextArea,
        },
        {
          label: "GENERAL.DESCRIPTION_EN",
          name: "description_en",
          type: "text",
          component: RenderTextArea,
        },
        {
          label: "GENERAL.PUBLISH_YEAR",
          name: "publish_year",
          type: "number",
          component: RenderInputField,
        },
      ],
    });
  };

  onChooseMediaType = (item) => {
    let acceptFileType = ".pdf";
    if (item.label === "pdf") {
      acceptFileType = ".pdf";
    } else if (item.label === "mp3") {
      acceptFileType = ".mp3";
    } else {
      acceptFileType = "video/*";
    }

    this.setState({
      mediaType: item.label,
      acceptFileType,
    });
  };

  onCheckbox = (e, key) => {
    if (e.currentTarget) {
      if (key === "is_sponsor") {
        this.setState({
          isSponsor: e.target.checked,
        });
      } else if (key === "serie") {
        this.setState({
          isMediaSerie: e.target.checked,
        });
      } else {
        let { public_criterias } = this.state;
        if (e.target.checked) {
          public_criterias = public_criterias.concat(key.id, ",");
          this.setState({
            public_criterias,
          });
        } else {
          public_criterias = public_criterias.replace(`${key.id},`, "");
          this.setState({
            public_criterias,
          });
        }
      }
    }
  };

  handleSearchCategory = (data) => {
    const params = `name=${data.name}&name_en=${data.name_en}&id=${data.id}`;
    Service.getSearch("user/searchCategory", params).then((response) => {
      if (response.status === 200) {
        const data = response.data;
        this.setState({
          categoryTree: data,
        });
      }
    });
  };

  handleApprove = (media) => {
    const data = { media_id: media.id };
    this.setState({
      isPublishingMedia: true,
    });
    Service.post("user/publicMedia", data).then((response) => {
      if (response.data.success) {
        const { t, rtl } = this.props;
        notification.notice({
          content: (
            <BasicNotification
              title={t("ACTION_CONTROL.APPROVE")}
              color="primary"
              message={t("GENERAL.THE_ACTION_HAS_BEEN_COMPLETED_SUCCESSFULLY")}
            />
          ),
          duration: 5,
          closable: true,
          style: { top: 0, left: "calc(100vw - 100%)" },
          className: `right-up ${rtl}-support`,
        });
        Service.get("getDetailMedia", media.id)
          .then((response) => {
            if (response.data.success) {
              const selectedMedia = response.data.data;
              this.addPrefillInput(selectedMedia);
              this.setState({
                selectedMedia,
                isPublishingMedia: false,
              });
            }
          })
          .catch((e) => {
            console.log(e);
            this.setState({
              isPublishingMedia: false,
            });
          });
      }
    });
  };

  toggleConfirmModal = () => {
    const { isOpenModalConfirm } = this.state;
    this.setState({ isOpenModalConfirm: !isOpenModalConfirm });
  };

  handlePublicMedia = (media) => {
    const data = {
      media_id: media.id,
      is_public: 1,
    };
    const { selectedMediaId } = this.state;
    Service.post("user/publicMedia", data)
      .then(async (response) => {
        if (response.data.success) {
          await this.getDetailMedia(selectedMediaId);
          await this.toggleConfirmModal();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  toggleCreateNewCombo = (name) => {
    const { isShowCreatePublisher, isShowCreateAuthor } = this.state;
    if (name === "publisher") {
      // create new publisher
      this.setState({ isShowCreatePublisher: !isShowCreatePublisher });
    } else {
      // create new author
      this.setState({ isShowCreateAuthor: !isShowCreateAuthor });
    }
  };

  refreshAfterCreatePublisher = () => {
    const { dispatch } = this.props;
    const { selectedMedia } = this.state;
    let option_publishers = [];
    Service.getSearch("publishers", searchParams)
      .then(async (response) => {
        if (response) {
          const entries = response.data.data;
          for (var i = 0; i < entries.length; i++) {
            option_publishers.push({
              value: entries[i].id,
              label: entries[i].name,
            });
          }
          await this.setState({
            allComboPublishers: option_publishers,
          });
          await this.checkFormFieldType(selectedMedia);
          await dispatch(fetchActionComboPublishers(option_publishers));
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  refreshAfterCreateAuthor = () => {
    const { dispatch } = this.props;
    const { selectedMedia } = this.state;
    let option_authors = [];
    Service.getSearch("authors", searchParams)
      .then(async (response) => {
        if (response) {
          const entries = response.data.data;
          for (var i = 0; i < entries.length; i++) {
            option_authors.push({
              value: entries[i].id,
              label: entries[i].fullname,
            });
          }
          await this.setState({
            allComboAuthors: option_authors,
          });
          await this.checkFormFieldType(selectedMedia);
          await dispatch(fetchActionComboAuthors(option_authors));
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  render() {
    const {
      t,
      handleSubmit,
      isEditingMedia,
      pristine,
      isCreateMainMedia,
      permissions,
      isCreateMediaRef,
      mediaSeries,
    } = this.props;
    const {
      isToggleModal,
      selectedCategory,
      isUploading,
      mediaType,
      formFields,
      isSponsor,
      category_name,
      selectedMedia,
      isOpenModalConfirm,
      isMediaRef,
      acceptFileType,
      isMediaSerie,
      categoryTree,
      isPublishingMedia,
      isShowCreateAuthor,
      isShowCreatePublisher,
    } = this.state;
    return (
      <form
        className="form"
        onSubmit={
          !isShowCreateAuthor &&
          !isShowCreatePublisher &&
          handleSubmit(this.onSaveMedia)
        }
      >
        <div className="card__title">
          <h5 className="bold-text">{t("MEDIAS.MEDIA_CATEGORY_TITLE")}</h5>
          <h5 className="subhead">{t("MEDIAS.MEDIA_CATEGORY_SUBTITLE")}</h5>
        </div>
        <Col md={12} sm={12}>
          <div className="media__edit-form-col">
            <div className="form__form-group">
              {mediaType === "mp3" &&
              !isEditingMedia &&
              !isCreateMainMedia &&
              isCreateMediaRef ? (
                <div className="media">
                  <img
                    className="media__cover-img"
                    src={selectedMedia.s3_cover_media_url}
                    alt={selectedMedia.title}
                  />
                </div>
              ) : (
                <div className="form__form-group-field">
                  {mediaType === "mp3" && isMediaRef ? ( // disallow choose new media cover when it is mp3 and type media reference
                    <div className="media">
                      <img
                        className="media__cover-img"
                        src={selectedMedia.s3_cover_media_url}
                        alt={selectedMedia.title}
                      />
                    </div>
                  ) : (
                    <Field name="media_cover" component={chooseImage} />
                  )}
                </div>
              )}
              {isEditingMedia &&
                (selectedMedia.media_type !== "mp3" ? (
                  <div className="media">
                    <div className="media__edit-file-btn">
                      <Button
                        type="button"
                        color="primary"
                        onClick={() =>
                          this.viewMediaContent(selectedMedia.s3_media_url)
                        }
                        size="sm"
                      >
                        {t("BUTTON.VIEW_MEDIA")}
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className="media mt-3 mb-4">
                    <AudioPlayer
                      src={selectedMedia.s3_media_url}
                      style={{ background: "transparent", boxShadow: "none" }}
                    />
                  </div>
                ))}
            </div>
          </div>
          <Row>
            {_.map(formFields, (item) => (
              <Col md={6} sm={12}>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    {t(item.label)}
                  </span>
                  {item.name === "media_type" && isEditingMedia && (
                    <span className="red-text">
                      {"   "}
                      {t("MEDIAS.MEDIA_TYPE_CANNOT_CHANGE")}
                    </span>
                  )}
                  <div className="form__form-group-field">
                    {item.name === "media_file" && isEditingMedia ? (
                      <div
                        className="media__view-file-url"
                        onClick={() =>
                          this.viewMediaContent(selectedMedia.s3_media_url)
                        }
                      >
                        {selectedMedia.s3_media_url}
                      </div>
                    ) : (
                      <>
                        <Field
                          name={item.name}
                          type={item.type}
                          component={item.component}
                          options={item.options ? item.options : ""}
                          accept={
                            item.name === "media_file" ? acceptFileType : ""
                          }
                          isDisabled={
                            item.name === "media_type" && isEditingMedia
                          }
                          onChange={
                            item.name === "media_type" && this.onChooseMediaType
                          }
                        />
                        {item.field_type === "combo_box" && (
                          <Button
                            type="button"
                            color="primary"
                            size="sm"
                            style={{
                              height: 38,
                              marginBottom: 0,
                              marginLeft: "5px",
                            }}
                            onClick={() => this.toggleCreateNewCombo(item.name)}
                          >
                            + {t("GENERAL.CREATE_A_NEW")}
                          </Button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </Col>
            ))}
            {mediaType === "mp3" && (isCreateMediaRef || isMediaRef) ? ( // mp3 + type create media
              <div />
            ) : (
              <Col md={6}>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    {t("GENERAL.CATEGORY")}
                  </span>
                  <div className="media__category">
                    <div
                      onClick={this.onToogleModal}
                      className="media__category--toggle-view"
                    >
                      <div className="d-flex__space-between">
                        <span style={{ color: "#646777" }}>
                          {selectedCategory !== ""
                            ? selectedCategory
                            : category_name}
                        </span>
                        <BsChevronRight color="#dddddd" />
                      </div>
                    </div>
                  </div>
                </div>
                <Modal
                  isOpen={isToggleModal}
                  toggle={this.onToogleModal}
                  style={{ padding: 0 }}
                  className="todo__add-modal theme-light ltr-support"
                >
                  <div className="card__title">
                    <h5 className="bold-text">
                      {t("MEDIAS.MEDIA_CATEGORY_MODAL_TITLE")}
                    </h5>
                    <h5 className="subhead">
                      {t("MEDIAS.MEDIA_CATEGORY_MODAL_SUBTITLE")}
                    </h5>
                  </div>
                  <div className="media__category">
                    <div className="category-modal-content">
                      <CategoryTree
                        categoryTree={categoryTree}
                        onChangeCategory={this.onChangeCategory}
                        onSearchCategory={this.handleSearchCategory}
                        onCancelSelectedTree={this.onCancelSelectedTree}
                        onConfirmSelectedTree={this.onConfirmSelectedTree}
                      />
                    </div>
                  </div>
                </Modal>
              </Col>
            )}
            {mediaSeries && ( // sortkey exist only when media is type serie
              <Col md={6} sm={12}>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    {t("GENERAL.SORT_KEY")}
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      name="sortkey"
                      type="number"
                      component={renderInputField}
                    />
                  </div>
                </div>
              </Col>
            )}
            {!isMediaRef && !isCreateMediaRef && (
              <Col md={12} className="mb-3">
                <div className="form__form-group-field">
                  <Field
                    name="serie"
                    component={renderCheckBoxField}
                    onChange={(e) => this.onCheckbox(e, "serie")}
                    label={t("MEDIAS.IS_THIS_MEDIA_HAVE_SERIE")}
                    checked={isMediaSerie}
                    disabled={
                      isMediaSerie && !_.isEmpty(selectedMedia.media_serie_data)
                    }
                  />
                </div>
                {isMediaSerie && !_.isEmpty(selectedMedia.media_serie_data) && (
                  <CardSubtitle className="red-text" style={{ fontSize: 11 }}>
                    {t("MEDIAS.MEDIA_SERIES_CANNOT_UNCHECK")}
                  </CardSubtitle>
                )}
              </Col>
            )}

            <Col md={12}>
              <div className="form__form-group-field">
                <Field
                  name="is_sponsor"
                  component={renderCheckBoxField}
                  onChange={(e) => this.onCheckbox(e, "is_sponsor")}
                  label={t("MEDIAS.IS_SPONSOR_BY_ANY_ORGANIZATION")}
                  checked={isSponsor}
                />
              </div>
            </Col>
            {isSponsor ? (
              <>
                <Col md={6}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t("MEDIAS.SPONSOR_URL")}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="sponsor_url"
                        component={renderInputField}
                        onChange={this.onTextChange}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t("MEDIAS.SPONSOR_ORGANIZATION")}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="sponsor_organization"
                        component={renderInputField}
                        onChange={this.onTextChange}
                      />
                    </div>
                  </div>
                </Col>
              </>
            ) : (
              <div />
            )}
            {isEditingMedia && selectedMedia.public_criteria_data && (
              <Col md={12} className="public-criteria-data">
                <p className="form__form-group-label mt-2 mb-2">
                  {`${t("MEDIAS.PUBLIC_CRITERIA_DATA")}:`}
                </p>
                <Row>
                  {_.map(selectedMedia.public_criteria_data, (item, index) => (
                    <Col md={6}>
                      <div
                        className="form__form-group-field"
                        key={index.toString()}
                      >
                        <Field
                          name={item.name}
                          component={renderCheckBoxField}
                          onChange={(e) => this.onCheckbox(e, item)}
                          defaultChecked={item.selected}
                          label={item.name}
                          checked={item.selected}
                        />
                      </div>
                    </Col>
                  ))}
                </Row>
              </Col>
            )}
          </Row>
        </Col>
        <div className="media">
          <div className="media__edit-footer-btn">
            {permissions &&
              permissions.approve &&
              !selectedMedia.is_public &&
              !isCreateMediaRef && (
                <div className="mr-3">
                  <CustomActionButtonModal
                    color="primary"
                    btnOutline
                    size="lg"
                    title={t("MEDIAS.WOULD_YOU_LIKE_TO_PUBLIC_THIS_MEDIA")}
                    btn={t("BUTTON.PUBLIC")}
                    message={selectedMedia.title}
                    modal={isOpenModalConfirm}
                    loading={isPublishingMedia}
                    toggle={this.toggleConfirmModal}
                    actionCallApi={() => this.handlePublicMedia(selectedMedia)}
                  />
                </div>
              )}
            <Button
              color="primary"
              type="submit"
              disabled={isUploading || pristine}
            >
              {isUploading ? (
                <Spinner animation="border" variant="primary" size="sm" />
              ) : isEditingMedia ? (
                t("BUTTON.EDIT")
              ) : (
                t("GENERAL.SAVE")
              )}
            </Button>
            <Button
              type="button"
              onClick={this.props.onCancel}
              disabled={isUploading}
            >
              {t("BUTTON.BACK")}
            </Button>
          </div>
        </div>
        <CreateAuthor
          toggleModal={() => this.toggleCreateNewCombo("author")}
          isPopupModal={isShowCreateAuthor}
          isEdit={false}
          selectedAuthor={null}
          refreshAfterCreate={this.refreshAfterCreateAuthor}
        />
        <CreatePublisher
          isOpen={isShowCreatePublisher}
          toggleModal={() => this.toggleCreateNewCombo("publisher")}
          refreshAfterCreate={this.refreshAfterCreatePublisher}
        />
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.theme,
    rtl: state.rtl,
    allComboCategories: state.fetchData.FETCH_COMBO_CATEGORIES,
    allComboAuthors: state.fetchData.FETCH_COMBO_AUTHORS,
    allComboPublishers: state.fetchData.FETCH_COMBO_PUBLISHERS,
    categoryTree: state.fetchData.FETCH_CATEGORY_TREE,
  };
};

MediaGeneralForm = reduxForm({
  form: "medias_form--vertical",
  validate,
  shouldValidate: () => true,
})(MediaGeneralForm);

export default connect(mapStateToProps)(
  withRouter(withTranslation("common")(MediaGeneralForm))
);
