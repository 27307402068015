import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { Collapse } from 'reactstrap';
import DownIcon from 'mdi-react/ChevronDownIcon';
import PropTypes from 'prop-types';

const gb = `${process.env.PUBLIC_URL}/img/language/gb.png`;
const kh = `${process.env.PUBLIC_URL}/img/language/kh.png`;

const GbLng = () => (
  <span className="topbar__language-btn-title">
    <img src={gb} alt="gb" />
    <span>EN</span>
  </span>
);

const KhLng = () => (
  <span className="topbar__language-btn-title">
    <img src={kh} alt="kh" />
    <span>Kh</span>
  </span>
);

class TopbarLanguage extends PureComponent {
  static propTypes = {
    i18n: PropTypes.shape({ changeLanguage: PropTypes.func }).isRequired,
  };

  constructor() {
    super();
    this.state = {
      collapse: false,
      mainButtonContent: <KhLng />,
    };
  }

  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
  };

  changeLanguage = (lng) => {
    const { i18n } = this.props;
    i18n.changeLanguage(lng);
    switch (lng) {
      case 'kh':
        this.setState({ mainButtonContent: <KhLng /> });
        this.toggle();
        break;
      case 'en':
        this.setState({ mainButtonContent: <GbLng /> });
        this.toggle();
        break;
      default:
        this.setState({ mainButtonContent: <KhLng /> });
        break;
    }
  };

  render() {
    const { mainButtonContent, collapse } = this.state;

    return (
      <div className="topbar__collapse topbar__collapse--language">
        <button className="topbar__btn" type="button" onClick={this.toggle}>
          {mainButtonContent}
          <DownIcon className="topbar__icon" />
        </button>
        {collapse && <button className="topbar__back" type="button" onClick={this.toggle} />}
        <Collapse
          isOpen={collapse}
          className="topbar__collapse-content topbar__collapse-content--language"
        >
          <button
            className="topbar__language-btn"
            type="button"
            onClick={() => this.changeLanguage('kh')}
          >
            <KhLng />
          </button>
          <button
            className="topbar__language-btn"
            type="button"
            onClick={() => this.changeLanguage('en')}
          >
            <GbLng />
          </button>

        </Collapse>
      </div>
    );
  }
}

export default withTranslation('common')(TopbarLanguage);
