import React, { PureComponent } from "react";
import {
  Card,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
} from "reactstrap";
import classnames from "classnames";
import MediaList from "./MediaList";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
class ProfileTabs extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "total",
    };
  }

  componentDidMount() {
    const { history } = this.props;
    const {
      location: { state },
    } = history;
    this.setState({
      activeTab: (state && state.activeTab) || "total",
    });
  }

  toggle = (tab) => {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  toggleDetail = (media) => {
    const { history } = this.props;
    const { activeTab } = this.state;
    history.push({
      pathname: "/module/medias/edit",
      state: {
        pageOfItems: 1,
        filterValue: {},
        media: media,
        isFromUser: {
          activeTab,
        },
      },
    });
  };

  viewMediaContent = (mediaURL) => {
    window.open(mediaURL);
  };

  render() {
    const { activeTab } = this.state;
    const { userInfo, t } = this.props;
    return (
      <Col md={12} lg={12} xl={8}>
        <Card>
          <div className="profile__card tabs tabs--bordered-bottom">
            <div className="tabs__wrap">
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "total" })}
                    onClick={() => {
                      this.toggle("total");
                    }}
                  >
                    {t("MEDIAS.ALL_MEDIAS")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "approved" })}
                    onClick={() => {
                      this.toggle("approved");
                    }}
                  >
                    {t("MEDIAS.PUBLIC_MEDIAS")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "pending" })}
                    onClick={() => {
                      this.toggle("pending");
                    }}
                  >
                    {t("MEDIAS.PENDIGN_MEDIAS")}
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="total">
                  <Col md={12}>
                    <Row>
                      {userInfo.link_to_many_medias &&
                        userInfo.link_to_many_medias.map((item, index) => (
                          <Col sm={12} md={6} key={index.toString()}>
                            <MediaList
                              media={item}
                              viewDetail={this.toggleDetail}
                              viewMediaContent={this.viewMediaContent}
                              user={userInfo}
                            />
                          </Col>
                        ))}
                    </Row>
                  </Col>
                </TabPane>
                <TabPane tabId="approved">
                  <Col md={12}>
                    <Row>
                      {userInfo.link_to_many_medias_approving &&
                        userInfo.link_to_many_medias_approving.map(
                          (item, index) => (
                            <Col sm={12} md={6} key={index.toString()}>
                              <MediaList
                                media={item}
                                viewDetail={this.toggleDetail}
                                viewMediaContent={this.viewMediaContent}
                                user={userInfo}
                              />
                            </Col>
                          )
                        )}
                    </Row>
                  </Col>
                </TabPane>
                <TabPane tabId="pending">
                  <Col md={12}>
                    <Row>
                      {userInfo.link_to_many_medias_pending &&
                        userInfo.link_to_many_medias_pending.map(
                          (item, index) => (
                            <Col sm={12} md={6} key={index.toString()}>
                              <MediaList
                                media={item}
                                viewDetail={this.toggleDetail}
                                viewMediaContent={this.viewMediaContent}
                                user={userInfo}
                              />
                            </Col>
                          )
                        )}
                    </Row>
                  </Col>
                </TabPane>
              </TabContent>
            </div>
          </div>
        </Card>
      </Col>
    );
  }
}
export default withRouter(withTranslation("common")(ProfileTabs));
