import React, { PureComponent } from "react";
import { Card, CardBody, Col } from "reactstrap";
import { reduxForm } from "redux-form";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import MediaForm from "./MediaGeneralForm";

class CreateMedia extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    props.initialize();
  }

  handleBack = () => {
    const { history } = this.props;
    this.props.destroy();
    this.props.initialize();
    history.push({
      pathname: "/module/medias",
      state: {
        activeTab: "penging_medias",
      },
    });
  };

  render() {
    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <MediaForm onCancel={this.handleBack} isCreateMainMedia={true} />
          </CardBody>
        </Card>
      </Col>
    );
  }
}

CreateMedia = reduxForm({
  form: "medias_form--vertical",
  enableReinitialize: true,
  destroyOnUnmount: false,
  keepDirtyOnReinitialize: true,
})(CreateMedia);

export default withRouter(withTranslation("common")(CreateMedia));
