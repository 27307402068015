import React, { PureComponent } from "react";
import { Modal } from "reactstrap";
import { reduxForm } from "redux-form";
import { withTranslation } from "react-i18next";
import { getUserDetail } from '../../../../../redux/actions/authActions';
import validate from "../validate";
import { connect } from "react-redux";
import SecurityConfirmation from './SecurityLogin';

class EditUserModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isUploading: false
    };
  }

  onCancel = () => {
    this.props.toggleModal();
  };

  render() {
    const { isPopupModal, toggleModal } = this.props;
    return (
      <Modal
        isOpen={isPopupModal}
        style={{ padding: 0 }}
        className="todo__add-modal theme-light ltr-support"
      >
        <SecurityConfirmation
          toggleModal={toggleModal}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {}
}
const mapDispatchToProps = {
  getUserDetail
}

EditUserModal = reduxForm({
  shouldValidate: () => true, // to alway validation form even user cancel the process
  form: "edit_user_main",
  validate,
  enableReinitialize: true,
  destroyOnUnmount: false,
  keepDirtyOnReinitialize: true,
})(EditUserModal);

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("common")(EditUserModal));
