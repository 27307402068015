import React, { PureComponent } from "react";
import {
  Button,
  Row,
  Card,
  CardBody,
  Col,
  CardTitle,
  CardSubtitle,
  CardText,
} from "reactstrap";
import { reduxForm } from "redux-form";
import ButtonModal from "../../DeleteModal/Modal";
import ShowMoreText from "react-show-more-text";
import { withTranslation } from "react-i18next";
import Pagination from "../../../components/CustomPagination/CustomPagination";
import AuthorDetail from "./AuthorDetail";
import { connect } from "react-redux";
import { PlaceholderProfile } from "../../../shared/img";
import _ from "lodash";
import Image, { Shimmer } from "react-shimmer";
import { withRouter } from "react-router";

class CardAuthor extends PureComponent {
  constructor() {
    super();
    this.state = {
      isPopupModal: false,
      isGoToEdit: false,
    };
  }

  onEditAuthor = (author) => {
    const { history, pageOfItems, filterValue } = this.props;
    history.push({
      pathname: `/module/authors/${author.id}/edit`,
      state: {
        pageOfItems: pageOfItems,
        filterValue: filterValue,
        selectedAuthor: author,
      },
    });
  };

  render() {
    const {
      t,
      authors,
      onChangePage,
      itemsPerPage,
      pageOfItems,
      permissions,
      filterValue,
    } = this.props;
    const { isGoToEdit } = this.state;
    return (
      <Col md={12} sm={12} xl={12}>
        <Row className="author-list" md={12} xl={12} sm={12}>
          {!!authors.data &&
            authors.data.map((item) => (
              <Col key={item.id.toString()} md={6} xl={4} sm={12}>
                <Card>
                  <CardBody>
                    <div className="justify-content-center row">
                      <div className="profile__avatar">
                        <Image
                          src={item.s3_photo_url || PlaceholderProfile}
                          alt={item.fullname}
                          fallback={<Shimmer width={800} height={600} />}
                        />
                      </div>
                    </div>
                    <CardTitle className="mb-2">{item.fullname}</CardTitle>
                    <CardSubtitle className="mb-2">
                      {item.fullname_en}
                    </CardSubtitle>
                    <div style={{ minHeight: 62 }}>
                      <CardText>
                        <ShowMoreText
                          lines={3}
                          more="Show more"
                          less="Show less"
                          style={{ color: "red" }}
                          expanded={false}
                        >
                          {item.description}
                        </ShowMoreText>
                      </CardText>
                    </div>
                    <div className="author-list__footer">
                      {permissions.edit && (
                        <Button
                          size="sm"
                          color="primary"
                          onClick={() => this.onEditAuthor(item)}
                        >
                          {t("BUTTON.EDIT")}
                        </Button>
                      )}
                      {permissions.delete && (
                        <ButtonModal
                          isAbleToDelete={item.count_link_to_medias === 0}
                          unableToDeleteMessage={t(
                            "GENERAL.ITEM_CANNOT_DELETE"
                          )}
                          color="danger"
                          size="sm"
                          title={t("GENERAL.DO_YOU_WANT_TO_DELETE_THIS_RECORD")}
                          btn={t("BUTTON.DELETE")}
                          message={item.fullname}
                          actionKey="delete"
                          actionModule="authors"
                          actionId={item.id.toString()}
                          page={pageOfItems}
                          itemsPerPage={itemsPerPage}
                          filterValue={filterValue}
                          itemsCount={authors.data && authors.total}
                        />
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          {_.isEmpty(authors.data) && (
            <Col md={12}>
              <Card>
                <CardBody>
                  <h5 className="text-center p-3">{t("GENERAL.EMPTY_DATA")}</h5>
                </CardBody>
              </Card>
            </Col>
          )}
        </Row>
        {!!authors.data && authors.total > 1 && (
          <div className="author-list">
            <div className="auhtor-list__pagination">
              <Pagination
                itemsCount={authors.data && authors.total}
                itemsToShow={itemsPerPage}
                onChangePage={onChangePage}
                page={pageOfItems}
              />
            </div>
          </div>
        )}
        {isGoToEdit && <AuthorDetail />}
      </Col>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authors: state.fetchData.FETCH_AUTHORS,
  };
};

CardAuthor = reduxForm({
  form: "author_info_form",
})(CardAuthor);

export default connect(mapStateToProps)(
  withRouter(withTranslation("common")(CardAuthor))
);
