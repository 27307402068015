/* eslint-disable */
const validate = (values) => {
  
  const errors = {};

  if (!values.post_name) {
    errors.post_name = 'Name field shouldn’t be empty';
  }
  if (!values.post_name_en) {
    errors.post_name_en = 'Name (English) field shouldn’t be empty';
  }

  if (!values.post_parent_name) {
    errors.post_parent_name = 'Name field shouldn’t be empty';
  }
  if (!values.post_parent_name_en) {
    errors.post_parent_name_en = 'Name (English) field shouldn’t be empty';
  }

  if (!values.name) {
    errors.name = 'Name field shouldn’t be empty';
  }
  if (!values.name_en) {
    errors.name_en = 'Name (English) field shouldn’t be empty';
  }

  return errors;

};

export default validate;
