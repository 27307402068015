import React, { PureComponent } from "react";
import { Button, Col, Spinner, Modal } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import chooseProfileImage from "../../../components/ChooseImage/RoundedChooseImage";
import { withTranslation } from "react-i18next";
import NotificationSystem from "rc-notification";
// import { fetchActionAuthors } from "../../../redux/actions/fetchApiActions";
import { BasicNotification } from "../../../components/Notification";
import {
  RenderInputField,
  RenderTextArea,
} from "../../../components/RenderField";
import validate from "./validate";
import Service from "../../../config/Service";

let notification = null;

class CreateAuthor extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      authorInfo: {},
      isUploading: false,
    };
  }

  componentDidMount() {
    NotificationSystem.newInstance(
      { style: { top: 65 } },
      (n) => (notification = n)
    );
    this.setState({
      authorInfo: {
        parent: 0,
      },
    });
    this.props.initialize();
  }

  showNotification = (rtl) => {
    const { t } = this.props;
    notification.notice({
      content: (
        <BasicNotification
          title={t("GENERAL.NOTIFICATION")}
          color="primary"
          message={t("GENERAL.THE_ACTION_HAS_BEEN_COMPLETED_SUCCESSFULLY")}
        />
      ),
      duration: 5,
      closable: true,
      style: { top: 0, left: "calc(100vw - 100%)" },
      className: `right-up ${rtl}-support`,
    });
  };

  onTextInput = (event) => {
    const fieldName = event.target.name;
    const authorInfo = { ...this.state.authorInfo };
    authorInfo[fieldName] = event.target.value;
    this.setState({ authorInfo });
  };

  onCreateAuthor = () => {
    const { selectedAuthor, refreshAfterCreate } = this.props;
    const { authorInfo } = this.state;

    const formData = new FormData();

    for (const [key, value] of Object.entries(authorInfo)) {
      formData.append(key, value);
    }

    if (authorInfo.s3_photo_url && authorInfo.s3_photo_url.name) {
      formData.append("s3_photo_file", authorInfo.s3_photo_url.file);
    }

    this.setState({ isUploading: true });
    Service.post("authors", formData)
      .then((response) => {
        if (response.data.success) {
          refreshAfterCreate();
          this.setState({ isUploading: false });
          this.props.toggleModal(selectedAuthor);
          this.showNotification();
          this.props.initialize();
        }
      })
      .catch((e) => {
        this.setState({ isUploading: false });
        console.log(e);
      });
  };

  onChooseImage = (event) => {
    const fieldName = "s3_photo_url";
    const authorInfo = { ...this.state.authorInfo };
    authorInfo[fieldName] = event;
    this.setState({ authorInfo });
  };

  onCancel = () => {
    const { selectedAuthor } = this.props;
    this.props.toggleModal(selectedAuthor);
    this.props.initialize();
  };

  render() {
    const { t, handleSubmit, isPopupModal, pristine } = this.props;
    const { isUploading } = this.state;
    return (
      <Modal
        isOpen={isPopupModal}
        style={{ padding: 0 }}
        className="todo__add-modal theme-light ltr-support"
      >
        <div className="create-author">
          <Col md={12} lg={12} xl={12}>
            <div className="create-author_profile-view">
              <Field
                name="s3_photo_url"
                component={chooseProfileImage}
                onChange={this.onChooseImage}
              />
            </div>
            <div className="card__title">
              <h5 className="bold-text">{t("AUTHOR.AUTHOR_INFO_TITLE")}</h5>
              <h5 className="subhead">{t("AUTHOR.AUTHOR_INFO_SUBTITLE")}</h5>
            </div>
            <form
              className="form form--vertical"
              onSubmit={handleSubmit(this.onCreateAuthor)}
            >
              <Col md={6} lg={6} xl={6}>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    {t("INFO_FIELDS.FULLNAME")}
                  </span>
                  <span className="red-text">*</span>
                  <div className="form__form-group-field">
                    <Field
                      name="fullname"
                      component={RenderInputField}
                      type="text"
                      placeholder={t("INFO_FIELDS.FULLNAME")}
                      onChange={this.onTextInput}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    {t("INFO_FIELDS.DESCRIPTION")}
                  </span>
                  <span className="red-text">*</span>
                  <div className="form__form-group-field">
                    <Field
                      name="description"
                      component={RenderTextArea}
                      type="text"
                      placeholder={t("INFO_FIELDS.DESCRIPTION")}
                      onChange={this.onTextInput}
                    />
                  </div>
                </div>
              </Col>
              <Col md={6} lg={6} xl={6}>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    {t("INFO_FIELDS.FULLNAME_EN")}
                  </span>
                  <span className="red-text">*</span>
                  <div className="form__form-group-field">
                    <Field
                      name="fullname_en"
                      component={RenderInputField}
                      type="text"
                      placeholder={t("INFO_FIELDS.FULLNAME_EN")}
                      onChange={this.onTextInput}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    {t("INFO_FIELDS.DESCRIPTION_EN")}
                  </span>
                  <span className="red-text">*</span>
                  <div className="form__form-group-field">
                    <Field
                      name="description_en"
                      component={RenderTextArea}
                      type="text"
                      placeholder={t("INFO_FIELDS.DESCRIPTION_EN")}
                      onChange={this.onTextInput}
                    />
                  </div>
                </div>
              </Col>
              <Col md={12} lg={12} xl={12}>
                <div className="d-flex justify-content-end">
                  <Button
                    color="primary"
                    type="submit"
                    disabled={pristine || isUploading}
                  >
                    {isUploading ? (
                      <Spinner animation="border" variant="primary" size="sm" />
                    ) : (
                      t("BUTTON.CREATE_AUTHOR")
                    )}
                  </Button>
                  <Button
                    type="button"
                    onClick={this.onCancel}
                    disabled={isUploading}
                  >
                    {t("BUTTON.CANCEL")}
                  </Button>
                </div>
              </Col>
            </form>
          </Col>
        </div>
      </Modal>
    );
  }
}

CreateAuthor = reduxForm({
  shouldValidate: () => true, // to alway validation form even user cancel the process
  form: "create_author_form",
  validate,
  enableReinitialize: true,
  destroyOnUnmount: false,
  keepDirtyOnReinitialize: true,
})(CreateAuthor);

export default withTranslation("common")(CreateAuthor);
