/* FETCH DATA */
export const FETCH_COMBO_AUTHORS = 'FETCH_COMBO_AUTHORS';
export const FETCH_MEDIAS = 'FETCH_MEDIAS';
export const FETCH_COMBO_PUBLISHERS = 'FETCH_COMBO_PUBLISHERS';
export const FETCH_COMBO_PUBLISHYEARS = 'FETCH_COMBO_PUBLISHYEARS';
export const FETCH_COMBO_CATEGORIES = 'FETCH_COMBO_CATEGORIES';
export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const FETCH_PUBLISHERS = 'FETCH_PUBLISHERS';
export const FETCH_PUBLISHYEARS = 'FETCH_PUBLISHYEARS';
export const FETCH_AUTHORS = 'FETCH_AUTHORS';
export const FETCH_CATEGORY_TREE = 'FETCH_CATEGORY_TREE';
export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_SELECTED_MEDIA = "FETCH_SELECTED_MEDIA";

/* LOG IN */
export const USER_LOGIN = 'USER_LOGIN';
export const GET_USER_DETAIL = 'GET_USER_DETAIL';