import React from "react";
import { Col } from "reactstrap";
import { Field } from "redux-form";
import chooseProfileImage from "../../../components/ChooseImage/RoundedChooseImage";
import {
  RenderInputField,
  RenderTextArea,
} from "../../../components/RenderField";
import { withTranslation } from "react-i18next";

const AuthorInfo = ({ t, onChooseImage, onTextInput }) => (
  <div className="create-author">
    <Col md={12} lg={12} xl={12}>
      <div className="card__title">
        <h5 className="bold-text">{t("AUTHOR.AUTHOR_INFO_TITLE")}</h5>
        <h5 className="subhead">{t("AUTHOR.AUTHOR_INFO_SUBTITLE")}</h5>
      </div>
      <div className="create-author_profile-view mb-3">
        <Field
          name="s3_photo_url"
          component={chooseProfileImage}
          onChange={onChooseImage}
        />
      </div>
      <form className="form form--vertical">
        <Col md={6} lg={6} xl={6}>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {t("INFO_FIELDS.FULLNAME")}
            </span>
            <span className="red-text">*</span>
            <div className="form__form-group-field">
              <Field
                name="fullname"
                component={RenderInputField}
                type="text"
                placeholder={t("INFO_FIELDS.FULLNAME")}
                onChange={onTextInput}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {t("INFO_FIELDS.DESCRIPTION")}
            </span>
            <span className="red-text">*</span>
            <div className="form__form-group-field">
              <Field
                name="description"
                component={RenderTextArea}
                type="text"
                placeholder={t("INFO_FIELDS.DESCRIPTION")}
                onChange={onTextInput}
              />
            </div>
          </div>
        </Col>
        <Col md={6} lg={6} xl={6}>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {t("INFO_FIELDS.FULLNAME_EN")}
            </span>
            <span className="red-text">*</span>
            <div className="form__form-group-field">
              <Field
                name="fullname_en"
                component={RenderInputField}
                type="text"
                placeholder={t("INFO_FIELDS.FULLNAME_EN")}
                onChange={onTextInput}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {t("INFO_FIELDS.DESCRIPTION_EN")}
            </span>
            <span className="red-text">*</span>
            <div className="form__form-group-field">
              <Field
                name="description_en"
                component={RenderTextArea}
                type="text"
                placeholder={t("INFO_FIELDS.DESCRIPTION_EN")}
                onChange={onTextInput}
              />
            </div>
          </div>
        </Col>
      </form>
    </Col>
  </div>
);

export default withTranslation("common")(AuthorInfo);
