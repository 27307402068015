import {
  FETCH_COMBO_AUTHORS,
  FETCH_MEDIAS,
  FETCH_COMBO_PUBLISHERS,
  FETCH_COMBO_PUBLISHYEARS,
  FETCH_COMBO_CATEGORIES,
  FETCH_CATEGORIES,
  FETCH_PUBLISHERS,
  FETCH_PUBLISHYEARS,
  FETCH_AUTHORS,
  FETCH_CATEGORY_TREE,
  FETCH_USERS,
  FETCH_SELECTED_MEDIA
} from "./actionTypes";

export function fetchActionMedias(data, filters) {
  return {
    type: FETCH_MEDIAS,
    data: data,
    filter: filters
  };
}

export function fetchSelectedMedias(data) {
  return {
    type: FETCH_SELECTED_MEDIA,
    data: data
  };
}

export function fetchActionCategories(data, params) {
  return {
    type: FETCH_CATEGORIES,
    data: data,
    params: params
  };
}

export function fetchActionPublishers(data, params) {
  return {
    type: FETCH_PUBLISHERS,
    data: data,
    params: params
  };
}

export function fetchActionPublishyears(data, params) {
  return {
    type: FETCH_PUBLISHYEARS,
    data: data,
    params: params
  };
}

export function fetchActionAuthors(data, params) {
  return {
    type: FETCH_AUTHORS,
    data: data,
    params: params
  };
}

export function fetchActionComboAuthors(data) {
  return {
    type: FETCH_COMBO_AUTHORS,
    data: data,
  };
}

export function fetchActionComboPublishers(data) {
  return {
    type: FETCH_COMBO_PUBLISHERS,
    data: data,
  };
}

export function fetchActionComboCategories(data) {
  return {
    type: FETCH_COMBO_CATEGORIES,
    data: data,
  };
}

export function fetchActionComboPublishyears(data) {
  return {
    type: FETCH_COMBO_PUBLISHYEARS,
    data: data,
  };
}

export function fetchActionCategoryTree(data) {
  return {
    type: FETCH_CATEGORY_TREE,
    data: data,
  }
}

export function fetchActionUsers(data) {
  return {
    type: FETCH_USERS,
    data: data,
  }
}


