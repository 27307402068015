import {
  USER_LOGIN,
  GET_USER_DETAIL
} from "./actionTypes";

export function actionLogin(data) {
  return {
    type: USER_LOGIN,
    data: data,
  };
}

export function getUserDetail(data) {
  return {
    type: GET_USER_DETAIL,
    data: data
  }
}
