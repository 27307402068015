import React from 'react';
import { PureComponent } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { reduxForm } from "redux-form";
import ProfileMain from './components/ProfileMain';
import ProfileTabs from './components/ProfileTabs';
import EditUserModal from './components/EditUser/EditUserModal';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

class User extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      userDetail: {},
      isPopupModal: false
    }
  }

  toggleModal = () => {
    const { isPopupModal } = this.state;
    this.setState({
      isPopupModal: !isPopupModal
    });
  }

  render() {
    const { userDetail } = this.props;
    const { isPopupModal } = this.state;
    return (
      <Container>
        <div className="profile">
          <Row>
            <Col md={12} lg={12} xl={4}>
              <Row>
                <ProfileMain userInfo={userDetail} handleEditUser={this.toggleModal} />
              </Row>
            </Col>
            <ProfileTabs userInfo={userDetail} />
            <EditUserModal
              isPopupModal={isPopupModal}
              toggleModal={this.toggleModal}
              userData={userDetail}
            />
          </Row>
        </div>
      </Container>
    )
  }
}

User = reduxForm({
  form: "edit_user_main",
  enableReinitialize: true,
  destroyOnUnmount: false,
  keepDirtyOnReinitialize: true,
})(User);

const mapStateToProps = (state) => {
  return {
    userDetail: state.user.detail
  }
}

export default connect(mapStateToProps)(withTranslation("common")(User));
