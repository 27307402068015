import React, { PureComponent } from "react";
import { Spinner, Col, Button, Alert } from "reactstrap";
import { Field, reduxForm, Form } from "redux-form";
import EyeIcon from "mdi-react/EyeIcon";
import KeyVariantIcon from "mdi-react/KeyVariantIcon";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import _ from "lodash";
import Service from "../../../../config/Service";
import NotificationSystem from "rc-notification";
import { BasicNotification } from "../../../../components/Notification";

let notification = null;

class ChangePasswordForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showNewPassword: false,
      showConfirmPassword: false,
      showOldPassword: false,
      isUploading: false,
      error: false,
      errorMessage: "",
      data: {},
    };
    props.initialize();
  }

  componentDidMount() {
    NotificationSystem.newInstance(
      { style: { top: 10 } },
      (n) => (notification = n)
    );
  }

  showSuccessNotification = () => {
    const { t } = this.props;
    notification.notice({
      content: (
        <BasicNotification
          title={t("GENERAL.NOTIFICATION")}
          color="primary"
          message={t("GENERAL.THE_ACTION_HAS_BEEN_COMPLETED_SUCCESSFULLY")}
        />
      ),
      duration: 5,
      closable: true,
      style: { top: 0, left: "calc(100vw - 100%)" },
      className: `right-up right-support`,
    });
  };

  showPassword = (fieldName) => {
    if (fieldName === "confirm_password") {
      this.setState((prevState) => ({
        showConfirmPassword: !prevState.showConfirmPassword,
      }));
    }
    if (fieldName === "new_password") {
      this.setState((prevState) => ({
        showNewPassword: !prevState.showNewPassword,
      }));
    }
    if (fieldName === "old_password") {
      this.setState((prevState) => ({
        showOldPassword: !prevState.showOldPassword,
      }));
    }
  };

  onTextInput = (event) => {
    const fieldName = event.target.name;
    const data = { ...this.state.data };
    data[fieldName] = event.target.value;

    this.setState({ data });
  };

  onCancel = () => {
    const { history } = this.props;
    history.push("/account/profile");
  };

  onChangePassword = (event) => {
    event.preventDefault();
    const { data } = this.state;
    const { t } = this.props;

    if (!data.new_password || !data.confirm_password || !data.old_password) {
      this.setState({
        errorMessage: t("CHANGE_PASSWORD.ALL_FIELDS_ARE_REQURED"),
        error: true,
      });
      return;
    }

    if (
      data.new_password.length < 6 ||
      data.confirm_password.length < 6 ||
      data.old_password.length < 6
    ) {
      this.setState({
        errorMessage: t(
          "CHANGE_PASSWORD.PASSWORD_LENGTH_MUST_BE_MORE_THAN_SIX"
        ),
        error: true,
      });
      return;
    }

    if (!_.isEqual(data.new_password, data.confirm_password)) {
      this.setState({
        errorMessage: t(
          "CHANGE_PASSWORD.NEW_PASSWORD_CONFIRM_PASSWORD_NOT_MATCH"
        ),
        error: true,
      });
      return;
    }

    this.setState({
      errorMessage: "",
      error: false,
    });
    this.confirmChangePassword(data);
  };

  confirmChangePassword = (data) => {
    this.setState({ isUploading: true });
    Service.post("user/changePassword", data)
      .then((response) => {
        if (response.data) {
          this.setState({ isUploading: false });
          if (response.data.message === "Your password has been changed.") {
            this.showSuccessNotification();
          } else {
            this.setState({
              errorMessage: response.data.message,
              error: true,
            });
          }
        }
      })
      .catch((e) => {
        this.setState({ isUploading: false });
      });
  };

  render() {
    const { t } = this.props;
    const {
      showConfirmPassword,
      showNewPassword,
      showOldPassword,
      isUploading,
      error,
      errorMessage,
    } = this.state;

    return (
      <Form className="form login-form" onSubmit={this.onChangePassword}>
        <Alert color="danger" isOpen={error}>
          {errorMessage}
        </Alert>
        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("CHANGE_PASSWORD.CURRENT_PASSWORD")}
            <span className="red-text">*</span>
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <Field
              name="old_password"
              component="input"
              type={showOldPassword ? "text" : "password"}
              placeholder="xxxxxx"
              onChange={this.onTextInput}
            />
            <button
              className={`form__form-group-button${
                showOldPassword ? " active" : ""
              }`}
              type="button"
              onClick={() => {
                this.showPassword("old_password");
              }}
            >
              <EyeIcon />
            </button>
          </div>
        </div>

        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("CHANGE_PASSWORD.NEW_PASSWORD")}
            <span className="red-text">*</span>
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <Field
              name="new_password"
              component="input"
              type={showNewPassword ? "text" : "password"}
              placeholder="xxxxxx"
              onChange={this.onTextInput}
            />
            <button
              className={`form__form-group-button${
                showNewPassword ? " active" : ""
              }`}
              type="button"
              onClick={() => this.showPassword("new_password")}
            >
              <EyeIcon />
            </button>
          </div>
        </div>

        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("CHANGE_PASSWORD.CONFIRM_PASSWORD")}
            <span className="red-text">*</span>
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <Field
              name="confirm_password"
              component="input"
              type={showConfirmPassword ? "text" : "password"}
              placeholder="xxxxxx"
              onChange={this.onTextInput}
            />
            <button
              className={`form__form-group-button${
                showConfirmPassword ? " active" : ""
              }`}
              type="button"
              onClick={() => this.showPassword("confirm_password")}
            >
              <EyeIcon />
            </button>
          </div>
        </div>
        <Col md={12}>
          <div class="row justify-content-md-end">
            <Button color="primary" type="submit" disabled={isUploading}>
              {isUploading ? (
                <Spinner animation="border" variant="primary" size="sm" />
              ) : (
                t("BUTTON.CHANGE_PASSWORD")
              )}
            </Button>
            <Button
              type="button"
              onClick={this.onCancel}
              disabled={isUploading}
            >
              {t("BUTTON.CANCEL")}
            </Button>
          </div>
        </Col>
      </Form>
    );
  }
}

ChangePasswordForm = reduxForm({
  form: "change_password_form",
})(ChangePasswordForm);

export default withRouter(withTranslation("common")(ChangePasswordForm));
