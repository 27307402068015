import React, { PureComponent } from "react";
import DownIcon from "mdi-react/ChevronDownIcon";
import { Collapse } from "reactstrap";
import TopbarMenuLink from "./TopbarMenuLink";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { PlaceholderProfile } from "../../../shared/img";
class TopbarProfile extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      user: {},
    };
  }

  toggle = () => {
    this.setState((prevState) => ({ collapse: !prevState.collapse }));
  };

  logout = () => {
    localStorage.removeItem("main");
    localStorage.removeItem("user");
  };

  render() {
    const { t, userDetail } = this.props;
    const { collapse } = this.state;

    return (
      <div className="topbar__profile">
        <button className="topbar__avatar" type="button" onClick={this.toggle}>
          <img
            className="topbar__avatar-img"
            src={(userDetail && userDetail.s3_photo_url) || PlaceholderProfile}
            alt="avatar"
          />
          <p className="topbar__avatar-name">
            {userDetail && userDetail.fullname}
          </p>
          <DownIcon className="topbar__icon" />
        </button>
        {collapse && (
          <button
            className="topbar__back"
            type="button"
            onClick={this.toggle}
          />
        )}
        <Collapse isOpen={collapse} className="topbar__menu-wrap">
          <div className="topbar__menu">
            <TopbarMenuLink
              title={t("TOPBAR_PROFILE.MY_PROFILE")}
              icon="user"
              path="/account/profile"
              onClick={this.toggle}
            />
            <TopbarMenuLink
              title={t("TOPBAR_PROFILE.CHANGE_PASSWORD")}
              icon="lock"
              path="/account/change-password"
              onClick={this.toggle}
            />
            <TopbarMenuLink
              title={t("TOPBAR_PROFILE.LOG_OUT")}
              icon="exit"
              path="/login"
              onClick={this.logout}
            />
          </div>
        </Collapse>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userDetail: state.user.detail,
  };
};

export default connect(mapStateToProps)(
  withTranslation("common")(TopbarProfile)
);
