import React from "react";
import { Route, Switch } from "react-router-dom";
import Layout from "../../../Layout/index";
import Account from "./Account";

import ModuleMedia from "./Medias";
import ModuleSystemuser from "./SystemUser";
import ModuleAuthor from "./Author";
import ModuleCategory from "../../../Category/index";
import ModulePublisher from "../../../Publisher/index";
import ModulePublishyear from "../../../Publishyear/index";
import ModulePermission from "../../../Permission/index";
import NotFound404 from "../../../DefaultPage/404page";

export default () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Switch>
        <Route path="/account" component={Account} />
        <Route path="/module/medias" component={ModuleMedia} />
        <Route path="/module/authors" component={ModuleAuthor} />
        <Route path="/module/systemuser" component={ModuleSystemuser} />
        <Route path="/module/categories" component={ModuleCategory} exact />
        <Route path="/module/publishers" component={ModulePublisher} exact />
        {/* <Route
          path="/module/publishyears"
          component={ModulePublishyear}
          exact
        /> */}
        <Route path="/module/permission" component={ModulePermission} exact />

        <Route component={NotFound404} />
      </Switch>
    </div>
  </div>
);
