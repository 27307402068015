import React, { PureComponent } from "react";
import { Card, CardBody, Col, Button, Container, Row, Modal } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import PlusIcon from "mdi-react/PlusIcon";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import classNames from "classnames";
import { RenderInputField } from "../../../components/RenderField";
import AddCategory from "./AddCategory";

class AddForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  componentDidMount() {
    this.props.initialize();
  }

  toggleModal = () => {
    this.setState((prevState) => ({ modal: !prevState.modal }));
  };

  onCancel = () => {
    this.toggleModal();
    this.props.destroy();
    this.props.initialize();
  };

  render() {
    const { modal } = this.state;
    const {
      t,
      theme,
      rtl,
      handleSubmit,
      actionSearch,
      refreshAfterCreate,
      permissions,
    } = this.props;
    const modalClasses = classNames(
      {
        "todo__add-modal": true,
      },
      theme.className,
      `${rtl}-support`
    );

    return (
      <Container className="todo-app">
        <Row>
          <Col md={12}>
            <Card>
              <CardBody>
                <form className="form" onSubmit={handleSubmit(actionSearch)}>
                  <Col md={6} sm={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t("GENERAL.NAME")}
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="filter_name"
                          component={RenderInputField}
                          type="text"
                        />
                      </div>
                    </div>
                  </Col>

                  <Col md={6} sm={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t("GENERAL.NAME_EN")}
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="filter_name_en"
                          component={RenderInputField}
                          type="text"
                        />
                      </div>
                    </div>
                  </Col>
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "15px",
                    }}
                  >
                    <Button color="primary" type="submit">
                      {t("GENERAL.FILTER")}
                    </Button>
                    {permissions.create && (
                      <Button
                        className="icon"
                        onClick={this.toggleModal}
                        color="success"
                      >
                        <p>
                          <PlusIcon /> {t("GENERAL.CREATE_A_NEW")}
                        </p>
                      </Button>
                    )}
                  </div>
                </form>
              </CardBody>
            </Card>
          </Col>

          <Modal isOpen={modal} toggle={this.onCancel} className={modalClasses}>
            <AddCategory
              onCancel={this.onCancel}
              refreshAfterCreate={refreshAfterCreate}
            />
          </Modal>
        </Row>
      </Container>
    );
  }
}

AddForm = reduxForm({
  form: "filter_category_form",
  enableReinitialize: true,
  destroyOnUnmount: false,
})(AddForm);

export default withTranslation("common")(AddForm);
