import React, { PureComponent } from "react";
import { Card, CardBody, Col, Button, Modal } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import PlusIcon from "mdi-react/PlusIcon";
import { withTranslation } from "react-i18next";
import renderSelectField from "../../../components/form/Select";
import Utilities from "../../../shared/helpers/Utilities";
import classNames from "classnames";
import AddUser from "./AddUser";
import { FaRegUser } from "react-icons/fa";
import { connect } from "react-redux";
import _ from "lodash";

const iconUserCount = {
  marginRight: "5px",
  background: "#80808026",
  width: "24px",
  height: "24px",
  borderRadius: "12px",
  textAlign: "center",
  color: "#343a40",
};
class Filter extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  componentDidMount() {
    const { filterValue } = this.props;
    if (!_.isEmpty(filterValue)) {
      this.addPrefillInput();
    }
  }

  addPrefillInput = () => {
    const { t } = this.props;
    const { filterValue } = this.props;
    const roleOptions = Utilities.allRolesOptions(t);
    const indexUserRole = roleOptions.findIndex(
      (item) => item.value === filterValue.role_id.value
    );

    const data = {
      role_id: roleOptions[indexUserRole],
      filter_name: filterValue.filter_name,
      filter_email: filterValue.filter_email,
      filter_phone: filterValue.filter_phone,
    };
    this.props.initialize(data);
  };

  toggleModal = () => {
    this.setState((prevState) => ({ modal: !prevState.modal }));
  };

  onCancel = () => {
    this.toggleModal();
    this.props.initialize();
  };

  render() {
    const {
      t,
      handleSubmit,
      theme,
      rtl,
      toggleFilter,
      permissions,
      systemUsers,
      filterValue,
      refreshAfterCreate,
    } = this.props;
    const userLabel =
      filterValue.role_id && filterValue.role_id.label !== "---"
        ? filterValue.role_id.label
        : "អ្នកប្រើប្រាស់";
    const { modal } = this.state;
    const modalClasses = classNames(
      {
        "todo__add-modal": true,
      },
      theme.className,
      `${rtl}-support`
    );
    const roleOptions = Utilities.allRolesOptions(t);

    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <form
              className="form form--vertical"
              onSubmit={handleSubmit(toggleFilter)}
            >
              <Col md={6} sm={12}>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    {t("GENERAL.FULLNAME")}
                  </span>
                  <div className="form__form-group-field">
                    <Field name="filter_name" component="input" type="text" />
                  </div>
                </div>

                <div className="form__form-group">
                  <span className="form__form-group-label">
                    {t("GENERAL.EMAIL")}
                  </span>
                  <div className="form__form-group-field">
                    <Field name="filter_email" component="input" type="text" />
                  </div>
                </div>
              </Col>

              <Col md={6} sm={12}>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    {t("GENERAL.PHONE")}
                  </span>
                  <div className="form__form-group-field">
                    <Field name="filter_phone" component="input" type="text" />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    {t("GENERAL.USER_ROLE")}
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      name="role_id"
                      component={renderSelectField}
                      options={roleOptions}
                    />
                  </div>
                </div>
              </Col>
              <Col md={12}>
                <div className="d-flex justify-content-between">
                  <div className="row p-3">
                    <span style={iconUserCount}>
                      <FaRegUser />
                    </span>
                    <h4 style={{ color: "#646777" }}>
                      {`ចំនួន${userLabel}សរុប:`}
                      <b> {systemUsers.total} </b>
                      នាក់
                    </h4>
                  </div>

                  <div>
                    <Button color="primary" type="submit">
                      {t("GENERAL.FILTER")}
                    </Button>
                    {permissions.create && (
                      <Button
                        className="icon"
                        onClick={this.toggleModal}
                        color="success"
                      >
                        <p>
                          <PlusIcon /> {t("GENERAL.CREATE_A_NEW")}
                        </p>
                      </Button>
                    )}
                  </div>
                </div>
              </Col>
            </form>
          </CardBody>
        </Card>
        <Modal isOpen={modal} toggle={this.onCancel} className={modalClasses}>
          <AddUser
            onCancel={this.onCancel}
            refreshAfterCreate={refreshAfterCreate}
          />
        </Modal>
      </Col>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    systemUsers: state.fetchData.FETCH_USERS,
  };
};

Filter = reduxForm({
  form: "filter_system-user_form",
})(Filter);

export default connect(mapStateToProps)(withTranslation("common")(Filter));
