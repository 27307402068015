/* eslint-disable */
const validate = (values) => {

  const errors = {};

  if (!values.fullname) {
    errors.fullname = 'Fullname field shouldn’t be empty';
  }
  if (!values.fullname_en) {
    errors.fullname_en = 'Fullname (English) field shouldn’t be empty';
  }
  if(values.email) {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }
  }

  return errors;

};

export default validate;
