import React, { PureComponent } from "react";
import { Spinner, Col } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import { withTranslation } from "react-i18next";
import KeyVariantIcon from "mdi-react/KeyVariantIcon";
import { Button, Alert } from "reactstrap";
import NotificationSystem from "rc-notification";
import { BasicNotification } from "../../../components/Notification";
import Service from "../../../config/Service";
import { withRouter } from "react-router";

let notification = null;

class NewPasswordForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      isUploading: false,
      resetPasswordData: {},
    };
  }

  componentDidMount() {
    NotificationSystem.newInstance(
      { style: { top: 10 } },
      (n) => (notification = n)
    );
    this.setState({
      resetPasswordData: this.props.resetPasswordData,
    });
  }

  showNotification = (message) => {
    const { t } = this.props;
    notification.notice({
      content: (
        <BasicNotification
          title={t("NOTIFICATION.SUBMITTED")}
          color="primary"
          message={message}
        />
      ),
      duration: 5,
      closable: true,
      style: { top: 0, left: "calc(100vw - 100%)" },
      className: `right-up right-support`,
    });
  };

  onTextInput = (event) => {
    const fieldName = event.target.name;
    const resetPasswordData = { ...this.state.resetPasswordData };
    resetPasswordData[fieldName] = event.target.value;

    this.setState({ resetPasswordData });
  };

  resetPassword = () => {
    const { t } = this.props;
    const { resetPasswordData } = this.state;
    if (!resetPasswordData.new_password) {
      this.setState({
        errorMessage: t("RESET_PASSWORD.PLAESE_INPUT_NEW_PASSWORD_TO_CONTINUE"),
      });
      return;
    }
    if (resetPasswordData.new_password.length < 6) {
      this.setState({
        errorMessage: t(
          "CHANGE_PASSWORD.PASSWORD_LENGTH_MUST_BE_MORE_THAN_SIX"
        ),
      });
      return;
    }
    this.setState({
      errorMessage: "",
    });
    this.postData(resetPasswordData);
  };

  postData = (resetPasswordData) => {
    const { history } = this.props;
    this.setState({ isUploading: true });
    Service.post("user/reset-password", resetPasswordData)
      .then((response) => {
        if (response.data.message === "Your password has been reseted.") {
          this.setState({ isUploading: false });
          this.showNotification(response.data.message);
          history.push("login");
        } else {
          this.setState({
            isUploading: false,
            errorMessage: response.data.message,
          });
        }
      })
      .catch((error) => {
        this.setState({
          isUploading: false,
          errorMessage: error.message,
        });
      });
  };

  render() {
    const { t } = this.props;
    const { isUploading, errorMessage } = this.state;
    return (
      <Col md={12}>
        <form className="form login-form">
          <Alert color="danger" isOpen={!!errorMessage}>
            {errorMessage}
          </Alert>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {t("RESET_PASSWORD.NEW_PASSWORD")}
              <span className="red-text">*</span>
            </span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <KeyVariantIcon />
              </div>
              <Field
                name="new_password"
                component="input"
                type="text"
                placeholder="xxxxxx"
                className="input-without-border-radius"
                onChange={this.onTextInput}
              />
            </div>
          </div>
          <Button
            className="account__btn"
            color="primary"
            onClick={this.resetPassword}
          >
            {isUploading ? (
              <Spinner size="sm" animation="border" varaint="primary" />
            ) : (
              t("BUTTON.RESET_PASSWORD")
            )}
          </Button>
        </form>
      </Col>
    );
  }
}

export default reduxForm({
  form: "reset_password_form",
})(withRouter(withTranslation("common")(NewPasswordForm)));
