import React, { PureComponent } from "react";
import { Card, CardBody, Col, Button, Container, Row } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import { withTranslation } from "react-i18next";
import NotificationSystem from "rc-notification";
import { BasicNotification } from "../../../components/Notification";
import { fetchActionPublishers } from "../../../redux/actions/fetchApiActions";
import { RenderInputField } from "../../../components/RenderField";
import Service from "../../../config/Service";
import validate from "./validate";

let notification = null;

class DetailForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      name_en: "",
    };
    this.getPublisher();
  }

  componentDidMount() {
    NotificationSystem.newInstance(
      { style: { top: 65 } },
      (n) => (notification = n)
    );
  }

  getPublisher = () => {
    Service.get("publishers", this.props.clickId)
      .then((response) => {
        const data = response.data.data;
        if (response) {
          this.props.initialize({
            name: data.name,
            name_en: data.name_en,
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  showNotification = (rtl) => {
    const { t } = this.props;
    notification.notice({
      content: (
        <BasicNotification
          title={t("GENERAL.NOTIFICATION")}
          color="primary"
          message={t("GENERAL.THE_ACTION_HAS_BEEN_COMPLETED_SUCCESSFULLY")}
        />
      ),
      duration: 5,
      closable: true,
      style: { top: 0, left: "calc(100vw - 100%)" },
      className: `right-up ${rtl}-support`,
    });
  };

  actionSubmit = (value) => {
    const { dispatch, clickId, itemsPerPage, pageOfItems } = this.props;
    const postData = {
      name: value.name,
      name_en: value.name_en,
    };

    const searchParams = `perPage=${itemsPerPage}&page=${pageOfItems}`;
    Service.update("publishers", clickId, postData)
      .then((response) => {
        if (response) {
          this.showNotification();
          Service.getSearch("publishers", searchParams)
            .then((response) => {
              dispatch(fetchActionPublishers(response.data));
              this.toggleGobackTo();
            })
            .catch((e) => {
              console.log(e);
            });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  toggleGobackTo = () => {
    this.props.destroy();
    this.props.initialize();
    this.props.goBackToView();
  };

  render() {
    const { t, handleSubmit, permissions } = this.props;

    return (
      <Container className="todo-app">
        <Row>
          <Col md={12}>
            <h3 className="page-title">{t("GENERAL.DETAIL_INFORMATION")}</h3>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Card>
              <CardBody>
                <form
                  className="form"
                  onSubmit={handleSubmit(this.actionSubmit)}
                >
                  <Col md={6} sm={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t("GENERAL.NAME")}
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="name"
                          component={RenderInputField}
                          type="text"
                          placeholder={t("GENERAL.NAME")}
                        />
                      </div>
                    </div>
                  </Col>

                  <Col md={6} sm={12}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t("GENERAL.NAME_EN")}
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="name_en"
                          component={RenderInputField}
                          type="text"
                          placeholder={t("GENERAL.NAME_EN")}
                        />
                      </div>
                    </div>
                  </Col>
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "15px",
                    }}
                  >
                    {permissions.edit && (
                      <Button color="primary" type="submit">
                        {t("GENERAL.SAVE")}
                      </Button>
                    )}
                    <Button className="icon" onClick={this.toggleGobackTo}>
                      {t("GENERAL.BACK")}
                    </Button>
                  </div>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

DetailForm = reduxForm({
  shouldValidate: () => true,
  form: "detail_publisher_form",
  validate,
  enableReinitialize: true,
  destroyOnUnmount: false,
  keepDirtyOnReinitialize: true,
})(DetailForm);

export default withTranslation("common")(DetailForm);
