import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import {
  sidebarReducer,
  themeReducer,
  customizerReducer,
  rtlReducer,
  authReducer,
  storeReducers,
  fetchApiReducers,
} from '../reducers';

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import thunk from 'redux-thunk';
import logger from 'redux-logger';

const persistConfig = {
  key: 'root',
  storage,
}

const enhancer = compose(
  //applyMiddleware(thunk, promise, logger)
  applyMiddleware(thunk, logger)

  // change to production
  // applyMiddleware (thunk)
);

const reducer = combineReducers({
  form: reduxFormReducer, // mounted under "form",
  theme: themeReducer,
  rtl: rtlReducer,
  sidebar: sidebarReducer,
  customizer: customizerReducer,
  user: authReducer,
  getData: storeReducers,
  fetchData: fetchApiReducers,
});
const persistedReducer = persistReducer(persistConfig, reducer)

export default () => {
  let store = createStore(persistedReducer, enhancer)
  let persistor = persistStore(store)
  return { store, persistor }
}

