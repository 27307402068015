import React, { PureComponent } from "react";
import { Card, CardBody, Col, Button, Input } from "reactstrap";
import PlusIcon from "mdi-react/PlusIcon";
import { withTranslation } from "react-i18next";
import CreateAuthor from "./CreateAuthor";

class Filter extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpenModal: false,
      filterData: {
        fullname: "",
        fullname_en: "",
      },
    };
  }

  componentDidMount() {
    const { filterValue } = this.props;
    this.setState({
      filterData: filterValue,
    });
  }

  toggleModal = () => {
    const { isPopupModal } = this.state;
    this.setState({
      isPopupModal: !isPopupModal,
    });
  };

  onTextChange = (event) => {
    const fieldName = event.target.name;
    const value = event.target.value;
    let filterData = { ...this.state.filterData };
    filterData[fieldName] = value;
    this.setState({ filterData });
  };

  render() {
    const { t, toggleFilter, permissions, refreshAfterCreate } = this.props;
    const { isPopupModal, filterData } = this.state;
    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <form className="form form--vertical">
              <Col md={6} sm={12}>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    {t("GENERAL.NAME")}
                  </span>
                  <div className="form__form-group-field">
                    <Input
                      name="fullname"
                      type="text"
                      onChange={this.onTextChange}
                      value={filterData.fullname || ""}
                    />
                  </div>
                </div>
              </Col>

              <Col md={6} sm={12}>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    {t("GENERAL.NAME_EN")}
                  </span>
                  <div className="form__form-group-field">
                    <Input
                      name="fullname_en"
                      type="text"
                      onChange={this.onTextChange}
                      value={filterData.fullname_en || ""}
                    />
                  </div>
                </div>
              </Col>
              <div className="author-list">
                <div className="author-list__filter">
                  <Button
                    color="primary"
                    onClick={() => toggleFilter(filterData)}
                  >
                    {t("GENERAL.FILTER")}
                  </Button>
                  {permissions.create && (
                    <Button
                      className="icon"
                      onClick={this.toggleModal}
                      color="success"
                    >
                      <p>
                        <PlusIcon />
                        {t("GENERAL.CREATE_A_NEW")}
                      </p>
                    </Button>
                  )}
                </div>
              </div>
            </form>
            <CreateAuthor
              toggleModal={this.toggleModal}
              isPopupModal={isPopupModal}
              isEdit={false}
              selectedAuthor={null}
              refreshAfterCreate={refreshAfterCreate}
            />
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default withTranslation("common")(Filter);
