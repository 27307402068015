import React, { PureComponent } from "react";
import {
  Card,
  CardBody,
  Col,
  Button,
  Container,
  Row,
  Spinner,
  Table,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { Field, reduxForm } from "redux-form";
import renderCheckBoxField from "../../../components/form/CheckBox";
import { RenderInputField } from "../../../components/RenderField";
import { withTranslation } from "react-i18next";
import NotificationSystem from "rc-notification";
import { BasicNotification } from "../../../components/Notification";
import { fetchActionUsers } from "../../../redux/actions/fetchApiActions";
import Service from "../../../config/Service";
import validate from "./validateEdit";
import classnames from "classnames";
import _ from "lodash";
import { connect } from "react-redux";
import LoadingView from "../../../components/LoadingView/LoadingView";
import Modal from "../../DeleteModal/Modal";
import Utilities from "../../../shared/helpers/Utilities";
import { PlaceholderProfile } from "../../../shared/img";
import ChangeUserPassword from "./ChangeUserPassword";

let notification = null;
const isTrue = 1;
const isFalse = 0;

class DetailForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "user_info",
      isUploading: false,
      isUploadingInfo: false,
      itemsPerPage: 12,
      selectedUser: {},
      currentRowKey: "",
      allModule: [],
      permissions: null,
      showNewPassword: false,
      showConfirmPassword: false,
      isUploadingChangePassword: false,
      error: false,
      errorMessage: "",
      passwordData: {},
    };
  }

  componentDidMount() {
    const permissionData = Utilities.getPermission(
      this.props.user,
      "systemusers"
    );
    console.log("permissionData", permissionData);
    this.setState({
      permissions: permissionData,
    });
    NotificationSystem.newInstance(
      { style: { top: 65 } },
      (n) => (notification = n)
    );
    this.getUserDetail();
  }

  getUserDetail = () => {
    const { params } = this.props.match;
    Service.get("users", params.userId).then((response) => {
      if (response.status === 200) {
        const selectedUser = response.data.data;
        this.props.initialize({
          fullname: selectedUser.fullname,
          fullname_en: selectedUser.fullname_en,
          phone: selectedUser.phone,
          email: selectedUser.email,
          s3_photo_url: selectedUser.s3_photo_url,
        });
        this.setState(
          {
            selectedUser,
            passwordData: {
              user_id: selectedUser.id,
            },
          },
          () => {
            if (
              selectedUser.permissions !== null &&
              selectedUser.action_controls
            ) {
              const allModule = selectedUser.action_controls;
              const newModule = allModule.map((item, index) => {
                item.control_modules.map((action, subIndex) => {
                  if (action.action_type === "VIEW") {
                    action.disabled = false;
                  } else {
                    action.disabled =
                      item.control_modules[0].action_type === "VIEW" &&
                      !item.control_modules[0].is_checked;
                  }
                  return action;
                });
                return item;
              });
              this.setState({ allModule: newModule });
            }
          }
        );
      }
    });
  };

  showNotification = (rtl) => {
    const { t } = this.props;
    notification.notice({
      content: (
        <BasicNotification
          title={t("GENERAL.NOTIFICATION")}
          color="primary"
          message={t("GENERAL.THE_ACTION_HAS_BEEN_COMPLETED_SUCCESSFULLY")}
        />
      ),
      duration: 5,
      closable: true,
      style: { top: 0, left: "calc(100vw - 100%)" },
      className: `right-up ${rtl}-support`,
    });
  };

  showPassword = (fieldName) => {
    if (fieldName === "confirm_password") {
      this.setState((prevState) => ({
        showConfirmPassword: !prevState.showConfirmPassword,
      }));
    }
    if (fieldName === "new_password") {
      this.setState((prevState) => ({
        showNewPassword: !prevState.showNewPassword,
      }));
    }
  };

  onTextInput = (event) => {
    const fieldName = event.target.name;
    const passwordData = { ...this.state.passwordData };
    passwordData[fieldName] = event.target.value;

    this.setState({ passwordData });
  };

  actionSubmit = (value) => {
    const { selectedUser } = this.state;
    const data = {
      fullname: value.fullname,
      fullname_en: value.fullname_en,
      email: value.email,
      phone: value.phone,
    };
    this.updateUserInfo(selectedUser.id, data);
  };

  updateUserInfo = (id, data) => {
    const { itemsPerPage } = this.state;
    const { dispatch } = this.props;
    const searchParams = `parent=0&perPage=${itemsPerPage}`;

    this.setState({ isUploadingInfo: true });
    Service.update("users", id, data)
      .then((response) => {
        if (response.data.success) {
          Service.getSearch("users", searchParams)
            .then((response) => {
              dispatch(fetchActionUsers(response.data));
              this.showNotification();
              this.setState({ isUploadingInfo: false });
            })
            .catch((e) => {
              console.log(e);
            });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  onChangePassword = (event) => {
    event.preventDefault();
    const { passwordData } = this.state;
    const { t } = this.props;

    if (!passwordData.new_password || !passwordData.confirm_password) {
      this.setState({
        errorMessage: t("CHANGE_PASSWORD.ALL_FIELDS_ARE_REQURED"),
        error: true,
      });
      return;
    }

    if (
      passwordData.new_password.length < 6 ||
      passwordData.confirm_password.length < 6
    ) {
      this.setState({
        errorMessage: t(
          "CHANGE_PASSWORD.PASSWORD_LENGTH_MUST_BE_MORE_THAN_SIX"
        ),
        error: true,
      });
      return;
    }

    if (!_.isEqual(passwordData.new_password, passwordData.confirm_password)) {
      this.setState({
        errorMessage: t(
          "CHANGE_PASSWORD.NEW_PASSWORD_CONFIRM_PASSWORD_NOT_MATCH"
        ),
        error: true,
      });
      return;
    }

    this.setState({
      errorMessage: "",
      error: false,
    });
    this.confirmChangePassword(passwordData);
  };

  confirmChangePassword = (data) => {
    this.setState({ isUploadingChangePassword: true });
    Service.post("user/superAdminChangePassword", data)
      .then((response) => {
        if (response.data) {
          this.setState({ isUploadingChangePassword: false });
          if (response.data.message === "Your password has been changed.") {
            this.showNotification();
            this.props.initialize();
          } else {
            this.setState({
              errorMessage: response.data.message,
              error: true,
            });
          }
        }
      })
      .catch((error) => {
        this.setState({ isUploadingChangePassword: false });
        console.log(error);
      });
  };
  toggleGobackTo = () => {
    const { history } = this.props;
    const {
      location: { state },
    } = history;
    this.props.destroy();
    this.props.initialize();
    history.push({
      pathname: "/module/systemuser",
      state: {
        pageOfItems: state && state.pageOfItems,
        filterValue: state && state.filterValue,
      },
    });
  };

  onCheckbox = (e, itemMain, indexMain, actionItem, actionIndex) => {
    if (e.currentTarget) {
      const { allModule } = this.state;
      const newModule = allModule.map((item, index) => {
        if (index === indexMain) {
          item.control_modules.map((action, subIndex) => {
            if (subIndex === actionIndex) {
              action.is_checked = !action.is_checked;
            } else {
              action.is_checked =
                actionItem.action_type === "VIEW" && !actionItem.is_checked
                  ? actionItem.is_checked
                  : action.is_checked;
              action.disabled =
                actionItem.action_type === "VIEW" && !actionItem.is_checked;
            }
            return action;
          });
        }
        return item;
      });
      this.setState({
        allModule: newModule,
        currentRowKey: itemMain.module_key,
      });
    }
  };

  onSaveCheckAction = (item) => {
    const { selectedUser } = this.state;
    let data = {};
    item.control_modules.map((action) => {
      data.module_key = item.module_key;
      data[`action_${action.action_type.toLowerCase()}`] = action.is_checked
        ? isTrue
        : isFalse;
      data.user_id = selectedUser.id;
      return action;
    });
    this.setState({ currentRowKey: item.module_key, isUploading: true });
    Service.post("user/setUserModuleControl", data)
      .then((response) => {
        if (response) {
          this.setState({ isUploading: false });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  toggle = (tab) => {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  render() {
    const {
      t,
      handleSubmit,
      user: { data: user },
    } = this.props;
    const {
      isUploading,
      selectedUser,
      allModule,
      currentRowKey,
      isUploadingInfo,
      activeTab,
      permissions,
      showConfirmPassword,
      showNewPassword,
      isUploadingChangePassword,
      error,
      errorMessage,
    } = this.state;

    return (
      <Container className="todo-app">
        <Row>
          <Col md={12}>
            <h3 className="page-title">{t("GENERAL.DETAIL_INFORMATION")}</h3>
          </Col>
        </Row>
        {_.isEmpty(selectedUser) && <LoadingView />}
        {!_.isEmpty(selectedUser) && (
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  {user.role_id === 2 &&
                    selectedUser.role_id === 1 &&
                    !selectedUser.is_approve && (
                      <div style={{ position: "absolute", right: "20px" }}>
                        <Modal
                          color="primary"
                          size="sm"
                          title={t(
                            "GENERAL.WOULD_YOU_LIKE_APPROVE_THIS_USER_USE_THIS_SYSTEM"
                          )}
                          btn={t("BUTTON.APPROVE")}
                          message={selectedUser.name}
                          actionKey="approve"
                          actionModule="users"
                          data={{
                            user_id: selectedUser.id,
                            is_approve: 1,
                          }}
                          callbackFitler=""
                        />
                      </div>
                    )}
                  <div className="tabs tabs--bordered-bottom">
                    <div className="tabs__wrap">
                      <Nav tabs>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "user_info",
                            })}
                            onClick={() => {
                              this.toggle("user_info");
                            }}
                          >
                            {t("SYSTEM_USER.USER_INFO")}
                          </NavLink>
                        </NavItem>
                        {selectedUser.role_id !== 3 && ( // user role is not author
                          <NavItem>
                            <NavLink
                              className={classnames({
                                active: activeTab === "user_permission",
                              })}
                              onClick={() => {
                                this.toggle("user_permission");
                              }}
                            >
                              {t("SYSTEM_USER.PERMISSION_ACTION")}
                            </NavLink>
                          </NavItem>
                        )}
                        {selectedUser.role_id !== 3 && (
                          <NavItem>
                            <NavLink
                              className={classnames({
                                active: activeTab === "change_password",
                              })}
                              onClick={() => {
                                this.toggle("change_password");
                              }}
                            >
                              {t("SYSTEM_USER.CHANGE_PASSWORD")}
                            </NavLink>
                          </NavItem>
                        )}
                      </Nav>
                      <TabContent activeTab={activeTab}>
                        <TabPane tabId="user_info">
                          <div className="card__title pl-3 pr-3">
                            <h5 className="bold-text">
                              {t("SYSTEM_USER.USER_INFO")}
                            </h5>
                            <h5 className="subhead">
                              {t("SYSTEM_USER.PLEASE_INPUT_FIELD_TO_USER_INFO")}
                            </h5>
                          </div>
                          <form
                            className="form horizontal_form"
                            onSubmit={handleSubmit(this.actionSubmit)}
                          >
                            <Col md={12} sm={12}>
                              <div className="d-flex justify-content-center mb-3">
                                <img
                                  src={
                                    selectedUser.s3_photo_url ||
                                    PlaceholderProfile
                                  }
                                  alt="user_profile"
                                  style={{
                                    width: 160,
                                    height: 160,
                                    borderRadius: 80,
                                    border: "5px solid snow",
                                    objectFit: "cover",
                                  }}
                                />
                              </div>
                            </Col>
                            <Col md={6} sm={12}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">
                                  {t("GENERAL.FULLNAME")}
                                </span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="fullname"
                                    component={RenderInputField}
                                    type="text"
                                    placeholder={t("GENERAL.FULLNAME")}
                                  />
                                </div>
                              </div>
                              <div className="form__form-group">
                                <span className="form__form-group-label">
                                  {t("GENERAL.EMAIL")}
                                </span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="email"
                                    component={RenderInputField}
                                    type="text"
                                    placeholder={t("GENERAL.EMAIL")}
                                  />
                                </div>
                              </div>
                            </Col>

                            <Col md={6} sm={12}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">
                                  {t("GENERAL.FULLNAME_EN")}
                                </span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="fullname_en"
                                    component={RenderInputField}
                                    type="text"
                                    placeholder={t("GENERAL.FULLNAME_EN")}
                                  />
                                </div>
                              </div>
                              <div className="form__form-group">
                                <span className="form__form-group-label">
                                  {t("GENERAL.PHONE")}
                                </span>
                                <div className="form__form-group-field">
                                  <Field
                                    name="phone"
                                    component={RenderInputField}
                                    type="text"
                                    placeholder={t("GENERAL.PHONE")}
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col md={12}>
                              <div className="d-flex justify-content-end">
                                {permissions.edit && (
                                  <Button
                                    color="primary"
                                    type="submit"
                                    disabled={isUploadingInfo}
                                  >
                                    {isUploadingInfo ? (
                                      <Spinner
                                        animation="border"
                                        variant="primary"
                                        size="sm"
                                      />
                                    ) : (
                                      t("BUTTON.EDIT")
                                    )}
                                  </Button>
                                )}
                                <Button
                                  className="icon"
                                  onClick={this.toggleGobackTo}
                                  disabled={isUploadingInfo}
                                >
                                  {t("BUTTON.BACK")}
                                </Button>
                              </div>
                            </Col>
                          </form>
                        </TabPane>
                        {selectedUser.role_id !== 3 && ( // user role is not author
                          <TabPane tabId="user_permission">
                            <Col md={12} sm={12}>
                              <div className="form__form-group">
                                <Table className="table--bordered">
                                  <thead>
                                    <tr>
                                      <th>Module Name</th>
                                      <th className="text-center">Actions</th>
                                      <th>Save</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {allModule.map((item, indexMain) => (
                                      <tr key={indexMain.toString()}>
                                        <td>
                                          {t(`SIDE_BAR.${item.module_name}`)}
                                        </td>
                                        <td>
                                          <div className="row form__form-group-field">
                                            {item.control_modules.map(
                                              (action, index) => (
                                                <div
                                                  className="p-2"
                                                  key={index.toString()}
                                                >
                                                  <Field
                                                    name={`${item.module_name}_action_${action.action_type}`}
                                                    component={
                                                      renderCheckBoxField
                                                    }
                                                    onChange={(e) =>
                                                      this.onCheckbox(
                                                        e,
                                                        item,
                                                        indexMain,
                                                        action,
                                                        index
                                                      )
                                                    }
                                                    defaultChecked={
                                                      action.is_checked
                                                    }
                                                    disabled={action.disabled}
                                                    label={t(
                                                      `ACTION_CONTROL.${action.action_type}`
                                                    )}
                                                    checked={action.is_checked}
                                                  />
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </td>

                                        <td>
                                          {permissions.edit && (
                                            <Button
                                              color="primary"
                                              outline
                                              size="sm"
                                              disabled={isUploading}
                                              style={{ padding: "3px 8px" }}
                                              onClick={() =>
                                                this.onSaveCheckAction(item)
                                              }
                                            >
                                              {isUploading &&
                                              currentRowKey ==
                                                item.module_key ? (
                                                <Spinner size="sm" />
                                              ) : (
                                                t("BUTTON.EDIT")
                                              )}
                                            </Button>
                                          )}
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                              </div>
                            </Col>
                            <Col md={12}>
                              <div className="d-flex justify-content-end">
                                <Button
                                  className="icon"
                                  onClick={this.toggleGobackTo}
                                  disabled={isUploadingInfo}
                                >
                                  {t("GENERAL.BACK")}
                                </Button>
                              </div>
                            </Col>
                          </TabPane>
                        )}
                        <TabPane tabId="change_password">
                          <ChangeUserPassword
                            onChangePassword={this.onChangePassword}
                            permissions={permissions}
                            error={error}
                            errorMessage={errorMessage}
                            onTextInput={this.onTextInput}
                            showPassword={this.showPassword}
                            showNewPassword={showNewPassword}
                            showConfirmPassword={showConfirmPassword}
                            toggleGobackTo={this.toggleGobackTo}
                            isUploadingChangePassword={
                              isUploadingChangePassword
                            }
                          />
                        </TabPane>
                      </TabContent>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
    );
  }
}

DetailForm = reduxForm({
  shouldValidate: () => true,
  form: "detail_system-user_form",
  validate,
  enableReinitialize: true,
  destroyOnUnmount: false,
  keepDirtyOnReinitialize: true,
})(DetailForm);

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(withTranslation("common")(DetailForm));
