import React, { PureComponent } from "react";
import { Button, ButtonToolbar, Card, CardBody, Col, Table } from "reactstrap";
import { connect } from "react-redux";
import Modal from "../../DeleteModal/Modal";
import Pagination from "../../../components/CustomPagination/CustomPagination";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import _ from "lodash";

class TableCollection extends PureComponent {
  constructor() {
    super();
    this.state = {};
  }

  toggleAddView = (obj) => {
    this.props.addView(obj);
  };

  toggleDetailView = (item) => {
    const { history, pageOfItems, filterValue } = this.props;
    history.push({
      pathname: `/module/systemuser/${item.id}/edit`,
      state: {
        pageOfItems: pageOfItems,
        filterValue: filterValue,
      },
    });
  };

  displayUserRole = (v) => {
    const { t } = this.props;
    switch (v) {
      case 1:
        return t("GENERAL.ADMIN");
      case 2:
        return t("GENERAL.SUPERADMIN");
      case 3:
        return t("GENERAL.AUTHOR");
      default:
        return t("GENERAL.USER");
    }
  };

  actionButtons = (item) => {
    const {
      t,
      user,
      systemUsers,
      permissions,
      pageOfItems,
      filterValue,
      itemsPerPage,
    } = this.props;
    switch (item.role_id) {
      case 1:
      case 2:
      case 3:
        return (
          <div className="d-flex justify-content-end">
            {user.role_id === 2 &&
              item.role_id !== 3 &&
              !item.is_approve &&
              permissions.approve && (
                <div style={{ marginRight: 15 }}>
                  {
                    <Modal
                      color="primary"
                      size="sm"
                      title={t(
                        "GENERAL.WOULD_YOU_LIKE_APPROVE_THIS_USER_USE_THIS_SYSTEM"
                      )}
                      btn={t("BUTTON.APPROVE")}
                      message={item.name}
                      actionKey="approve"
                      actionModule="users"
                      data={{
                        user_id: item.id,
                        is_approve: 1,
                      }}
                      page={pageOfItems}
                    />
                  }
                </div>
              )}
            {user.role_id === 2 && item.role_id !== 3 && permissions.approve && (
              <div style={{ marginRight: 15 }}>
                {
                  <Modal
                    color="primary"
                    btnOutline
                    size="sm"
                    title={t(
                      item.is_suspend
                        ? "GENERAL.WOULD_YOU_LIKE_UNSUSPEND_THIS_USER_USING_THIS_SYSTEM"
                        : "GENERAL.WOULD_YOU_LIKE_SUSPEND_THIS_USER_USING_THIS_SYSTEM"
                    )}
                    btn={t(
                      item.is_suspend ? "BUTTON.UNSUSPEND" : "BUTTON.SUSPEND"
                    )}
                    message={item.name}
                    actionKey="approve"
                    actionModule="suspend_unsuspend_user"
                    data={{
                      user_id: item.id,
                      is_suspend: item.is_suspend ? 0 : 1,
                    }}
                    page={pageOfItems}
                  />
                }
              </div>
            )}
            {permissions.view && (
              <Button
                onClick={() => this.toggleDetailView(item)}
                color="primary"
                size="sm"
              >
                {t("BUTTON.DETAIL")}
              </Button>
            )}
            {permissions.delete && (
              <Modal
                isAbleToDelete={
                  (item.role_id !== 2 && _.isEmpty(item.link_to_many_medias)) ||
                  (item.role_id == 3 && _.isEmpty(item.link_to_many_medias))
                }
                unableToDeleteMessage={t("GENERAL.ITEM_CANNOT_DELETE")}
                color="danger"
                size="sm"
                title={t("GENERAL.DO_YOU_WANT_TO_DELETE_THIS_RECORD")}
                btn={t("BUTTON.DELETE")}
                message={item.name}
                actionKey="delete"
                actionModule="users"
                actionId={item.id}
                page={pageOfItems}
                filterValue={filterValue}
                itemsPerPage={itemsPerPage}
                itemsCount={systemUsers.data && systemUsers.total}
              />
            )}
          </div>
        );
      default:
        return (
          <div>
            <ButtonToolbar className="invoice__toolbar">
              {permissions.view && (
                <Button disabled size="sm">
                  {t("GENERAL.DETAIL")}
                </Button>
              )}
              {permissions.delete && (
                <Button disabled size="sm">
                  {t("BUTTON.DELETE")}
                </Button>
              )}
            </ButtonToolbar>
          </div>
        );
    }
  };

  render() {
    const {
      t,
      systemUsers,
      onChangePage,
      pageOfItems,
      itemsPerPage,
    } = this.props;

    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <Table className="table--bordered">
              <thead>
                <tr>
                  <th>{t("GENERAL.NUMBER")}</th>
                  <th>{t("GENERAL.NAME")}</th>
                  <th>{t("GENERAL.USER_ROLE")}</th>
                  <th className="text-center">{t("GENERAL.ACTION")}</th>
                </tr>
              </thead>
              <tbody>
                {!!systemUsers.data &&
                  !_.isEmpty(systemUsers.data) &&
                  systemUsers.data.map((item, index) => (
                    <tr key={index.toString()}>
                      <td>{index + 1 + (pageOfItems - 1) * itemsPerPage}</td>
                      <td>
                        <p className="project-member__name">
                          {item.fullname || item.name}
                        </p>
                        <p className="project-member__name">{item.email}</p>
                      </td>
                      <td>{this.displayUserRole(item.role_id)}</td>
                      <td>{this.actionButtons(item)}</td>
                    </tr>
                  ))}
                {_.isEmpty(systemUsers.data) && (
                  <tr>
                    <td
                      style={{ textAlign: "center", paddingTop: "50px" }}
                      colSpan="4"
                    >
                      <h5>{t("GENERAL.EMPTY_DATA")}</h5>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Pagination
                itemsCount={systemUsers.data && systemUsers.total}
                itemsToShow={itemsPerPage}
                page={pageOfItems}
                onChangePage={onChangePage}
              />
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.theme,
    rtl: state.rtl,
    systemUsers: state.fetchData.FETCH_USERS,
    user: state.user.data,
  };
};
export default connect(mapStateToProps)(
  withRouter(withTranslation("common")(TableCollection))
);
