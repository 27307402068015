import React, { PureComponent } from 'react';
import { Col, Alert, Button, Spinner, CardTitle } from 'reactstrap';
import { Field, reduxForm, Form } from 'redux-form';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
import Image, { Shimmer } from 'react-shimmer';
import { PlaceholderProfile } from "../../../../../shared/img";
import Service from '../../../../../config/Service';
import UserDetail from './UserDetail';

class ChangePasswordForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      isUploading: false,
      error: false,
      errorMessage: "",
      userData: {},
      showUserInfo: false
    };
    props.initialize();
  }

  showPassword = () => {
    this.setState(prevState => ({
      showPassword: !prevState.showPassword,
    }));
  }

  onTextInput = (event) => {
    const fieldName = event.target.name;
    const userData = { ...this.state.userData };
    userData[fieldName] = event.target.value;

    this.setState({ userData });
  }

  onCancel = () => {
    this.props.toggleModal();
  }

  handleCheckCredential = () => {
    const { userData } = this.state;
    const { t } = this.props;

    if (!userData.phone || !userData.password) {
      this.setState({
        errorMessage: t("LOGIN.ALL_FIELDS_ARE_REQURED"),
        error: true
      });
      return
    }

    this.setState({
      errorMessage: "",
      error: false
    });
    this.postUser(userData);
  }

  postUser = (userData) => {
    this.setState({ isUploading: true });
    Service.post("login", userData)
      .then((response) => {
        this.setState({ isUploading: false });
        if (!response.data.success) {
          this.setState({
            errorMessage: response.data.message,
            error: true
          });
        } else {
          if (response.data.data) {
            this.setState({ showUserInfo: true, isUploading: false })
          }
        }
      })
      .catch((e) => {
        this.setState({ isUploading: false });
        console.log(e);
      });
  }

  render() {
    const { user, t, toggleModal } = this.props;
    const { showPassword, isUploading, error, errorMessage, showUserInfo } = this.state;

    return (
      <Col md={12} lg={12}>
        {!showUserInfo && (
          <>
            <div className="text-center p-3">
              <div className="justify-content-md-center row">
                <div className="account__avatar">
                  <Image
                    src={user.s3_photo_url || PlaceholderProfile} alt="avatar"
                    style={{ objectFit: "cover" }}
                    fallback={<Shimmer width={300} height={300} />}
                  />
                </div>
              </div>
              <CardTitle style={{ fontSize: 16 }} className="mb-1 mt-1">{user.fullname}</CardTitle>
              <CardTitle>{t("USER_PROFILE.FOR_SECURITY_PLEASE_LOG_IN")}</CardTitle>

              <div class="p-3">
                <Form className="form login-form">
                  <Alert color="danger" isOpen={error}>
                    {errorMessage}
                  </Alert>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t("LOGIN.PHONE")}
                      <span className="red-text">*</span>
                    </span>
                    <div className="form__form-group-field">
                      <div className="form__form-group-icon">
                        <AccountOutlineIcon />
                      </div>
                      <Field
                        name="phone"
                        component="input"
                        type="number"
                        placeholder={t("LOGIN.PHONE")}
                        onChange={this.onTextInput}
                      />
                    </div>
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {t("LOGIN.PASSWORD")}
                      <span className="red-text">*</span>
                    </span>
                    <div className="form__form-group-field">
                      <div className="form__form-group-icon">
                        <KeyVariantIcon />
                      </div>
                      <Field
                        name="password"
                        component="input"
                        type={showPassword ? 'text' : 'password'}
                        placeholder="xxxxxx"
                        onChange={this.onTextInput}
                      />
                      <button
                        className={`form__form-group-button${showPassword ? ' active' : ''}`}
                        type="button"
                        onClick={this.showPassword}
                      ><EyeIcon />
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <Button
                type="button"
                onClick={this.onCancel}
                disabled={isUploading}
              >
                {t("BUTTON.CANCEL")}
              </Button>
              <Button
                color="primary"
                disabled={isUploading}
                onClick={this.handleCheckCredential}
              >
                {isUploading ? (
                  <Spinner animation="border" variant="primary" size="sm" />
                ) : (
                    t("BUTTON.NEXT")
                  )}
              </Button>
            </div>
          </>
        )}
        {showUserInfo && (
          <UserDetail
            toggleModal={toggleModal}
          />
        )}
      </Col>
    );
  }
}

ChangePasswordForm = reduxForm({
  form: 'security_login_form',
})(ChangePasswordForm);

const mapStateToProps = (state) => {
  return {
    user: state.user.detail
  }
}

export default connect(mapStateToProps)(withRouter(withTranslation("common")(ChangePasswordForm)));
