export const TYPE_GET_CATEGORY = 'TYPE_GET_CATEGORY';
export const TYPE_SET_ACTION_HISTORY = 'TYPE_SET_ACTION_HISTORY';
export const TYPE_COMPONENT_VIEW = 'TYPE_COMPONENT_VIEW';

export function getActionCategory(data) {
  return {
    type: TYPE_GET_CATEGORY,
    CATEGORY_DATA: data,
  };
}

export function setActionHistory(data) {
  return {
    type: TYPE_SET_ACTION_HISTORY,
    HISTORY_DATA: data,
  };
}

export function setTypeComponentView(value) {
  return {
    type: TYPE_COMPONENT_VIEW,
    value: value,
  };
}
