import React from "react";
import { Route, Switch } from "react-router-dom";
import Profile from "../../../Account/Profile/index";
import ChangePassword from "../../../Account/ChangePassword/index";
import NotFound404 from '../../../DefaultPage/404page';

export default () => (
  <Switch>
    <Route path="/account/profile" component={Profile} exact />
    <Route path="/account/change-password" component={ChangePassword} exact />
    <Route component={NotFound404} />
  </Switch>
);
