import React, { PureComponent } from "react";
import { Spinner, Col } from "reactstrap";
import { Field, reduxForm, reset } from "redux-form";
import { withTranslation } from "react-i18next";
import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
import AlternateEmailIcon from "mdi-react/AlternateEmailIcon";
import PhoneIcon from "mdi-react/PhoneIcon";
import { Button, Alert } from "reactstrap";
import { NavLink } from "react-router-dom";
import KeyVariantIcon from "mdi-react/KeyVariantIcon";
import EyeIcon from "mdi-react/EyeIcon";

class RegisterForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      showConfirmPassword: false,
    };
    this.clearReduxForm();
  }

  clearReduxForm = () => {
    const { dispatch } = this.props;
    dispatch(reset("register_form"));
  };

  showPasswordToggle = (fieldName) => {
    if (fieldName === "password") {
      this.setState((prevState) => ({
        showPassword: !prevState.showPassword,
      }));
    } else {
      this.setState((prevState) => ({
        showConfirmPassword: !prevState.showConfirmPassword,
      }));
    }
  };
  render() {
    const {
      onSendPhoneCode,
      onTextChange,
      errorMessage,
      t,
      isUploading,
    } = this.props;
    const { showPassword, showConfirmPassword } = this.state;

    return (
      <form className="form login-form">
        <Alert color="danger" isOpen={!!errorMessage}>
          {errorMessage}
        </Alert>
        <Col md={12} sm={12}>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {t("GENERAL.FULLNAME")}
              <span className="red-text">*</span>
            </span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <AccountOutlineIcon />
              </div>
              <Field
                name="fullname"
                component="input"
                type="text"
                placeholder={t("GENERAL.FULLNAME")}
                className="input-without-border-radius"
                onChange={onTextChange}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {t("GENERAL.FULLNAME_EN")}
              <span className="red-text">*</span>
            </span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <AccountOutlineIcon />
              </div>
              <Field
                name="fullname_en"
                component="input"
                type="text"
                placeholder={t("GENERAL.FULLNAME_EN")}
                className="input-without-border-radius"
                onChange={onTextChange}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {t("GENERAL.PHONE")}
              <span className="red-text">*</span>
            </span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <PhoneIcon />
              </div>
              <Field
                name="phone"
                component="input"
                type="number"
                placeholder="XXX XXX XXX"
                className="input-without-border-radius"
                onChange={onTextChange}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {t("GENERAL.EMAIL")}
              <span className="red-text">*</span>
            </span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <AlternateEmailIcon />
              </div>
              <Field
                name="email"
                component="input"
                type="email"
                placeholder="example@mail.com"
                className="input-without-border-radius"
                onChange={onTextChange}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {t("LOGIN.PASSWORD")}
              <span className="red-text">*</span>
            </span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <KeyVariantIcon />
              </div>
              <Field
                name="password"
                component="input"
                type={showPassword ? "text" : "password"}
                placeholder={t("LOGIN.PASSWORD")}
                className="input-without-border-radius"
                onChange={onTextChange}
              />
              <button
                type="button"
                className={`form__form-group-button${
                  showPassword ? " active" : ""
                }`}
                onClick={() => this.showPasswordToggle("password")}
              >
                <EyeIcon />
              </button>
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {t("GENERAL.CONFIRM_PASSWORD")}
              <span className="red-text">*</span>
            </span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <KeyVariantIcon />
              </div>
              <Field
                name="confirm_password"
                component="input"
                type={showConfirmPassword ? "text" : "password"}
                placeholder={t("GENERAL.CONFIRM_PASSWORD")}
                className="input-without-border-radius"
                onChange={onTextChange}
              />
              <button
                type="button"
                className={`form__form-group-button${
                  showConfirmPassword ? " active" : ""
                }`}
                onClick={() => this.showPasswordToggle("confirm_password")}
              >
                <EyeIcon />
              </button>
            </div>
          </div>
        </Col>
        <Col md={12}>
          <div className="mt-5 d-flex justify-content-center">
            <Col md={6}>
              <Button
                color="primary"
                className="account__btn mb-2"
                onClick={onSendPhoneCode}
              >
                {isUploading ? (
                  <Spinner size="sm" animation="border" varaint="primary" />
                ) : (
                  t("REGISTER.SEND_CODE")
                )}
              </Button>
              <div className="text-center">
                <p>
                  {t("REGISTER.ALREADY_HAVE_ACCOUNT")}{" "}
                  <NavLink to="/login">{t("LOGIN.SIGN_IN")}</NavLink>
                </p>
              </div>
            </Col>
          </div>
        </Col>
      </form>
    );
  }
}

export default reduxForm({
  form: "register_form",
})(withTranslation("common")(RegisterForm));
