import React, { PureComponent } from "react";
import { Card, CardBody, Col, Button, Modal } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import PlusIcon from "mdi-react/PlusIcon";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import AddPublisher from "./AddPublisher";

class Filter extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggleModal = () => {
    this.setState((prevState) => ({ modal: !prevState.modal }));
  };

  onCancel = () => {
    this.toggleModal();
    this.props.initialize();
  };

  render() {
    const { t, handleSubmit, toggleFilter, permissions, refreshAfterCreate } =
      this.props;
    const { modal } = this.state;

    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <form
              className="form form--vertical"
              onSubmit={handleSubmit(toggleFilter)}
            >
              <Col md={6} sm={12}>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    {t("GENERAL.NAME")}
                  </span>
                  <div className="form__form-group-field">
                    <Field name="filter_name" component="input" type="text" />
                  </div>
                </div>
              </Col>

              <Col md={6} sm={12}>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    {t("GENERAL.NAME_EN")}
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      name="filter_name_en"
                      component="input"
                      type="text"
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <Button color="primary" type="submit">
                    {t("GENERAL.FILTER")}
                  </Button>
                  {permissions.create && (
                    <Button
                      className="icon"
                      onClick={this.toggleModal}
                      color="success"
                    >
                      <p>
                        <PlusIcon /> {t("GENERAL.CREATE_A_NEW")}
                      </p>
                    </Button>
                  )}
                </div>
              </Col>
            </form>
          </CardBody>
        </Card>
        <AddPublisher
          isOpen={modal}
          toggleModal={this.toggleModal}
          refreshAfterCreate={refreshAfterCreate}
        />
      </Col>
    );
  }
}

export default reduxForm({
  form: "filter_publisher_form",
})(withTranslation("common")(Filter));
