import React from "react";
import {
  Card,
  CardBody,
  Col,
  Button,
  Row,
  CardTitle,
  CardText,
} from "reactstrap";
import { withTranslation } from "react-i18next";
import { AiFillTags, AiOutlineBarcode } from "react-icons/ai";
import { RiAddFill } from "react-icons/ri";
import ButtonModal from "../../../DeleteModal/Modal";
import { FaPlay } from "react-icons/fa";
import _ from "lodash";

const ellipsisText = {
  whiteSpace: "nowrap",
  minWidth: "193px",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const MediaReferenceList = (props) => {
  const {
    t,
    toggleCreateMediaRef,
    selectedMedia,
    editMediaRef,
    onCancelEditMedia,
    mediaSeries,
  } = props;
  const releatedMedia = mediaSeries
    ? selectedMedia.media_serie_data
    : selectedMedia.media_reference_data;

  return (
    <div className="media__reference">
      <div className="card__title">
        <h5 className="bold-text">
          {t(mediaSeries ? "MEDIAS.MEDIA_SERIES" : "MEDIAS.MEDIAS_REFERENCE")}
        </h5>
        <h5 className="subhead">
          {t("MEDIAS.CLICK_PLUS_BUTTON_TO_CREATE_NEW_MEDIA")}
        </h5>
      </div>
      <Row className="m-0">
        {/* to add new media reference */}
        <Col sm={12} md={6} lg={4} xl={3} className="pl-0 pr-2">
          <Card>
            <CardBody className="media_ref-card">
              <div className="plus-container" onClick={toggleCreateMediaRef}>
                <div className="wrapper-icon">
                  <RiAddFill className="icon-size" />
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        {!_.isEmpty(releatedMedia) &&
          releatedMedia.map((media) => (
            // to display all media reference
            <Col
              sm={12}
              md={6}
              lg={4}
              xl={3}
              className="pl-2 pr-2"
              key={media.id.toString()}
            >
              <Card>
                <CardBody className="media_ref-card-img">
                  <div className="img-wrapper">
                    {media.media_type === "mp4" ? (
                      <div>
                        <div className="media__cover-overlay">
                          <FaPlay color="white" className="media-play-icon" />
                        </div>
                        <img
                          className="media__cover"
                          src={media.s3_cover_media_url}
                          alt={media.title}
                        />
                      </div>
                    ) : (
                      <img
                        className="media-cover"
                        src={media.s3_cover_media_url}
                        alt="media_cover"
                      />
                    )}
                  </div>
                  <div style={{ padding: "10px" }}>
                    <CardTitle style={ellipsisText}>{media.title}</CardTitle>
                    <CardText style={ellipsisText}>
                      <AiFillTags /> {media.category_name}
                    </CardText>
                    <CardText style={ellipsisText} className="mt-1">
                      <AiOutlineBarcode /> {media.isbn}
                    </CardText>
                    <div className="d-flex justify-content-end mt-2 mb-2">
                      <div className="mr-2">
                        <ButtonModal
                          size="sm"
                          color="danger"
                          title={t(
                            "MEDIAS.DO_YOU_WANT_TO_REMOVE_THIS_MEDIA_REFERENCE_FROM_THIS_MEDIA"
                          )}
                          btn={t("BUTTON.DELETE")}
                          message={media.title}
                          actionKey="delete"
                          actionModule="medias_reference"
                          actionId={media.id}
                          selectedMediaId={selectedMedia.id}
                        />
                      </div>
                      <Button
                        color="primary"
                        size="sm"
                        onClick={() => editMediaRef(media)}
                      >
                        {t("BUTTON.EDIT")}
                      </Button>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          ))}
        <Col md={12}>
          <div className="d-flex justify-content-end">
            <Button type="button" onClick={onCancelEditMedia}>
              {t("BUTTON.BACK")}
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default withTranslation("common")(MediaReferenceList);
