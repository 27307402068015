import React from "react";
import { Col, Button, Spinner, Alert } from "reactstrap";
import { Field, Form } from "redux-form";
import { withTranslation } from "react-i18next";
import KeyVariantIcon from "mdi-react/KeyVariantIcon";
import EyeIcon from "mdi-react/EyeIcon";

const ChangeUserPassword = ({
  t,
  onChangePassword,
  error,
  permissions,
  errorMessage,
  onTextInput,
  showPassword,
  showNewPassword,
  showConfirmPassword,
  toggleGobackTo,
  isUploadingChangePassword,
}) => (
  <div>
    <div className="card__title pl-3 pr-3">
      <h5 className="bold-text">{t("SYSTEM_USER.CHANGE_PASSWORD")}</h5>
      <h5 className="subhead">
        {t("SYSTEM_USER.PLEASE_INPUT_NEW_PASSWORD_TO_CHANGE_PASSWORD")}
      </h5>
    </div>
    <Form className="form login-form">
      <Alert color="danger" isOpen={error} className="ml-3 mr-3">
        {errorMessage}
      </Alert>

      <Col md={6} sm={12}>
        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("CHANGE_PASSWORD.NEW_PASSWORD")}
            <span className="red-text">*</span>
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <Field
              name="new_password"
              component="input"
              type={showNewPassword ? "text" : "password"}
              placeholder="xxxxxx"
              onChange={onTextInput}
            />
            <button
              className={`form__form-group-button${
                showNewPassword ? " active" : ""
              }`}
              type="button"
              onClick={() => showPassword("new_password")}
            >
              <EyeIcon />
            </button>
          </div>
        </div>
      </Col>
      <Col md={6} sm={12}>
        <div className="form__form-group">
          <span className="form__form-group-label">
            {t("CHANGE_PASSWORD.CONFIRM_PASSWORD")}
            <span className="red-text">*</span>
          </span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <Field
              name="confirm_password"
              component="input"
              type={showConfirmPassword ? "text" : "password"}
              placeholder="xxxxxx"
              onChange={onTextInput}
            />
            <button
              className={`form__form-group-button${
                showConfirmPassword ? " active" : ""
              }`}
              type="button"
              onClick={() => showPassword("confirm_password")}
            >
              <EyeIcon />
            </button>
          </div>
        </div>
      </Col>
      <Col md={12} sm={12}>
        <div className="d-flex justify-content-end">
          {permissions && permissions.edit && (
            <Button
              color="primary"
              onClick={onChangePassword}
              disabled={isUploadingChangePassword}
            >
              {isUploadingChangePassword ? (
                <Spinner animation="border" variant="primary" size="sm" />
              ) : (
                t("BUTTON.EDIT")
              )}
            </Button>
          )}
          <Button
            className="icon"
            onClick={toggleGobackTo}
            disabled={isUploadingChangePassword}
          >
            {t("BUTTON.BACK")}
          </Button>
        </div>
      </Col>
    </Form>
  </div>
);

export default withTranslation("common")(ChangeUserPassword);
