import React from "react";
import { Col, Button, Row, Spinner } from "reactstrap";
import { withTranslation } from "react-i18next";
import { Field } from "redux-form";
import renderSelectField from "../../../../components/form/Select";
import MediaCard from "../MediaCard";
import _ from "lodash";
const SearchExistingMedias = ({
  t,
  onTextChange,
  onChangeSelect,
  viewMediaContent,
  toggleCreateMediaRef,
  handleSearchMedia,
  // changeRating,
  createMediaRefByExisting,
  isEmptySearchData,
  isUploadingMediaRef,
  searchMediaData,
  permissions,
  uploadingMediaId,
}) => (
  <div>
    <div className="card__title">
      <h5 className="bold-text">{t("MEDIAS.ADD_MEDIA_REFERENCE")}</h5>
      <h5 className="subhead">
        {t("MEDIAS.PLEASE_INPUT_VALUE_FOR_SEARCHING_EXISTING_MEDIAS")}
      </h5>
    </div>
    <form className="form">
      <div className="form__form-group">
        <Row>
          <Col md={6} sm={12}>
            <div className="form__form-group">
              <span className="form__form-group-label">
                {`${t("MEDIAS.SEARCH_BY_ID")} :`}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="searchId"
                  component="input"
                  type="text"
                  onChange={onTextChange}
                />
              </div>
            </div>
          </Col>

          <Col md={6} sm={12}>
            <div className="form__form-group">
              <span className="form__form-group-label">
                {`${t("MEDIAS.SEARCH_BY_TITLE")} :`}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="searchTitle"
                  component="input"
                  type="text"
                  onChange={onTextChange}
                />
              </div>
            </div>
          </Col>

          <Col md={6} sm={12}>
            <div className="form__form-group">
              <span className="form__form-group-label">
                {t("GENERAL.IS_PUBLIC")}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="is_public"
                  component={renderSelectField}
                  options={[
                    { value: "", label: "---" },
                    { value: "none", label: t("GENERAL.NO") },
                    {
                      value: "true",
                      label: t("GENERAL.YES"),
                    },
                  ]}
                  onChange={(val) => onChangeSelect(val, "is_public")}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Col md={12} className="p-0">
        <div className="d-flex justify-content-end">
          <Button onClick={() => toggleCreateMediaRef()}>
            {t("BUTTON.CANCEL")}
          </Button>
          <Button color="primary" onClick={() => handleSearchMedia()}>
            {t("BUTTON.SEARCH")}
          </Button>
        </div>
      </Col>

      <Col md={12} className="p-0">
        {!isEmptySearchData && !_.isEmpty(searchMediaData) && (
          <div
            style={{
              width: "100%",
              height: "auto",
              backgroundColor: "rgba(0, 0, 0, 0.1)",
              borderRadius: 5,
              padding: "10px 25px",
            }}
          >
            <div className="media justify-content-between">
              <div className="media__card-wrapper">
                <Col md={12}>
                  <Row>
                    {searchMediaData.map((media) => (
                      <Col
                        key={media.id.toString()}
                        md={6}
                        sm={12}
                        className="p-0 mb-3"
                      >
                        <MediaCard
                          media={media}
                          permissions={permissions}
                          // changeRating={changeRating}
                          viewMediaContent={viewMediaContent}
                        />
                        <div className="mt-2 text-left">
                          <Button
                            size="sm"
                            color="primary"
                            onClick={() => createMediaRefByExisting(media)}
                          >
                            {isUploadingMediaRef &&
                            uploadingMediaId === media.id ? (
                              <Spinner
                                animation="border"
                                variant="primary"
                                size="sm"
                              />
                            ) : (
                              t("BUTTON.ADD_MEDIA")
                            )}
                          </Button>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </div>
            </div>
          </div>
        )}
        {isEmptySearchData && (
          <div
            style={{
              width: "100%",
              height: "auto",
              backgroundColor: "rgba(0, 0, 0, 0.1)",
              borderRadius: 5,
              padding: "10px 25px",
            }}
          >
            <Col md={12}>
              <h5 className="text-center p-3">{t("GENERAL.EMPTY_DATA")}</h5>
            </Col>
          </div>
        )}
      </Col>
    </form>
  </div>
);

export default withTranslation("common")(SearchExistingMedias);
