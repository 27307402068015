import React, { PureComponent } from "react";
import { Col, Button, Row, Spinner, Alert, Input } from "reactstrap";
import { withTranslation } from "react-i18next";
import Service from "../../../config/Service";
import { RenderInputField } from "../../../components/RenderField";
import _ from "lodash";

class AddForm extends PureComponent {
  constructor() {
    super();
    this.state = {
      publishyearData: {
        name: "",
        name_en: "",
        sortkey: "",
      },
      isUploading: false,
      showAlert: false,
      alertMessage: "",
      alertColor: "primary",
    };
  }

  componentDidMount() {}

  onTextChange = (event) => {
    const fieldName = event.target.name;
    const value = event.target.value;
    let publishyearData = { ...this.state.publishyearData };
    publishyearData[fieldName] = value;
    this.setState({ publishyearData });
  };

  actionSubmit = () => {
    const { publishyearData } = this.state;
    const { t } = this.props;
    if (
      !publishyearData.name ||
      !publishyearData.name_en ||
      !publishyearData.sortkey
    ) {
      this.setState({
        alertMessage: t("GENERAL.ALL_FIELDS_ARE_REQURED"),
        showAlert: true,
        alertColor: "danger",
      });
    } else {
      this.setState({
        isUploading: true,
      });
      this.postPublishyear(publishyearData);
    }
  };

  postPublishyear = (data) => {
    const { refreshAfterCreate, t } = this.props;
    Service.post("publishyears", data)
      .then((response) => {
        if (response.data.success) {
          this.setState({
            alertMessage: t(
              "GENERAL.THE_ACTION_HAS_BEEN_COMPLETED_SUCCESSFULLY"
            ),
            showAlert: true,
            isUploading: false,
            alertColor: "primary",
          });
          refreshAfterCreate();
          this.props.onCancel();
        } else {
          this.setState({
            alertMessage: response.data.message,
            showAlert: true,
            alertColor: "danger",
            isUploading: false,
          });
        }
      })
      .catch((e) => {
        this.setState({
          alertMessage: "Error",
          showAlert: true,
          alertColor: "danger",
          isUploading: false,
        });
        console.log(e);
      });
  };
  render() {
    const { t, onCancel } = this.props;
    const {
      isUploading,
      publishyearData,
      alertMessage,
      showAlert,
      alertColor,
    } = this.state;

    return (
      <Col md={12}>
        <Row>
          <Col md={12}>
            <h3 className="page-title">
              {t("GENERAL.CREATE_NEW_PUBLISHYEAR")}
            </h3>
          </Col>
        </Row>
        <Col md={12}>
          <form className="form login-form">
            <Alert color={alertColor} isOpen={showAlert}>
              {alertMessage}
            </Alert>
            <Col md={6} sm={12} className="pl-0">
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {t("GENERAL.NAME")}
                </span>
                <span className="red-text">*</span>
                <div className="form__form-group-field">
                  <Input
                    name="name"
                    component={RenderInputField}
                    type="text"
                    value={publishyearData.name}
                    placeholder={t("GENERAL.NAME")}
                    onChange={this.onTextChange}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {t("GENERAL.SORT_KEY")}
                </span>
                <span className="red-text">*</span>
                <div className="form__form-group-field">
                  <Input
                    name="sortkey"
                    component={RenderInputField}
                    type="number"
                    value={publishyearData.sortkey}
                    placeholder={t("GENERAL.SORT_KEY")}
                    onChange={this.onTextChange}
                  />
                </div>
              </div>
            </Col>

            <Col md={6} sm={12} className="pr-0">
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {t("GENERAL.NAME_EN")}
                </span>
                <span className="red-text">*</span>
                <div className="form__form-group-field">
                  <Input
                    name="name_en"
                    component={RenderInputField}
                    type="text"
                    value={publishyearData.name_en}
                    placeholder={t("GENERAL.NAME_EN")}
                    onChange={this.onTextChange}
                  />
                </div>
              </div>
            </Col>
            <Col md={12}>
              <div className="d-flex justify-content-end">
                <Button
                  color="primary"
                  disabled={isUploading}
                  onClick={this.actionSubmit}
                >
                  {isUploading ? (
                    <Spinner animation="border" varaint="primary" size="sm" />
                  ) : (
                    t("GENERAL.SAVE")
                  )}
                </Button>
                <Button type="button" onClick={onCancel} disabled={isUploading}>
                  {t("GENERAL.BACK")}
                </Button>
              </div>
            </Col>
          </form>
        </Col>
      </Col>
    );
  }
}

export default withTranslation("common")(AddForm);
