/* eslint-disable */
const validate = (values) => {
  const errors = {};
  if (!values.phone) {
    errors.phone = 'Phone shouldn’t be empty';
  }
  return errors;
};

export default validate;
