import React from "react";
import { Button, CardText, CardTitle } from "reactstrap";
import { withTranslation } from "react-i18next";
import { BsPeopleCircle } from "react-icons/bs";
import { AiOutlineBarcode, AiFillCalendar, AiFillTags } from "react-icons/ai";
import StarRatings from "react-star-ratings";
import { FaPlay } from "react-icons/fa";
import moment from "moment";

const MediaCard = ({ media, permissions, changeRating, viewMediaContent }) => {
  return (
    <div className="media__card-row">
      {media.media_type === "mp4" ? (
        <div
          className="media__btn-cover"
          onClick={
            permissions.view
              ? () => viewMediaContent(media.s3_media_url)
              : () => {}
          }
        >
          <div className="media__cover-overlay">
            <FaPlay color="white" className="media-play-icon" />
          </div>
          <img
            className="media__cover"
            src={media.s3_cover_media_url}
            alt={media.title}
          />
        </div>
      ) : (
        <Button
          className="media__btn-cover"
          onClick={
            permissions.view
              ? () => viewMediaContent(media.s3_media_url)
              : () => {}
          }
        >
          <img
            className="media__cover"
            src={media.s3_cover_media_url}
            alt={media.title}
          />
        </Button>
      )}

      <div className="media__text-column">
        <CardTitle className="mb-0 ellipsis-text">{media.title}</CardTitle>
        <StarRatings
          rating={media.like_value_avg}
          starHoverColor="#ffa900"
          starRatedColor="#ffa900"
          // changeRating={changeRating}
          numberOfStars={5}
          starDimension="20px"
          starSpacing="1px"
          name={media.id}
        />
        <CardText>
          <span>
            <BsPeopleCircle />{" "}
          </span>
          {media.author_fullname}
        </CardText>
        <CardText className="ellipsis-text">
          <span>
            <AiFillTags />{" "}
          </span>
          {media.category_name}
        </CardText>
        <CardText>
          <span>
            <AiFillCalendar />{" "}
          </span>
          {moment(media.created_at).format("DD MMMM YY")}
        </CardText>
        <CardText className="ellipsis-text">
          <span>
            <AiOutlineBarcode />{" "}
          </span>
          {media.isbn}
        </CardText>
      </div>
    </div>
  );
};

export default withTranslation("common")(MediaCard);
