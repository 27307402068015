import React from 'react';
import './loading-view.scss';
import { Card, CardBody, Row, Col } from 'reactstrap';

const LoadingView = () => (
  <Card>
    <CardBody className="loading-view mb-5">
      <div className="loading-view__container">
        <Row className="padding-bottom-30">
          <div className="sm-width-height"></div>
          <Col>
            <div className="lg-width-height"></div>
            <div className="width-300"></div>
            <div className="width-250"></div>
            <div className="width-250"></div>
            <div className="width-250"></div>
          </Col>
        </Row>
      </div>
    </CardBody>
    <CardBody className="loading-view">
      <div className="loading-view__container">
        <Row className="padding-bottom-30">
          <div className="sm-width-height"></div>
          <Col>
            <div className="lg-width-height"></div>
            <div className="width-300"></div>
            <div className="width-250"></div>
            <div className="width-250"></div>
            <div className="width-250"></div>
          </Col>
        </Row>
        <Row className="padding-bottom-30">
          <div className="sm-width-height"></div>
          <Col>
            <div className="lg-width-height"></div>
            <div className="width-300"></div>
            <div className="width-250"></div>
            <div className="width-250"></div>
            <div className="width-250"></div>
          </Col>
        </Row>
        <Row className="padding-bottom-30">
          <div className="sm-width-height"></div>
          <Col>
            <div className="lg-width-height"></div>
            <div className="width-300"></div>
            <div className="width-250"></div>
            <div className="width-250"></div>
            <div className="width-250"></div>
          </Col>
        </Row>
      </div>
    </CardBody>
  </Card>
)

export default LoadingView;