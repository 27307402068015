import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import RegisterForm from "./RegisterForm";
import VerifyPhoneForm from "./VerifyPhoneForm";
import { withTranslation } from "react-i18next";
import NotificationSystem from "rc-notification";
import { BasicNotification } from "../../../components/Notification";
import firebase from "../../../config/firebase";
import Service from "../../../config/Service";
import _ from "lodash";

let notification = null;
class Register extends Component {
  constructor() {
    super();
    this.state = {
      registerData: {
        role_id: 1, // set default role is admin
      },
      errorMessage: "",
      isUploading: false,
      goToVerifyPhone: false,
    };
  }

  componentDidMount() {
    NotificationSystem.newInstance(
      { style: { top: 10 } },
      (n) => (notification = n)
    );
  }

  sendNotification = (title, smg, color = "danger") => {
    if (smg == "TOO_SHORT") smg = "Phone number is too short";
    else if (smg == "TOO_LONG") smg = "Phone number is too long";
    return notification.notice({
      content: <BasicNotification title={title} color={color} message={smg} />,
      duration: 5,
      closable: true,
      style: { top: 0, left: "calc(100vw - 100%)" },
      className: `right-up `,
    });
  };

  // set up recaptcha checking robotic security
  setUpRecaptcha = () => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "captchaid001",
      {
        size: "invisible",
      }
    );
  };

  checkRequiredFields = () => {
    const { registerData } = this.state;
    const { t } = this.props;

    if (
      !registerData.fullname ||
      !registerData.fullname_en ||
      !registerData.phone ||
      !registerData.email ||
      !registerData.password ||
      !registerData.confirm_password
    ) {
      this.setState({
        errorMessage: t("REGISTER.ALL_FIELDS_ARE_REQURED"),
        goToVerifyPhone: false,
      });
      return;
    }
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(registerData.email)) {
      this.setState({
        errorMessage: t("REGISTER.INVALID_EMAIL"),
      });
      return;
    }
    if (!_.isEqual(registerData.password, registerData.confirm_password)) {
      this.setState({
        errorMessage: t("REGISTER.PASSWORD_NOT_MATCH"),
      });
      return;
    }
    if (
      registerData.password.length < 6 ||
      registerData.confirm_password.length < 6
    ) {
      this.setState({
        errorMessage: t("REGISTER.PASSWORD_LENGTH_MUST_BE_MORE_THAN_SIX"),
        error: true,
      });
      return;
    }
    this.checkExistingPhone();
  };

  checkExistingPhone = () => {
    const { t } = this.props;
    const { registerData } = this.state;
    const data = {
      phone: registerData.phone,
    };
    console.log("resetPasswordData =>", data);
    Service.post("user/check-existing-phone", data).then((response) => {
      console.log("response check phone =>", response);
      if (!response.data.success) {
        this.setState({ errorMessage: "" });
        this.sendCodeSMS(registerData);
      } else {
        this.setState({
          errorMessage: t("GENERAL.PHONE_ALREADY_EXIST"),
        });
      }
    });
  };

  sendCodeSMS = (registerData) => {
    this.setState({ isUploading: true });
    this.setUpRecaptcha();

    const appVerifier = window.recaptchaVerifier;
    firebase
      .auth()
      .signInWithPhoneNumber("+855" + parseInt(registerData.phone), appVerifier)
      .then((confirmationResult) => {
        console.log("confirm sms", confirmationResult);
        window.confirmationResult = confirmationResult;
        this.setState({
          goToVerifyPhone: true,
          isUploading: false,
        });
      })
      .catch((error) => {
        this.setState({ isUploading: false, goToVerifyPhone: false });
        this.sendNotification("SMS phone code message: ", error.message);
      });
  };

  onTextChange = (event) => {
    const fieldName = event.target.name;
    const registerData = { ...this.state.registerData };
    registerData[fieldName] = event.target.value;

    this.setState({ registerData });
  };

  render() {
    const { t } = this.props;
    const {
      isUploading,
      goToVerifyPhone,
      registerData,
      errorMessage,
    } = this.state;
    return (
      <div className="account account--not-photo">
        <div className="account__wrapper">
          <div className="register__card">
            <div className="account__head">
              <h3 className="account__title">
                {t("GENERAL.WELCOME_TO")}
                <span className="account__logo"> E-Library</span>
              </h3>
              <h4 className="account__subhead subhead">
                {goToVerifyPhone
                  ? t("REGISTER.VERIFICATION_SMS_CODE")
                  : t("REGISTER.CREATE_ACCOUNT")}
              </h4>
            </div>
            {!goToVerifyPhone && (
              <RegisterForm
                onSendPhoneCode={this.checkRequiredFields}
                onTextChange={this.onTextChange}
                errorMessage={errorMessage}
                isUploading={isUploading}
              />
            )}
            {goToVerifyPhone && <VerifyPhoneForm registerData={registerData} />}
            <div id="captchaid001" />
          </div>
        </div>
      </div>
    );
  }
}

Register.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default withRouter(withTranslation("common")(Register));
