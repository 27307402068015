import React from "react";
import PropTypes from "prop-types";
import Pagination from "@material-ui/lab/Pagination";

const CustomPagination = ({ itemsCount, itemsToShow, onChangePage, page }) => {
  return itemsCount ? (
    <div className="pagination__wrap">
      {itemsCount <= 1 ? (
        ""
      ) : (
        <Pagination
          page={page}
          defaultPage={1}
          boundaryCount={2}
          siblingCount={2}
          count={Math.ceil(itemsCount / itemsToShow)}
          color="standard"
          onChange={(e, pageOfItems) => onChangePage(pageOfItems)}
        />
      )}
    </div>
  ) : (
    <div />
  );
};

CustomPagination.propTypes = {
  onChangePage: PropTypes.func.isRequired,
  itemsCount: PropTypes.number.isRequired,
  itemsToShow: PropTypes.number.isRequired,
};

export default CustomPagination;
