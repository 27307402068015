import React from "react";
import { Card, CardBody, Col, Button } from "reactstrap";
import { withTranslation } from "react-i18next";
import { PlaceholderProfile } from "../../../../shared/img";
import Image, { Shimmer } from "react-shimmer";

const userRole = (userInfo) => {
  if (userInfo.role_id == 1) {
    return "Admin";
  } else if (userInfo.role_id == 2) {
    return "Superadmin";
  } else {
    return "Author";
  }
};

const ProfileMain = ({ userInfo, t, handleEditUser }) => (
  <Col md={12} lg={12} xl={12}>
    <Card>
      <CardBody className="profile__card">
        <div className="profile__information">
          <div className="profile__avatar">
            <Image
              src={userInfo.s3_photo_url || PlaceholderProfile}
              alt="avatar"
              style={{ objectFit: "cover" }}
              fallback={<Shimmer width={800} height={600} />}
            />
          </div>
          <div className="profile__data">
            <p className="profile__name">{userInfo.fullname}</p>
            <p className="profile__work">{userRole(userInfo)}</p>
            <p className="profile__contact">{userInfo.email}</p>
            <p className="profile__contact" dir="ltr">
              {userInfo.phone}
            </p>
            <Button
              color="primary"
              className="icon profile__btn"
              size="sm"
              onClick={handleEditUser}
            >
              {t("BUTTON.EDIT")}
            </Button>
          </div>
        </div>
        <div className="profile__stats">
          <div className="profile__stat">
            <p className="profile__stat-number">
              {userInfo.count_medias_total}
            </p>
            <p className="profile__stat-title">Total Medias</p>
          </div>
          <div className="profile__stat">
            <p className="profile__stat-number">
              {userInfo.count_medias_pending}
            </p>
            <p className="profile__stat-title">Pending Medias</p>
          </div>
          <div className="profile__stat">
            <p className="profile__stat-number">
              {userInfo.count_medias_approving}
            </p>
            <p className="profile__stat-title">Approved Medias</p>
          </div>
        </div>
      </CardBody>
    </Card>
  </Col>
);

export default withTranslation("common")(ProfileMain);
