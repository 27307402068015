import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import { withTranslation } from "react-i18next";
import AuthorCardView from "./components/CardAuthor";
import FilterView from "./components/FilterAuthor";
import Service from "../../config/Service";
import { fetchActionAuthors } from "../../redux/actions/fetchApiActions";
import LoadingView from "../../components/LoadingView/LoadingView";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import "./author.scss";
import Utilities from "../../shared/helpers/Utilities";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewComponent: false,
      isFetchingData: true,
      itemsPerPage: 12,
      pageOfItems: 1,
      permissions: null,
      filterValue: null,
    };
  }

  componentDidMount() {
    const permissionData = Utilities.getPermission(this.props.user, "authors");
    this.setState({
      permissions: permissionData,
    });
    const {
      location: { state },
    } = this.props.history;
    this.setState(
      {
        pageOfItems: (state && state.pageOfItems) || 1,
        filterValue: (state && state.filterValue) || {},
      },
      () => {
        this.getAuthorList();
      }
    );
  }

  getAuthorList = () => {
    const { pageOfItems, itemsPerPage, filterValue } = this.state;
    let params = "";
    if (filterValue) {
      const name = filterValue.fullname || "";
      const name_en = filterValue.fullname_en || "";
      params = `perPage=${itemsPerPage}&page=${pageOfItems}&fullname=${name}&fullname_en=${name_en}`;
    } else {
      params = `perPage=${itemsPerPage}&page=${pageOfItems}`;
    }
    this.getSearchAuthor(params);
  };

  onChangePage = async (pageOfItems) => {
    if (pageOfItems) {
      await this.setState({ pageOfItems });
      await this.getAuthorList();
    }
  };

  refreshAfterCreate = () => {
    const { itemsPerPage } = this.state;
    const params = `perPage=${itemsPerPage}`;
    this.setState({ pageOfItems: 1 });
    this.getSearchAuthor(params);
  };

  toggleFilter = (value) => {
    const { itemsPerPage } = this.state;
    const name = value.fullname || "";
    const name_en = value.fullname_en || "";
    const filterParams = `perPage=${itemsPerPage}&fullname=${name}&fullname_en=${name_en}`;

    this.setState({
      filterValue: value,
      pageOfItems: 1,
    });

    this.getSearchAuthor(filterParams);
  };

  getSearchAuthor = (params) => {
    const { dispatch } = this.props;
    Service.getSearch("authors", params)
      .then((response) => {
        if (response.data.success) {
          this.setState({
            isFetchingData: false,
          });
          dispatch(fetchActionAuthors(response.data));
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  render() {
    const { t } = this.props;
    const {
      isFetchingData,
      itemsPerPage,
      pageOfItems,
      permissions,
      filterValue,
    } = this.state;
    if (isFetchingData) {
      return <LoadingView />;
    } else {
      return (
        <Container className="todo-app">
          <Row>
            <Col md={12}>
              <h3 className="page-title">{t("SIDE_BAR.LITST_OF_AUTHORS")}</h3>
            </Col>
          </Row>
          <Row>
            <FilterView
              toggleFilter={this.toggleFilter}
              permissions={permissions}
              filterValue={filterValue}
              itemsPerPage={itemsPerPage}
              pageOfItems={pageOfItems}
              refreshAfterCreate={this.refreshAfterCreate}
            />
          </Row>
          <Row>
            <AuthorCardView
              itemsPerPage={itemsPerPage}
              pageOfItems={pageOfItems}
              permissions={permissions}
              onChangePage={this.onChangePage}
              filterValue={filterValue}
            />
          </Row>
        </Container>
      );
    }
  }
}

const mapDispatchToProps = {
  fetchActionAuthors,
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

Index = reduxForm({
  form: "author_info_form",
})(Index);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("common")(Index));
