import React, { Component, Fragment } from "react";
import { connect, Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18n from "i18next";
import "firebase/auth";
import "bootstrap/dist/css/bootstrap.css";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import "../../scss/app.scss";
import PropTypes from "prop-types";
import Router from "./Router";
import stores from "../../redux/store/store";
import ScrollToTop from "./ScrollToTop";
import { config as i18nextConfig } from "../../translations";
import Loading from "../../components/Loading";

import { PersistGate } from "redux-persist/integration/react";
const { persistor, store } = stores();

i18n.init(i18nextConfig);

const ThemeComponent = ({ children, themeName }) => {
  const theme = createMuiTheme({
    palette: {
      type: themeName === "theme-dark" ? "dark" : "light",
    },
  });
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

ThemeComponent.propTypes = {
  children: PropTypes.shape().isRequired,
  themeName: PropTypes.string.isRequired,
};

const ConnectedThemeComponent = connect((state) => ({
  themeName: state.theme.className,
}))(ThemeComponent);

class App extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      loaded: false,
    };
  }

  componentDidMount() {
    window.addEventListener("load", () => {
      this.setState({ loading: false });
      setTimeout(() => this.setState({ loaded: true }), 500);
    });
  }

  render() {
    const { loaded, loading } = this.state;
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter basename="/">
            {/* //main */}
            <I18nextProvider i18n={i18n}>
              <ScrollToTop>
                <Fragment>
                  {!loaded && <Loading loading={loading} />}
                  <ConnectedThemeComponent>
                    <div>
                      <Router />
                    </div>
                  </ConnectedThemeComponent>
                </Fragment>
              </ScrollToTop>
            </I18nextProvider>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
