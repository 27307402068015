import React from "react";
import {
  ListGroupItem,
  Collapse,
  ListGroup,
  Col,
  Input,
  Row,
  Button,
} from "reactstrap";
import { RenderRadioButtonField } from "../../../components/RenderField";
import { Field } from "redux-form";
import { AiFillFolderOpen, AiFillFolder, AiFillTags } from "react-icons/ai";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { withTranslation } from "react-i18next";
import _ from "lodash";

class CategoryTree extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categorySearchData: {
        name: "",
        name_en: "",
        id: "",
      },
    };
  }

  toggleTree = (id) => {
    this.setState((state) => ({ [id]: !state[id] }));
  };

  onTextChange = (event) => {
    const fieldName = event.target.name;
    const value = event.target.value;
    let categorySearchData = { ...this.state.categorySearchData };
    categorySearchData[fieldName] = value;
    this.setState({
      categorySearchData,
    });
  };
  mapper = (nodes, lvl) => {
    const { onChangeCategory } = this.props;
    return nodes.map((node, index) => {
      const id = node.id;
      const item = (
        <React.Fragment key={index.toString()}>
          <ListGroupItem className="category__list-group-item">
            {
              <div
                style={{ paddingLeft: `${25 * lvl}px`, display: "flex" }}
                onClick={() => this.toggleTree(id)}
              >
                {!_.isEmpty(node.child_data) && (
                  <div className="icon-wrapper" id={id}>
                    {this.state[id] ? (
                      <AiFillFolderOpen size={20} />
                    ) : (
                      <AiFillFolder size={20} />
                    )}
                  </div>
                )}
                {_.isEmpty(node.child_data) && (
                  <div className="icon-wrapper">
                    <AiFillTags size={16} />
                  </div>
                )}
                <div className="radio-field-wrapper">
                  <div className="margin-top-5">
                    <Field
                      name="category"
                      component={RenderRadioButtonField}
                      label={node.text}
                      radioValue={node.id}
                      onChange={() => onChangeCategory(node)}
                    />
                  </div>
                  {!_.isEmpty(node.child_data) && (
                    <div className="icon-right">
                      {this.state[id] ? <BsChevronUp /> : <BsChevronDown />}
                    </div>
                  )}
                </div>
              </div>
            }
          </ListGroupItem>
          {node.child_data && (
            <Collapse isOpen={this.state[id]}>
              {this.mapper(node.child_data, (lvl || 0) + 1)}
            </Collapse>
          )}
        </React.Fragment>
      );
      return item;
    });
  };

  render() {
    const {
      t,
      categoryTree,
      onSearchCategory,
      onCancelSelectedTree,
      onConfirmSelectedTree,
    } = this.props;
    const { categorySearchData } = this.state;
    return (
      <div>
        <form className="form form--vertical">
          <Col md={12} style={{ backgroundColor: "#f2f4f7", borderRadius: 4 }}>
            <Row className="pt-2">
              <Col md={6}>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    {t("GENERAL.NAME")}
                  </span>
                  <div className="form__form-group-field">
                    <Input
                      style={{ border: "1px solid #dddddd" }}
                      name="name"
                      type="text"
                      value={categorySearchData.name || ""}
                      onChange={this.onTextChange}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    {t("GENERAL.ID")}
                  </span>
                  <div className="form__form-group-field">
                    <Input
                      name="id"
                      type="number"
                      value={categorySearchData.id || ""}
                      onChange={this.onTextChange}
                      style={{ border: "1px solid #dddddd" }}
                    />
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    {t("GENERAL.NAME_EN")}
                  </span>
                  <div className="form__form-group-field">
                    <Input
                      style={{ border: "1px solid #dddddd" }}
                      name="name_en"
                      type="text"
                      value={categorySearchData.name_en || ""}
                      onChange={this.onTextChange}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <Button
                    onClick={() => onSearchCategory(categorySearchData)}
                    type="button"
                    color="primary"
                    className="mb-0 mt-3"
                  >
                    {t("BUTTON.SEARCH")}
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </form>
        <div className="p-3">
          <ListGroup>{this.mapper(categoryTree)}</ListGroup>
        </div>
        {!_.isEmpty(categoryTree) && (
          <div className="d-flex__flex-end">
            <Button
              onClick={onCancelSelectedTree}
              type="button"
              className="modal-btn"
            >
              {t("BUTTON.CANCEL")}
            </Button>
            <Button
              onClick={onConfirmSelectedTree}
              type="button"
              color="primary"
              className="modal-btn"
            >
              {t("BUTTON.CONFIRM")}
            </Button>
          </div>
        )}
        {_.isEmpty(categoryTree) && (
          <Col md={12}>
            <h5 className="text-center p-3">{t("GENERAL.EMPTY_DATA")}</h5>
          </Col>
        )}
      </div>
    );
  }
}

export default withTranslation("common")(CategoryTree);
