import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import { withTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import TableView from "./components/TablePublisher";
import FilterView from "./components/FilterPublisher";
import DetailView from "./components/DetailPublisher";
import Service from "../../config/Service";
import LoadingView from "../../components/LoadingView/LoadingView";
import { fetchActionPublishers } from "../../redux/actions/fetchApiActions";
import Utilities from "../../shared/helpers/Utilities";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewComponent: false,
      itemsPerPage: 12,
      pageOfItems: 1,
      isFetchingData: true,
      permissions: null,
    };
  }
  componentDidMount() {
    const permissionData = Utilities.getPermission(
      this.props.user,
      "publishers"
    );
    this.setState({
      permissions: permissionData,
    });
    this.getPublisherList();
  }

  goBackToView = (toView) => {
    this.setState({
      viewComponent: "default_view",
    });
  };

  /**
   * BEGIN DETAIL PUBLISHER
   */
  detailView = (obj) => {
    this.setState({
      viewComponent: "COMPONENT_VIEW",
      clickId: obj.id,
    });
  };

  getPublisherList = () => {
    const { dispatch } = this.props;
    const { pageOfItems, itemsPerPage, value } = this.state;
    let params = "";
    if (value) {
      const name = value.filter_name || "";
      const name_en = value.filter_name_en || "";
      params = `perPage=${itemsPerPage}&page=${pageOfItems}&name=${name}&name_en=${name_en}`;
    } else {
      params = `perPage=${itemsPerPage}&page=${pageOfItems}`;
    }
    Service.getSearch("publishers", params)
      .then((response) => {
        if (response) {
          this.setState({
            totalPublishers: response.data.total,
            isFetchingData: false,
          });
          dispatch(fetchActionPublishers(response.data));
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  onChangePage = async (pageOfItems) => {
    if (pageOfItems) {
      await this.setState({ pageOfItems });
      await this.getPublisherList();
    }
  };

  refreshAfterCreate = () => {
    const { itemsPerPage } = this.state;
    const { dispatch } = this.props;
    const params = `perPage=${itemsPerPage}`;
    this.setState({ pageOfItems: 1 });
    Service.getSearch("publishers", params)
      .then((response) => {
        if (response) {
          this.setState({
            totalPublishers: response.data.total,
            isFetchingData: false,
          });
          dispatch(fetchActionPublishers(response.data));
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  toggleFilter = (value) => {
    const { itemsPerPage } = this.state;
    const { dispatch } = this.props;

    const name = value.filter_name || "";
    const name_en = value.filter_name_en || "";

    const filterParams = `perPage=${itemsPerPage}&name=${name}&name_en=${name_en}`;

    this.setState({ value, pageOfItems: 1 });
    Service.getSearch("publishers", filterParams)
      .then((response) => {
        dispatch(fetchActionPublishers(response.data, filterParams));
      })
      .catch((e) => {
        console.log(e);
      });
  };

  render() {
    const {
      itemsPerPage,
      pageOfItems,
      isFetchingData,
      permissions,
      value,
    } = this.state;
    const { t, theme, rtl } = this.props;
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      const permissions = user.permissions.split(",");
      if (!permissions.includes("d4xE9mATbjsZzRkVs2u5")) {
        return <Redirect push to="/module/permission" />;
      }
    }
    if (isFetchingData) {
      return <LoadingView />;
    } else {
      switch (this.state.viewComponent) {
        case "COMPONENT_VIEW":
          return (
            <Container className="todo-app">
              <Row>
                <DetailView
                  theme={theme}
                  clickId={this.state.clickId}
                  rtl={rtl.direction}
                  goBackToView={this.goBackToView}
                  permissions={permissions}
                  pageOfItems={pageOfItems}
                  itemsPerPage={itemsPerPage}
                />
              </Row>
            </Container>
          );
        default:
          return (
            <Container className="todo-app">
              <Row>
                <Col md={12}>
                  <h3 className="page-title">
                    {t("SIDE_BAR.LIST_OF_PUBLISHERS")}
                  </h3>
                </Col>
              </Row>
              <Row>
                <FilterView
                  theme={theme}
                  rtl={rtl.direction}
                  toggleFilter={this.toggleFilter}
                  permissions={permissions}
                  itemsPerPage={itemsPerPage}
                  pageOfItems={pageOfItems}
                  filterValue={value}
                  refreshAfterCreate={this.refreshAfterCreate}
                />
              </Row>
              <Row>
                <TableView
                  itemsPerPage={itemsPerPage}
                  pageOfItems={pageOfItems}
                  detailView={this.detailView}
                  onChangePage={this.onChangePage}
                  permissions={permissions}
                  filterValue={value}
                />
              </Row>
            </Container>
          );
      }
    }
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.theme,
    rtl: state.rtl,
    user: state.user,
  };
};

export default connect(mapStateToProps)(withTranslation("common")(Index));
