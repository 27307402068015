import React, { useState } from "react";
import { Button, CardText, CardTitle } from "reactstrap";
import { withTranslation } from "react-i18next";
import { BsPeopleCircle } from "react-icons/bs";
import { AiOutlineBarcode, AiFillCalendar, AiFillTags } from "react-icons/ai";
import StarRatings from "react-star-ratings";
import { FaPlay } from "react-icons/fa";
import moment from "moment";
import CustomModal from "../../DeleteModal/CustomActionModal";

const AuthorMedia = ({
  t,
  media,
  permissions,
  viewMediaContent,
  viewDetail,
  handleDeleteMedia,
  isLoadingDelete,
  handlePublicMedia,
  isLoadingPublic,
}) => {
  const [modal, setModal] = useState(false);
  const [modalPublic, setModalPublic] = useState(false);

  const toggle = () => {
    setModal((prevState) => !prevState);
  };
  const toggleModalPublic = () => {
    setModalPublic((prevState) => !prevState);
  };
  return (
    <div className="media justify-content-start">
      <div className="media__margin-content mr-0 ml-0">
        <div className="media__card-wrapper">
          <div className="media__card-row">
            {media.media_type === "mp4" ? (
              <div
                className="media__btn-cover"
                onClick={
                  permissions.view
                    ? () => viewMediaContent(media.s3_media_url)
                    : () => {}
                }
              >
                <div className="media__cover-overlay">
                  <FaPlay color="white" className="media-play-icon" />
                </div>
                <img
                  className="media__cover"
                  src={media.s3_cover_media_url}
                  alt={media.title}
                />
              </div>
            ) : (
              <Button
                className="media__btn-cover"
                onClick={
                  permissions.view
                    ? () => viewMediaContent(media.s3_media_url)
                    : () => {}
                }
              >
                <img
                  className="media__cover"
                  src={media.s3_cover_media_url}
                  alt={media.title}
                />
              </Button>
            )}

            <div className="media__text-column">
              <CardTitle className="mb-0 ellipsis-text">
                {media.title}
              </CardTitle>
              <StarRatings
                rating={media.like_value_avg}
                starHoverColor="#ffa900"
                starRatedColor="#ffa900"
                numberOfStars={5}
                starDimension="20px"
                starSpacing="1px"
                name={media.id}
              />
              <CardText>
                <span>
                  <BsPeopleCircle />{" "}
                </span>
                {media.author_fullname}
              </CardText>
              <CardText className="ellipsis-text">
                <span>
                  <AiFillTags />{" "}
                </span>
                {media.category_name}
              </CardText>
              <CardText>
                <span>
                  <AiFillCalendar />{" "}
                </span>
                {moment(media.created_at).format("DD MMMM YY")}
              </CardText>
              <CardText className="ellipsis-text">
                <span>
                  <AiOutlineBarcode />{" "}
                </span>
                {media.isbn}
              </CardText>
            </div>
          </div>
          <div className="media__btn-wrapper">
            {permissions.approve && (
              <div style={{ marginRight: 15 }}>
                <CustomModal
                  btnOutline
                  color="primary"
                  size="sm"
                  title={t(
                    media.is_public
                      ? "MEDIAS.WOULD_YOU_LIKE_TO_UNPUBLIC_THIS_MEDIA"
                      : "MEDIAS.WOULD_YOU_LIKE_TO_PUBLIC_THIS_MEDIA"
                  )}
                  btn={t(media.is_public ? "BUTTON.UNPUBLIC" : "BUTTON.PUBLIC")}
                  message={media.title}
                  modal={modalPublic}
                  loading={isLoadingPublic}
                  toggle={toggleModalPublic}
                  actionCallApi={() => handlePublicMedia(media)}
                />
              </div>
            )}
            {permissions.edit && (
              <Button
                size="sm"
                color="primary"
                onClick={() => viewDetail(media)}
              >
                {t("BUTTON.EDIT")}
              </Button>
            )}
            {permissions.delete && !media.is_public && (
              <CustomModal
                color="danger"
                size="sm"
                title={t("GENERAL.DO_YOU_WANT_TO_DELETE_THIS_RECORD")}
                btn={t("BUTTON.DELETE")}
                message={media.title}
                modal={modal}
                loading={isLoadingDelete}
                toggle={toggle}
                actionCallApi={() => handleDeleteMedia(media)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation("common")(AuthorMedia);
