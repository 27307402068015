/* eslint-disable */
const validate = (values) => {
  const errors = {};
  if (!values.title) {
    errors.title = 'Title shouldn’t be empty';
  }
  if (!values.title_en) {
    errors.title_en = 'Title EN shouldn’t be empty';
  }
  if (!values.description) {
    errors.description = 'Description shouldn’t be empty';
  }
  if (!values.search_keyword) {
  errors.search_keyword = 'Search Keyword shouldn’t be empty';
  }
  if (!values.author) {
    errors.author = "Author shouldn't be empty";
  }
  if (!values.publisher) {
    errors.publisher = "Publisher shouldn't be empty";
  }
  if (!values.publish_year) {
    errors.publish_year = "Publish year shouldn't be empty";
  }
  if (!values.media_cover) {
    errors.media_cover = "Media Cover shouldn't be empty";
  }
  if (!values.media_file) {  //&& !values.media_file.name (recheck, bug when editing media)
    errors.media_file = "Media File shouldn't be empty";
  }
  if(!values.sortkey) {
    errors.sortkey = "Sortkey shouldn't be empty";
  }
  return errors;
};

export default validate;
