/* eslint-disable */
const validate = (values) => {
  const errors = {};
  if (!values.fullname) {
    errors.fullname = 'Fullname shouldn’t be empty';
  }
  if (!values.fullname_en) {
    errors.fullname_en = 'Fullname EN shouldn’t be empty';
  }
  if (!values.description) {
    errors.description = 'Description shouldn’t be empty';
  }
  if (!values.description_en) {
    errors.description_en = 'Description EN shouldn’t be empty';
  }
  if (!values.s3_photo_url) {
    errors.s3_photo_url = "Author profile image shouldn't be empty"
  }
  return errors;
};

export default validate;
