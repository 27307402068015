import React, { PureComponent } from "react";
import { Spinner, Col } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import { withTranslation } from "react-i18next";
import { HiOutlineMail } from "react-icons/hi";
import { Button, Alert } from "reactstrap";
import { NavLink } from "react-router-dom";
import NotificationSystem from "rc-notification";
import { BasicNotification } from "../../../components/Notification";
import firebase from "../../../config/firebase";
import Service from "../../../config/Service";
import { withRouter } from "react-router";

let notification = null;

class VerifyPhoneForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      isUploading: false,
      resendCode: false,
    };
  }

  componentDidMount() {
    NotificationSystem.newInstance(
      { style: { top: 10 } },
      (n) => (notification = n)
    );
  }

  showNotification = (title, message, color) => {
    notification.notice({
      content: (
        <BasicNotification title={title} color={color} message={message} />
      ),
      duration: 5,
      closable: true,
      style: { top: 0, left: "calc(100vw - 100%)" },
      className: `right-up right-support`,
    });
  };

  sendNotification = (smg, color = "danger") => {
    return notification.notice({
      content: (
        <BasicNotification
          title="SMS code message"
          color={color}
          message={smg}
        />
      ),
      duration: 5,
      closable: true,
      style: { top: 0, left: "calc(100vw - 100%)" },
      className: `right-up `,
    });
  };

  onTextInput = (event) => {
    const fieldName = event.target.name;
    const value = event.target.value;
    this.setState({ [fieldName]: value });
  };

  setUpRecaptcha = () => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
      }
    );
  };

  resendComfirmPhoneCode = (event) => {
    event.preventDefault();
    this.setState({ resendCode: true, isUploading: true });
    const { registerData } = this.props;
    this.setUpRecaptcha();

    const appVerifier = window.recaptchaVerifier;
    firebase
      .auth()
      .signInWithPhoneNumber("+855" + parseInt(registerData.phone), appVerifier)
      .then((confirmationResult) => {
        this.setState({ resendCode: false, isUploading: false });
        window.confirmationResult = confirmationResult;
        this.sendNotification("Send phone code successfully.", "success");
      })
      .catch((error) => {
        this.setState({ resendCode: false, isUploading: false });
        this.sendNotification(error.message);
      });
  };

  saveData = (data) => {
    const { history, t } = this.props;

    Service.post("createUser", data)
      .then((response) => {
        if (response.data.success) {
          this.setState({ isUploading: false });
          this.showNotification(
            t("NOTIFICATION.SUBMITTED"),
            t("NOTIFICATION.WAITING_FOR_SUPERADMIN_APPROVE"),
            "primary"
          );
          history.push("login");
        } else {
          this.showNotification(
            t("NOTIFICATION.ERROR"),
            t("NOTIFICATION.ERROR_WHILE_UPLOADING_DATA"),
            "danger"
          );
          this.setState({ isUploading: false });
        }
      })
      .catch(() => {
        this.showNotification(
          t("NOTIFICATION.ERROR"),
          t("NOTIFICATION.ERROR_WHILE_UPLOADING_DATA"),
          "danger"
        );
        this.setState({ isUploading: false });
      });
  };

  checkSMSCode = () => {
    const { registerData, t } = this.props;
    const { code } = this.state;
    if (!code) {
      this.setState({ errorMessage: t("REGISTER.ALL_FIELDS_ARE_REQURED") });
      return;
    }
    this.setState({ isUploading: true });

    window.confirmationResult
      .confirm(code)
      .then((result) => {
        this.saveData(registerData);
      })
      .catch((error) => {
        this.sendNotification(error.message);
        this.setState({
          isUploading: false,
        });
      });
  };

  render() {
    const { t } = this.props;
    const { isUploading, errorMessage } = this.state;
    return (
      <form className="form login-form">
        <Alert color="danger" isOpen={!!errorMessage}>
          {errorMessage}
        </Alert>
        <Col md={12} sm={12}>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {t("REGISTER.CODE_SMS")}
            </span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <HiOutlineMail />
              </div>
              <Field
                name="code"
                component="input"
                type="number"
                required
                placeholder="xxxxxx"
                className="input-without-border-radius"
                onChange={this.onTextInput}
              />
            </div>
            <div
              onClick={(event) => this.resendComfirmPhoneCode(event)}
              className=" mt-3 mb-3 form__form-group"
              style={{ display: "block" }}
            >
              <p style={{ color: "#1183CA", fontSize: 14 }}>
                {t("REGISTER.PLEASE_CHECK_SMS_CODE_IN_MOBILE_PHONE")}
              </p>
              <a
                href=""
                style={{
                  textDecoration: "underline",
                  color: "#CE902C",
                  fontSize: 12,
                }}
              >
                {t("REGISTER.RESEND_SMS_CODE")}
              </a>
            </div>
          </div>
        </Col>
        <Col md={12}>
          <div className="mt-5 d-flex justify-content-center">
            <Col md={6}>
              <Button
                color="primary"
                className="account__btn mb-2"
                onClick={this.checkSMSCode}
              >
                {isUploading ? (
                  <Spinner size="sm" animation="border" varaint="primary" />
                ) : (
                  t("REGISTER.SIGN_UP")
                )}
              </Button>
              <div className="text-center">
                <p>
                  {t("REGISTER.ALREADY_HAVE_ACCOUNT")}{" "}
                  <NavLink to="/log_in">{t("LOGIN.SIGN_IN")}</NavLink>
                </p>
              </div>
            </Col>
          </div>
        </Col>
        <div id="recaptcha-container" />
      </form>
    );
  }
}

export default reduxForm({
  form: "register_form",
})(withRouter(withTranslation("common")(VerifyPhoneForm)));
