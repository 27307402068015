import {
  TYPE_GET_CATEGORY,
  TYPE_SET_ACTION_HISTORY,
  TYPE_COMPONENT_VIEW
} from '../actions/storeActions';

const initialState = {
  MEDIA_DATA: {},
  CATEGORY_DATA: {},
  COMPONENT_VIEW: ""
};

export default function storeReducers(state = initialState, action) {

  switch (action.type) {
    case TYPE_GET_CATEGORY:
      return {
        ...state,
        CATEGORY_DATA: action.CATEGORY_DATA,
      };
    case TYPE_SET_ACTION_HISTORY:
      return {
        ...state,
        loading: false,
        CLICK_HISTORY_DATA: action.HISTORY_DATA,
      };
    case TYPE_COMPONENT_VIEW:
      return {
        ...state,
        COMPONENT_VIEW: action.value,
      };
    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}