import React, { Component } from 'react';
import { CardBody, CardTitle, Col, Card, CardText } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { HiLockClosed } from 'react-icons/hi';

class Index extends Component {
  constructor() {
    super();
    this.state = {
    };
  }

  render() {
    const { t } = this.props;
    return (
      <Col md={12} xl={12} sm={12} >
        <Card>
          <CardBody style={{ height: '660px', display: 'flex', justifyContent: 'center' }}>
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
              <HiLockClosed color="darkgrey" size={55} />
              <CardTitle style={{ fontSize: '18px', color: 'darkgrey', paddingTop: '10px', margin: 0 }}>{t('PERMISSION.PERMISSION_DENY')}</CardTitle>
              <CardText style={{ fontSize: '14px', color: 'darkgrey', margin: 0 }}>{t('PERMISSION.NO_PERMISSION_TO_OPEN')}</CardText>
            </div>
          </CardBody>
        </Card>
      </Col>
    )
  }
}

export default withTranslation('common')(Index);