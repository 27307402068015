import React from "react";
import PropTypes from "prop-types";

const RenderFileInputField = ({ onChange, name, value, label, accept }) => {
  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.files.length > 0) {
      const files = [...e.target.files];
      onChange({ file: files[0], name: files[0].name });
    } else {
      onChange({});
    }
  };
  return (
    <div>
      <label htmlFor={name} />
      <input
        style={{ padding: 7, height: 34 }}
        type="file"
        // accept="audio/*,video/*,.pdf,.epub"
        accept={accept}
        name={name}
        id={name}
        onChange={handleChange}
      />
    </div>
  );
};

RenderFileInputField.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.shape({
      name: PropTypes.string,
    }),
    PropTypes.string,
  ]),
};

RenderFileInputField.defaultProps = {
  value: null,
};

const renderFileInputField = ({ input, meta, accept }) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <RenderFileInputField {...input} accept={accept} />
    {meta.touched && meta.error && (
      <span className="form__form-group-error">{meta.error}</span>
    )}
  </div>
);

renderFileInputField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderFileInputField.defaultProps = {
  meta: null,
};

export default renderFileInputField;
