import React, { PureComponent } from "react";
import { Alert, Button, Spinner, Col } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import PhoneIcon from "mdi-react/PhoneIcon";
import { withTranslation } from "react-i18next";
import firebase from "../../../config/firebase";
import VerifyPhoneForm from "./VerifyPhoneForm";
import Service from "../../../config/Service";

class PhoneInputForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      showInputSMSCode: false,
      resetPasswordData: {},
      isUploading: false,
    };
    props.initialize();
  }

  setUpRecaptcha = () => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "captchaid001",
      {
        size: "invisible",
      }
    );
  };

  onTextChange = (event) => {
    const fieldName = event.target.name;
    const resetPasswordData = { ...this.state.resetPasswordData };
    resetPasswordData[fieldName] = event.target.value;

    this.setState({ resetPasswordData });
  };

  sendCodeSMS = (resetPasswordData) => {
    this.setUpRecaptcha();
    this.setState({ isUploading: true });
    const appVerifier = window.recaptchaVerifier;
    firebase
      .auth()
      .signInWithPhoneNumber(
        "+855" + parseInt(resetPasswordData.phone),
        appVerifier
      )
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        this.setState({
          showInputSMSCode: true,
          isUploading: false,
          errorMessage: "",
        });
      })
      .catch((error) => {
        this.setState({
          isUploading: false,
          showInputSMSCode: false,
          errorMessage: error.message,
        });
      });
  };

  verifyPhoneNumber = () => {
    const { resetPasswordData } = this.state;
    const { t } = this.props;
    if (!resetPasswordData.phone) {
      this.setState({
        errorMessage: t("FORGET_PASSWORD.PHONE_NUMBER_REQUIRED"),
      });
      return;
    }
    this.setState({
      errorMessage: "",
    });
    this.checkExistingPhone();
  };

  checkExistingPhone = () => {
    const { resetPasswordData } = this.state;
    console.log("resetPasswordData =>", resetPasswordData);
    Service.post("user/check-existing-phone", resetPasswordData).then(
      (response) => {
        console.log("response check phone =>", response);
        if (response.data.success) {
          this.setState({ errorMessage: "" });
          this.sendCodeSMS(resetPasswordData);
        } else {
          this.setState({
            errorMessage: response.data.message,
          });
        }
      }
    );
  };

  render() {
    const { t } = this.props;
    const {
      isUploading,
      errorMessage,
      showInputSMSCode,
      resetPasswordData,
    } = this.state;
    return (
      <Col md={12}>
        {!showInputSMSCode && (
          <form className="form login-form">
            <Alert color="danger" isOpen={!!errorMessage}>
              {errorMessage}
            </Alert>
            <div className="form__form-group">
              <div>
                <span className="form__form-group-label">
                  {t("INFO_FIELDS.PHONE")}
                  <span className="red-text">*</span>
                </span>
              </div>
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <PhoneIcon />
                </div>
                <Field
                  name="phone"
                  component="input"
                  type="number"
                  placeholder="xxxxxxxxx"
                  className="input-without-border-radius"
                  onChange={this.onTextChange}
                />
              </div>
            </div>
            <Button
              className="account__btn"
              color="primary"
              onClick={this.verifyPhoneNumber}
            >
              {isUploading ? (
                <Spinner size="sm" animation="border" varaint="primary" />
              ) : (
                t("FORGET_PASSWORD.VERIFY_PHONE_NUMBER")
              )}
            </Button>
            <div id="captchaid001" />
          </form>
        )}
        {showInputSMSCode && (
          <VerifyPhoneForm resetPasswordData={resetPasswordData} />
        )}
      </Col>
    );
  }
}

export default reduxForm({
  form: "reset_password_form",
})(withTranslation("common")(PhoneInputForm));
