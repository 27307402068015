export default function authHeader() {
  const user = JSON.parse(localStorage.getItem('user'));
  if (user) {
    if (user.token) {
      return {
        Authorization: 'Bearer ' + user.token,
        APPKEY: "L2tPsd3PJ26RQwuaQvNVyhabWqVcdE"
      };
    } else {
      return {
        APPKEY: "L2tPsd3PJ26RQwuaQvNVyhabWqVcdE"
      };
    }
  }
  else {
    return {
      APPKEY: "L2tPsd3PJ26RQwuaQvNVyhabWqVcdE"
    };
  }

}
