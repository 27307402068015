import * as firebase from "firebase";
const firebaseConfig = {
  apiKey: "AIzaSyDnaUldetlz9dppqKBgGZ2Nl9A-JjuRoac",
  authDomain: "d-library-d756a.firebaseapp.com",
  projectId: "d-library-d756a",
  storageBucket: "d-library-d756a.appspot.com",
  messagingSenderId: "10420933918",
  appId: "1:10420933918:web:0ee8a7834f567a951e650f",
  measurementId: "G-SJ6BFGTMKD"
};

firebase.initializeApp(firebaseConfig);

export default firebase;
