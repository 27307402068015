import React, { PureComponent } from "react";
import { Card, CardBody, Col, Button, Modal } from "reactstrap";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import PlusIcon from "mdi-react/PlusIcon";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import {
  fetchActionComboAuthors,
  // fetchActionComboPublishyears,
  fetchActionComboPublishers,
  fetchActionCategoryTree,
} from "../../../redux/actions/fetchApiActions";
import renderSelectField from "../../../components/form/Select";
import { BsChevronRight } from "react-icons/bs";
import Service from "../../../config/Service";
import CategoryTree from "./CategoryTree";
import _ from "lodash";

const searchParams = "page=1&perPage=200000";
class FilterInput extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      categoryTree: props.categoryTree,
      selectedCategory: "",
      isToggleModal: false,
      itemsPerPage: 12,
      filterData: {},
    };
  }

  async componentDidMount() {
    await this.getAllAuthors();
    // await this.getAllPublishyears();
    await this.getPublisher();
    await this.getCategoryTree();
    await this.addPrefillInput();
  }

  addPrefillInput = async () => {
    const {
      categoryTree,
      allComboAuthors,
      // allComboPublishyears,
      allComboPublishers,
      filterValue,
    } = this.props;
    if (!_.isEmpty(filterValue)) {
      let categorySelect = [];

      if (filterValue && filterValue.category) {
        _.forEach(categoryTree, (category) => {
          if (category.id === filterValue.category) {
            categorySelect.push(category);
          } else {
            if (category.child_data) {
              _.forEach(category.child_data, (child) => {
                if (child.id === filterValue.category) {
                  categorySelect.push(child);
                }
              });
            }
          }
        });
      }

      this.setState({
        filterData: filterValue,
        selectedCategory:
          categorySelect.length > 0 ? categorySelect[0].text : "",
      });
      const indexAuthor = allComboAuthors.findIndex(
        (author) => author.value === filterValue.author
      );
      // const indexYear = allComboPublishyears.findIndex(
      //   (year) => year.value === filterValue.publish_year
      // );
      const indexPublisher = allComboPublishers.findIndex(
        (publisher) => publisher.value === filterValue.publisher
      );

      const data = {
        author: allComboAuthors[indexAuthor > -1 ? indexAuthor : 0],
        // publish_year: allComboPublishyears[indexYear > -1 ? indexYear : 0],
        publish_year: filterValue.publish_year,
        publisher: allComboPublishers[indexPublisher > -1 ? indexPublisher : 0],
        id: filterValue.id,
        title: filterValue.title,
        title_en: filterValue.title_en,
        isbn: filterValue.isbn,
      };
      await this.props.initialize(data);
    }
  };

  getAllAuthors = () => {
    const { dispatch } = this.props;
    let option_authors = [];
    Service.getSearch("authors", searchParams)
      .then((response) => {
        const entries = response.data.data;
        // option_authors.push({ value: "", label: "---" });
        for (var i = 0; i < entries.length; i++) {
          option_authors.push({
            value: entries[i].id,
            label: entries[i].fullname,
          });
        }
        dispatch(fetchActionComboAuthors(option_authors));
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // getAllPublishyears = () => {
  //   const { dispatch } = this.props;
  //   let option_publishyears = [];
  //   Service.getSearch("publishyears", searchParams)
  //     .then((response) => {
  //       const entries = response.data.data;
  //       // option_publishyears.push({ value: "", label: "---" });
  //       for (var i = 0; i < entries.length; i++) {
  //         option_publishyears.push({
  //           value: entries[i].id,
  //           label: entries[i].name,
  //         });
  //       }
  //       dispatch(fetchActionComboPublishyears(option_publishyears));
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // };

  getPublisher = () => {
    const { dispatch } = this.props;
    let option_publishers = [];
    Service.getSearch("publishers", searchParams)
      .then((response) => {
        const entries = response.data.data;
        // option_publishers.push({ value: "", label: "---" });
        for (var i = 0; i < entries.length; i++) {
          option_publishers.push({
            value: entries[i].id,
            label: entries[i].name,
          });
        }
        dispatch(fetchActionComboPublishers(option_publishers));
      })
      .catch((e) => {
        console.log(e);
      });
  };

  getCategoryTree = () => {
    const { dispatch } = this.props;
    Service.getAll("getCategoryTree")
      .then((response) => {
        if (response) {
          dispatch(fetchActionCategoryTree(response.data));
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  toggleCreate = () => {
    const { history } = this.props;
    history.push("/module/medias/create");
  };

  onToogleModal = () => {
    const { isToggleModal } = this.state;
    this.setState({
      isToggleModal: !isToggleModal,
    });
  };

  onCancelSelectedTree = () => {
    const { isToggleModal } = this.state;
    const { categoryTree } = this.props;
    this.setState({
      isToggleModal: !isToggleModal,
      selectedCategory: "",
      categoryTree,
    });
  };

  onChangeCategory = (node) => {
    const fieldName = "category";
    const filterData = { ...this.state.filterData };
    filterData[fieldName] = node.id;
    this.setState({ filterData, selectedCategory: node.text });
  };

  onConfirmSelectedTree = () => {
    const { isToggleModal, selectedCategory } = this.state;
    const { categoryTree } = this.props;
    this.setState({
      isToggleModal: !isToggleModal,
      selectedCategory,
      categoryTree,
    });
  };

  onTextChange = (event) => {
    const fieldName = event.target.name;
    const filterData = { ...this.state.filterData };
    filterData[fieldName] = event.target.value;

    this.setState({ filterData });
  };

  onChangeSelect = (val, key) => {
    const fieldName = key;
    const filterData = { ...this.state.filterData };
    filterData[fieldName] = val.value;
    this.setState({ filterData });
  };

  handleSearchCategory = (data) => {
    const params = `name=${data.name}&name_en=${data.name_en}&id=${data.id}`;
    Service.getSearch("user/searchCategory", params).then((response) => {
      if (response.status === 200) {
        const data = response.data;
        this.setState({
          categoryTree: data,
        });
      }
    });
  };

  render() {
    const {
      t,
      allComboAuthors,
      // allComboPublishyears,
      allComboPublishers,
      handleFilter,
      permissions,
    } = this.props;
    const { selectedCategory, isToggleModal, filterData, categoryTree } =
      this.state;
    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <form className="form form--vertical">
              <Col md={6} sm={12}>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    {t("GENERAL.ID")}
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      name="id"
                      component="input"
                      type="text"
                      onChange={this.onTextChange}
                    />
                  </div>
                </div>

                <div className="form__form-group">
                  <span className="form__form-group-label">
                    {t("GENERAL.TITLE")}
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      name="title"
                      component="input"
                      type="text"
                      onChange={this.onTextChange}
                    />
                  </div>
                </div>

                <div className="form__form-group">
                  <span className="form__form-group-label">
                    {t("GENERAL.AUTHOR")}
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      name="author"
                      component={renderSelectField}
                      options={allComboAuthors}
                      onChange={(val) => this.onChangeSelect(val, "author")}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    {t("GENERAL.PUBLISH_YEAR")}
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      name="publish_year"
                      component="input"
                      type="number"
                      onChange={this.onTextChange}
                    // options={allComboPublishyears}
                    // onChange={(val) =>
                    //   this.onChangeSelect(val, "publish_year")
                    // }
                    />
                  </div>
                </div>
              </Col>

              <Col md={6} sm={12}>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    {t("GENERAL.ISBN")}
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      name="isbn"
                      component="input"
                      type="text"
                      onChange={this.onTextChange}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    {t("GENERAL.TITLE_EN")}
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      name="title_en"
                      component="input"
                      type="text"
                      onChange={this.onTextChange}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    {t("GENERAL.PUBLISHER")}
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      name="publisher"
                      component={renderSelectField}
                      options={allComboPublishers}
                      onChange={(val) => this.onChangeSelect(val, "publisher")}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    {t("GENERAL.CATEGORY")}
                  </span>
                  <div className="media__category">
                    <div
                      onClick={this.onToogleModal}
                      className="media__category--toggle-view"
                    >
                      <div className="d-flex__space-between">
                        <span style={{ color: "#646777" }}>
                          {selectedCategory}
                        </span>
                        <BsChevronRight color="#dddddd" />
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={12}>
                <div className="d-flex justify-content-end">
                  <Button
                    color="primary"
                    onClick={() => handleFilter(filterData)}
                  >
                    {t("GENERAL.FILTER")}
                  </Button>
                  {permissions.create && (
                    <Button
                      className="icon"
                      onClick={this.toggleCreate}
                      color="success"
                    >
                      <p>
                        <PlusIcon /> {t("GENERAL.CREATE_A_NEW")}
                      </p>
                    </Button>
                  )}
                </div>
              </Col>
              <Modal
                isOpen={isToggleModal}
                toggle={this.onToogleModal}
                style={{ padding: 0 }}
                className="todo__add-modal theme-light ltr-support"
              >
                <div className="card__title">
                  <h5 className="bold-text">
                    {t("MEDIAS.MEDIA_CATEGORY_MODAL_TITLE")}
                  </h5>
                  <h5 className="subhead">
                    {t("MEDIAS.MEDIA_CATEGORY_MODAL_SUBTITLE")}
                  </h5>
                </div>
                {categoryTree && !_.isEmpty(categoryTree) && (
                  <div className="media__category">
                    <div className="category-modal-content">
                      <CategoryTree
                        categoryTree={categoryTree}
                        onChangeCategory={this.onChangeCategory}
                        onSearchCategory={this.handleSearchCategory}
                        onCancelSelectedTree={this.onCancelSelectedTree}
                        onConfirmSelectedTree={this.onConfirmSelectedTree}
                      />
                    </div>
                  </div>
                )}
              </Modal>
            </form>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

FilterInput = reduxForm({
  form: "filter_media_form",
})(FilterInput);

function mapStateToProps(state) {
  return {
    theme: state.theme,
    rtl: state.rtl,
    allComboAuthors: state.fetchData.FETCH_COMBO_AUTHORS,
    // allComboPublishyears: state.fetchData.FETCH_COMBO_PUBLISHYEARS,
    allComboPublishers: state.fetchData.FETCH_COMBO_PUBLISHERS,
    categoryTree: state.fetchData.FETCH_CATEGORY_TREE,
  };
}

export default connect(mapStateToProps)(
  withRouter(withTranslation("common")(FilterInput))
);
