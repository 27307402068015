import React, { PureComponent } from "react";
import { Col, Button, Row, Spinner, Table, Alert } from "reactstrap";
import { Field, reduxForm, Form } from "redux-form";
import { withTranslation } from "react-i18next";
import NotificationSystem from "rc-notification";
import renderSelectField from "../../../components/form/Select";
import { BasicNotification } from "../../../components/Notification";
import renderCheckBoxField from "../../../components/form/CheckBox";
import chooseProfileImage from "../../../components/ChooseImage/RoundedChooseImage";
import { RenderInputField } from "../../../components/RenderField";
import Service from "../../../config/Service";
import validate from "./validate";
import Utilities from "../../../shared/helpers/Utilities";
import { connect } from "react-redux";
import _ from "lodash";

let notification = null;
const isTrue = 1;
const isFalse = 0;

class AddUser extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isUploading: false,
      userRoleId: {},
      userProfile: {},
      itemsPerPage: 12,
      isShowManagePermission: false,
      responseUserId: 0,
      currentRowKey: "",
      allModule: [],
      error: false,
      errorMessage: "",
      success: false,
      successMessage: "",
    };
  }

  componentDidMount() {
    NotificationSystem.newInstance(
      { style: { top: 65 } },
      (n) => (notification = n)
    );
    this.props.initialize();
  }

  showNotification = (rtl) => {
    const { t } = this.props;
    notification.notice({
      content: (
        <BasicNotification
          title={t("GENERAL.NOTIFICATION")}
          color="primary"
          message={t("GENERAL.THE_ACTION_HAS_BEEN_COMPLETED_SUCCESSFULLY")}
        />
      ),
      duration: 5,
      closable: true,
      style: { top: 0, left: "calc(100vw - 100%)" },
      className: `right-up ${rtl}-support`,
    });
  };

  onChooseImage = (event) => {
    const fieldName = "s3_photo_url";
    const userProfile = { ...this.state.userProfile };
    userProfile[fieldName] = event;
    this.setState({ userProfile });
  };
  actionSubmit = (value) => {
    const { userRoleId, userProfile } = this.state;
    const data = {
      fullname: value.fullname,
      fullname_en: value.fullname_en,
      email: value.email,
      phone: value.phone,
      password: value.password,
      role_id: userRoleId.value,
    };

    const formData = new FormData();
    for (const [key, value] of Object.entries(data)) {
      formData.append(key, value);
    }
    if (userProfile.s3_photo_url && userProfile.s3_photo_url.name) {
      formData.append("s3_photo_url", userProfile.s3_photo_url.file);
    }
    this.saveUserInfo(formData);
  };

  saveUserInfo = (data) => {
    const { userRoleId } = this.state;
    const { refreshAfterCreate, t } = this.props;
    this.setState({ isUploading: true });
    Service.post("users", data)
      .then((response) => {
        if (response.data.success) {
          refreshAfterCreate();
          this.setState({
            errorMessage: "",
            error: false,
            success: true,
            successMessage:
              userRoleId.value !== "3"
                ? t("SYSTEM_USER.USER_REGISTER_WAITING_FOR_SUPERADMIN_APPROVE")
                : t("SYSTEM_USER.USER_REGISTER_SUCCESSFULLY"),
            isUploading: false,
            isShowManagePermission: true,
            responseUserId: response.data.data.id,
            allModule:
              userRoleId.value !== "3"
                ? response.data.data.action_controls
                : [],
          });
          this.getUserPermissionActions();
        } else {
          this.setState({
            isUploading: false,
            error: true,
            success: false,
            successMessage: "",
            errorMessage: response.data.message,
          });
        }
      })
      .catch((e) => {
        this.setState({
          isUploading: false,
          error: true,
          errorMessage: "Error",
          success: false,
          successMessage: "",
        });
        console.log(e);
      });
  };

  getUserPermissionActions = () => {
    const { allModule } = this.state;
    if (!_.isEmpty(allModule)) {
      const newModule = allModule.map((module) => {
        module.disabledSave = true;
        module.control_modules.map((action) => {
          if (action.action_type === "VIEW") {
            action.disabled = false;
          } else {
            action.disabled =
              module.control_modules[0].action_type === "VIEW" &&
              !module.control_modules[0].is_checked;
          }
          return action;
        });
        return module;
      });
      this.setState({
        allModule: newModule,
      });
    }
  };

  onChangeUserRole = (data) => {
    this.setState({ userRoleId: data });
  };

  onCheckbox = (e, itemMain, indexMain, actionItem, actionIndex) => {
    if (e.currentTarget) {
      const { allModule } = this.state;
      const newModule = allModule.map((item, index) => {
        if (index === indexMain) {
          item.control_modules.map((action, subIndex) => {
            if (subIndex === actionIndex) {
              action.disabled = false;
              action.is_checked = !action.is_checked;
            } else {
              action.disabled =
                actionItem.action_type === "VIEW" && !actionItem.is_checked;
              action.is_checked =
                actionItem.action_type === "VIEW" && !actionItem.is_checked
                  ? actionItem.is_checked
                  : action.is_checked;
            }
            return action;
          });
          item.disabledSave = false;
          item.disabledSave =
            actionItem.action_type === "VIEW" && !actionItem.is_checked;
        } else {
          item.disabledSave = true;
        }
        return item;
      });
      this.setState({
        allModule: newModule,
        currentRowKey: itemMain.module_key,
      });
    }
  };

  onSaveCheckAction = (item) => {
    const { responseUserId } = this.state;
    let data = {};
    item.control_modules.map((action) => {
      data.module_key = item.module_key;
      data[`action_${action.action_type.toLowerCase()}`] = action.is_checked
        ? isTrue
        : isFalse;
      data.user_id = responseUserId;
      return action;
    });
    this.setState({ currentRowKey: item.module_key, isUploading: true });
    Service.post("user/setUserModuleControl", data)
      .then((response) => {
        if (response) {
          this.setState({ isUploading: false });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  render() {
    const { t, handleSubmit, onCancel, pristine } = this.props;
    const {
      isUploading,
      userRoleId,
      allModule,
      isShowManagePermission,
      currentRowKey,
      error,
      errorMessage,
      success,
      successMessage,
    } = this.state;
    const roleOptions = Utilities.adminRolesOptions(t);

    return (
      <Col md={12}>
        <Row>
          <Col md={12}>
            <h3 className="page-title">{t("GENERAL.CREATE_NEW_USER")}</h3>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="d-flex justify-content-center mb-3">
              <Field
                name="s3_photo_url"
                component={chooseProfileImage}
                onChange={this.onChooseImage}
              />
            </div>
            <Form
              className="form login-form"
              onSubmit={handleSubmit(this.actionSubmit)}
            >
              <Alert color="primary" isOpen={success} className="ml-3 mr-3">
                {successMessage}
              </Alert>
              <Alert color="danger" isOpen={error} className="ml-3 mr-3">
                {errorMessage}
              </Alert>
              <Col md={6} sm={12}>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    {t("GENERAL.FULLNAME")}
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      name="fullname"
                      component={RenderInputField}
                      type="text"
                      placeholder={t("GENERAL.FULLNAME")}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    {t("GENERAL.EMAIL")}
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      name="email"
                      component={RenderInputField}
                      type="text"
                      placeholder={t("GENERAL.EMAIL")}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    {t("GENERAL.PASSWORD")}
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      name="password"
                      component={RenderInputField}
                      type="text"
                      placeholder={t("GENERAL.PASSWORD")}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    {t("GENERAL.USER_ROLE")}
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      name="role_id"
                      component={renderSelectField}
                      options={roleOptions}
                      onChange={this.onChangeUserRole}
                    />
                  </div>
                </div>
              </Col>

              <Col md={6} sm={12}>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    {t("GENERAL.FULLNAME_EN")}
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      name="fullname_en"
                      component={RenderInputField}
                      type="text"
                      placeholder={t("GENERAL.FULLNAME_EN")}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    {t("GENERAL.PHONE")}
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      name="phone"
                      component={RenderInputField}
                      type="text"
                      placeholder={t("GENERAL.PHONE")}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    {t("GENERAL.CONFIRM_PASSWORD")}
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      name="confirm_password"
                      component={RenderInputField}
                      type="text"
                      placeholder={t("GENERAL.CONFIRM_PASSWORD")}
                    />
                  </div>
                </div>
              </Col>
              {isShowManagePermission && userRoleId.value !== "3" && (
                <Col md={12} sm={12}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {`${t("SIDE_BAR.PERMISSIONS")}:`}
                    </span>
                    <Table className="table--bordered">
                      <thead>
                        <tr>
                          <th>Module Name</th>
                          <th className="text-center">Actions</th>
                          <th>Save</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allModule.map((item, indexMain) => (
                          <tr key={indexMain.toString()}>
                            <td>{t(`SIDE_BAR.${item.module_name}`)}</td>
                            <td>
                              <div className="row form__form-group-field">
                                {item.control_modules.map((action, index) => (
                                  <div className="p-2" key={index.toString()}>
                                    <Field
                                      name={`${item.module_name}_action_${action.action_type}`}
                                      component={renderCheckBoxField}
                                      onChange={(e) =>
                                        this.onCheckbox(
                                          e,
                                          item,
                                          indexMain,
                                          action,
                                          index
                                        )
                                      }
                                      defaultChecked={action.is_checked}
                                      label={t(
                                        `ACTION_CONTROL.${action.action_type}`
                                      )}
                                      disabled={action.disabled}
                                      // checked={action.is_checked}
                                    />
                                  </div>
                                ))}
                              </div>
                            </td>
                            <td>
                              <Button
                                color="primary"
                                outline
                                size="sm"
                                disabled={isUploading || item.disabledSave}
                                style={{ padding: "3px 8px" }}
                                onClick={() => this.onSaveCheckAction(item)}
                              >
                                {isUploading &&
                                currentRowKey == item.module_key ? (
                                  <Spinner size="sm" />
                                ) : (
                                  t("GENERAL.SAVE")
                                )}
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              )}

              <Col md={12}>
                <div className="d-flex justify-content-end">
                  {!isShowManagePermission && (
                    <Button
                      color="primary"
                      type="submit"
                      disabled={isUploading || pristine}
                    >
                      {isUploading ? (
                        <Spinner
                          animation="border"
                          variant="primary"
                          size="sm"
                        />
                      ) : (
                        t("GENERAL.SAVE")
                      )}
                    </Button>
                  )}
                  <Button
                    type="button"
                    onClick={onCancel}
                    disabled={isUploading}
                  >
                    {t("GENERAL.BACK")}
                  </Button>
                </div>
              </Col>
            </Form>
          </Col>
        </Row>
      </Col>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.data,
  };
};

AddUser = reduxForm({
  shouldValidate: () => true,
  form: "add_system-user_form",
  validate,
  // enableReinitialize: true,
  // destroyOnUnmount: false,
  // keepDirtyOnReinitialize: true,
})(AddUser);

export default connect(mapStateToProps)(withTranslation("common")(AddUser));
