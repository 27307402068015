import React, { PureComponent } from "react";
import { Col, Button, Row, Spinner, Modal } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import { withTranslation } from "react-i18next";
import NotificationSystem from "rc-notification";
import { BasicNotification } from "../../../components/Notification";
// import { fetchActionPublishers } from "../../../redux/actions/fetchApiActions";
import Service from "../../../config/Service";
import { RenderInputField } from "../../../components/RenderField";
import validate from "./validate";

let notification = null;
class AddForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isUploading: false,
    };
  }

  componentDidMount() {
    NotificationSystem.newInstance(
      { style: { top: 65 } },
      (n) => (notification = n)
    );
    this.props.initialize();
  }

  showNotification = (rtl) => {
    const { t } = this.props;
    notification.notice({
      content: (
        <BasicNotification
          title={t("GENERAL.NOTIFICATION")}
          color="primary"
          message={t("GENERAL.THE_ACTION_HAS_BEEN_COMPLETED_SUCCESSFULLY")}
        />
      ),
      duration: 5,
      closable: true,
      style: { top: 0, left: "calc(100vw - 100%)" },
      className: `right-up ${rtl}-support`,
    });
  };

  actionSubmit = (value) => {
    const { refreshAfterCreate } = this.props;
    let name = value.name;
    let name_en = value.name_en;
    const data = {
      name: name,
      name_en: name_en,
    };

    this.setState({ isUploading: true });
    Service.post("publishers", data)
      .then((response) => {
        if (response) {
          refreshAfterCreate();
          this.showNotification();
          this.props.toggleModal();
          this.setState({ isUploading: false });
          this.props.initialize();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  onCancel = () => {
    this.props.toggleModal();
    this.props.initialize();
  };

  render() {
    const { t, handleSubmit, pristine, isOpen } = this.props;
    const { isUploading } = this.state;

    return (
      <Modal
        isOpen={isOpen}
        className="todo__add-modal theme-light ltr-support"
      >
        <Col md={12}>
          <Row>
            <Col md={12}>
              <h3 className="page-title">
                {t("GENERAL.CREATE_NEW_PUBLISHER")}
              </h3>
            </Col>
          </Row>
          <form className="form" onSubmit={handleSubmit(this.actionSubmit)}>
            <Col md={12}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {t("GENERAL.NAME")}
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="name"
                    component={RenderInputField}
                    type="text"
                    placeholder={t("GENERAL.NAME")}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {t("GENERAL.NAME_EN")}
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="name_en"
                    component={RenderInputField}
                    type="text"
                    placeholder={t("GENERAL.NAME_EN")}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <Button
                  color="primary"
                  type="submit"
                  disabled={pristine || isUploading}
                >
                  {isUploading ? (
                    <Spinner animation="border" varaint="primary" size="sm" />
                  ) : (
                    t("GENERAL.SAVE")
                  )}
                </Button>
                <Button
                  type="button"
                  onClick={this.onCancel}
                  disabled={isUploading}
                >
                  {t("GENERAL.BACK")}
                </Button>
              </div>
            </Col>
          </form>
        </Col>
      </Modal>
    );
  }
}

AddForm = reduxForm({
  shouldValidate: () => true,
  form: "add_publisher_form",
  validate,
  enableReinitialize: true,
  destroyOnUnmount: false,
  keepDirtyOnReinitialize: true,
})(AddForm);

export default withTranslation("common")(AddForm);
