import React from "react";
import PropTypes from "prop-types";
import { BsCardImage } from "react-icons/bs";
import _ from "lodash";
import Resizer from "react-image-file-resizer";

let previewImage = null;
let tempImgFile = null;

const resizeFile = (file) => new Promise(resolve => {
  Resizer.imageFileResizer(file, 852, 480, 'JPEG', 80, 0,
  uri => {
    resolve(uri);
  },
  'file'
  );
});
const RoundedChooseImage = ({ onChange, name, value }) => {
  if (!_.isObject(value)) {
    previewImage = value;
  }
  const handleChange = async (e) => {
    e.preventDefault();
    const imgLength = e.target.files.length;
    const files = [...e.target.files];
    tempImgFile = imgLength === 1 ? files : tempImgFile;
    const file = tempImgFile[0];
    const compressImage = await resizeFile(file);
    previewImage = URL.createObjectURL(file);
    onChange({
      file: compressImage,
      name: file.name,
    });
  };
  return (
    <div className="create-author">
      <div className="form__form-group-file">
        <label className="create-author__label-input" htmlFor={name}>
          {previewImage == null && _.isEmpty(value) ? (
            <div className="create-author_profile">
              <BsCardImage htmlFor={name} className="create-author__icon" />
            </div>
          ) : (
            <div>
              <div className="create-author_profile">
                <BsCardImage
                  color="white"
                  htmlFor={name}
                  className="create-author__icon"
                />
              </div>
              <img
                className="create-author__preview-image"
                src={!_.isNull(previewImage) ? previewImage : value}
                alt=""
              />
            </div>
          )}
        </label>
        <input
          type="file"
          accept="image/*"
          name={name}
          id={name}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

RoundedChooseImage.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.shape({
      name: PropTypes.string,
    }),
    PropTypes.string,
  ]),
};

RoundedChooseImage.defaultProps = {
  value: null,
};

const renderFileInputField = ({ input, meta }) => (
  <div className="form__form-group-input-wrap">
    <RoundedChooseImage {...input} />
    {meta.touched && meta.error && (
      <span className="form__form-group-error">{meta.error}</span>
    )}
  </div>
);

renderFileInputField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderFileInputField.defaultProps = {
  meta: null,
};

export default renderFileInputField;
