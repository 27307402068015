import React, { PureComponent } from "react";
import {
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Spinner,
  Button,
} from "reactstrap";
import { reduxForm } from "redux-form";
import { withTranslation } from "react-i18next";
import NotificationSystem from "rc-notification";
import { fetchActionAuthors } from "../../../redux/actions/fetchApiActions";
import { BasicNotification } from "../../../components/Notification";
import { connect } from "react-redux";
import validate from "./validate";
import Service from "../../../config/Service";
import { withRouter } from "react-router";
import classnames from "classnames";
import Utilities from "../../../shared/helpers/Utilities";
import AuthorInfo from "./AuthorInfo";
import AuthorMedia from "./AuthorMedia";
import _ from "lodash";

let notification = null;

class AuthorDetail extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      authorInfo: {},
      isUploading: false,
      itemsPerPage: 12,
      selectedAuthor: {},
      activeTab: "author_info",
      mediaList: [],
      permissionsMedia: null,
      isLoadingDelete: false,
      isPublicMedia: true,
      isLoadingPublic: false,
    };
  }

  async componentDidMount() {
    await this.getUserPermission();

    NotificationSystem.newInstance(
      { style: { top: 65 } },
      (n) => (notification = n)
    );
    const { history } = this.props;
    const {
      location: { state },
    } = history;

    const author = state && state.selectedAuthor;
    await this.props.initialize(author);
    await this.setState({
      selectedAuthor: author,
      activeTab: (state && state.activeTab) || "author_info",
    });
    await this.getMediaList(author.id);
  }

  getUserPermission = () => {
    const permissionsMedia = Utilities.getPermission(this.props.user, "medias");
    this.setState({
      permissionsMedia,
    });
  };

  showNotification = (rtl) => {
    const { t } = this.props;
    notification.notice({
      content: (
        <BasicNotification
          title={t("GENERAL.NOTIFICATION")}
          color="primary"
          message={t("GENERAL.THE_ACTION_HAS_BEEN_COMPLETED_SUCCESSFULLY")}
        />
      ),
      duration: 5,
      closable: true,
      style: { top: 0, left: "calc(100vw - 100%)" },
      className: `right-up ${rtl}-support`,
    });
  };

  getMediaList = (authorId) => {
    const { isPublicMedia } = this.state;
    const searchParams = `perPage=500&author=${authorId}&is_public=${isPublicMedia}`;

    Service.getSearch("searchAllMedias", searchParams)
      .then((response) => {
        if (response.data.success) {
          this.setState({
            mediaList: response.data,
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  onTextInput = (event) => {
    const fieldName = event.target.name;
    const authorInfo = { ...this.state.authorInfo };
    authorInfo[fieldName] = event.target.value;
    this.setState({ authorInfo });
  };

  onEditAuthor = () => {
    const { itemsPerPage, authorInfo, selectedAuthor } = this.state;
    const { dispatch, history } = this.props;
    const {
      location: { state },
    } = history;
    const pageOfItems = state && state.pageOfItems;
    let filterParams = `perPage=${itemsPerPage}&page=${pageOfItems}`;

    this.setState({ isUploading: true });
    const data = {
      id: selectedAuthor.id,
      fullname: authorInfo.fullname || selectedAuthor.fullname,
      fullname_en: authorInfo.fullname_en || selectedAuthor.fullname_en,
      description: authorInfo.description || selectedAuthor.description,
      description_en:
        authorInfo.description_en || selectedAuthor.description_en,
    };

    const formData = new FormData();
    for (const [key, value] of Object.entries(data)) {
      formData.append(key, value);
    }

    if (authorInfo.s3_photo_url && authorInfo.s3_photo_url.name) {
      formData.append("s3_photo_file", authorInfo.s3_photo_url.file);
    }

    Service.post("authorChangeData", formData)
      .then((response) => {
        if (response.data.success) {
          Service.getSearch("authors", filterParams)
            .then((searchResponse) => {
              dispatch(fetchActionAuthors(searchResponse.data));
              this.setState({ isUploading: false });
              this.showNotification();
            })
            .catch((e) => {
              console.log(e);
            });
        }
      })
      .catch((e) => {
        this.setState({ isUploading: false });
        console.log(e);
      });
  };

  onChooseImage = (event) => {
    const fieldName = "s3_photo_url";
    const authorInfo = { ...this.state.authorInfo };
    authorInfo[fieldName] = event;
    this.setState({ authorInfo });
  };

  onCancel = () => {
    const { history } = this.props;
    const {
      location: { state },
    } = history;
    history.push({
      pathname: "/module/authors",
      state: {
        pageOfItems: state && state.pageOfItems,
        filterValue: state && state.filterValue,
      },
    });
  };

  toggle = async (tab) => {
    const { activeTab, selectedAuthor } = this.state;
    if (activeTab !== tab) {
      await this.setState({
        activeTab: tab,
        isCreateMediaRef: false,
        isEditMediaRef: false,
      });
      if (tab === "pending_medias") {
        await this.setState({
          isPublicMedia: "none",
        });
        await this.getMediaList(selectedAuthor.id);
      } else {
        await this.setState({
          isPublicMedia: true,
        });
        await this.getMediaList(selectedAuthor.id);
      }
    }
  };

  viewMediaContent = (mediaURL) => {
    window.open(mediaURL);
  };

  toggleDetail = (media) => {
    const { history } = this.props;
    const {
      location: { state },
    } = history;
    const author = state && state.selectedAuthor;
    const { activeTab } = this.state;
    history.push({
      pathname: "/module/medias/edit",
      state: {
        pageOfItems: 1,
        filterValue: {},
        media: media,
        isFromAuthor: {
          activeTab,
          selectedAuthor: author,
        },
      },
    });
  };

  handleDeleteMedia = (media) => {
    const { selectedAuthor } = this.state;
    const mediaId = media.id;
    this.setState({ isLoadingDelete: true });
    Service.remove("medias", mediaId)
      .then((response) => {
        if (response.data.success) {
          this.getMediaList(selectedAuthor.id);
          this.setState({ isLoadingDelete: false });
          this.showNotification();
        }
      })
      .catch((e) => {
        console.log(e);
        this.setState({ isLoadingDelete: false });
      });
  };

  handlePublicMedia = (media) => {
    const data = {
      media_id: media.id,
      is_public: media.is_public ? 0 : 1,
    };
    this.setState({ isLoadingPublic: true });
    const { selectedAuthor } = this.state;
    Service.post("user/publicMedia", data)
      .then(async (response) => {
        if (response.data.success) {
          this.getMediaList(selectedAuthor.id);
          this.setState({ isLoadingPublic: false });
          this.showNotification();
        }
      })
      .catch((e) => {
        console.log(e);
        this.setState({ isLoadingPublic: false });
      });
  };

  render() {
    const { t, pristine } = this.props;
    const {
      isUploading,
      activeTab,
      mediaList,
      selectedAuthor,
      permissionsMedia,
      isLoadingDelete,
      isLoadingPublic,
    } = this.state;
    return (
      <Col md={12}>
        <Card>
          <CardBody>
            <div className="profile__card tabs tabs--bordered-bottom">
              <div className="tabs__wrap">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === "author_info",
                      })}
                      onClick={() => this.toggle("author_info")}
                    >
                      {t("AUTHOR.ABOUT_AUTHOR")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === "approved_medias",
                      })}
                      onClick={() => this.toggle("approved_medias")}
                    >
                      {t("MEDIAS.PUBLIC_MEDIAS")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === "pending_medias",
                      })}
                      onClick={() => this.toggle("pending_medias")}
                    >
                      {t("MEDIAS.PENDIGN_MEDIAS")}
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent
                  style={{ overflow: "hidden" }}
                  activeTab={activeTab}
                >
                  <TabPane tabId="author_info">
                    <AuthorInfo
                      isUploading={isUploading}
                      onEditAuthor={() => this.onEditAuthor()}
                      onChooseImage={this.onChooseImage}
                      onTextInput={this.onTextInput}
                      onCancel={this.onCancel}
                    />
                  </TabPane>

                  <TabPane tabId="approved_medias">
                    <div className="card__title">
                      <h5 className="bold-text">{`${t(
                        "AUTHOR.ALL_MEDIAS_OF"
                      )} ${selectedAuthor.fullname} :`}</h5>
                    </div>
                    <Row>
                      {mediaList.data &&
                        !_.isEmpty(mediaList.data) &&
                        mediaList.data.map((media) => (
                          <Col
                            key={media.id.toString()}
                            md={12}
                            xl={4}
                            lg={6}
                            sm={12}
                          >
                            <AuthorMedia
                              permissions={permissionsMedia}
                              media={media}
                              isLoadingDelete={isLoadingDelete}
                              isLoadingPublic={isLoadingPublic}
                              viewMediaContent={this.viewMediaContent}
                              viewDetail={this.toggleDetail}
                              handleDeleteMedia={this.handleDeleteMedia}
                              handlePublicMedia={this.handlePublicMedia}
                            />
                          </Col>
                        ))}
                      {_.isEmpty(mediaList.data) && (
                        <Col md={12}>
                          <h5 className="text-center p-3">
                            {t("GENERAL.EMPTY_DATA")}
                          </h5>
                        </Col>
                      )}
                    </Row>
                  </TabPane>
                  <TabPane tabId="pending_medias">
                    <div className="card__title">
                      <h5 className="bold-text">{`${t(
                        "AUTHOR.ALL_MEDIAS_OF"
                      )} ${selectedAuthor.fullname} :`}</h5>
                    </div>
                    <Row>
                      {mediaList.data &&
                        !_.isEmpty(mediaList.data) &&
                        mediaList.data.map((media) => (
                          <Col
                            key={media.id.toString()}
                            md={12}
                            xl={4}
                            lg={6}
                            sm={12}
                          >
                            <AuthorMedia
                              permissions={permissionsMedia}
                              media={media}
                              isLoadingDelete={isLoadingDelete}
                              isLoadingPublic={isLoadingPublic}
                              viewMediaContent={this.viewMediaContent}
                              viewDetail={this.toggleDetail}
                              handleDeleteMedia={this.handleDeleteMedia}
                              handlePublicMedia={this.handlePublicMedia}
                            />
                          </Col>
                        ))}
                      {_.isEmpty(mediaList.data) && (
                        <Col md={12}>
                          <h5 className="text-center p-3">
                            {t("GENERAL.EMPTY_DATA")}
                          </h5>
                        </Col>
                      )}
                    </Row>
                  </TabPane>
                </TabContent>
              </div>
            </div>
            <Col md={12} lg={12} xl={12}>
              <div className="d-flex justify-content-end">
                {activeTab === "author_info" && (
                  <Button
                    color="primary"
                    disabled={pristine || isUploading}
                    onClick={() => this.onEditAuthor()}
                  >
                    {isUploading ? (
                      <Spinner animation="border" variant="primary" size="sm" />
                    ) : (
                      t("BUTTON.EDIT")
                    )}
                  </Button>
                )}
                <Button
                  type="button"
                  onClick={this.onCancel}
                  disabled={isUploading}
                >
                  {t("BUTTON.BACK")}
                </Button>
              </div>
            </Col>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

const mapDispatchToProps = {
  fetchActionAuthors,
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

AuthorDetail = reduxForm({
  shouldValidate: () => true, // to alway validation form even user cancel the process
  form: "edit_author_form",
  validate,
  enableReinitialize: true,
  destroyOnUnmount: false,
  keepDirtyOnReinitialize: true,
})(AuthorDetail);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation("common")(AuthorDetail)));
