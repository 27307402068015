import React, { PureComponent } from "react";
import { Spinner, Col, CardTitle } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import { withTranslation } from "react-i18next";
import { HiOutlineMail } from "react-icons/hi";
import { Button, Alert } from "reactstrap";
import NotificationSystem from "rc-notification";
import { BasicNotification } from "../../../../../components/Notification";
import firebase from "../../../../../config/firebase";
import Service from "../../../../../config/Service";
import { connect } from "react-redux";
import Image, { Shimmer } from "react-shimmer";
import { PlaceholderProfile } from "../../../../../shared/img";
import { getUserDetail } from "../../../../../redux/actions/authActions";

let notification = null;

class VerifyPhoneNumber extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      isUploading: false,
      resendCode: false,
    };
    props.initialize();
  }

  componentDidMount() {
    NotificationSystem.newInstance(
      { style: { top: 10 } },
      (n) => (notification = n)
    );
  }

  showNotification = (title, message) => {
    notification.notice({
      content: (
        <BasicNotification title={title} color="primary" message={message} />
      ),
      duration: 5,
      closable: true,
      style: { top: 0, left: "calc(100vw - 100%)" },
      className: `right-up right-support`,
    });
  };

  onTextInput = (event) => {
    const fieldName = event.target.name;
    const value = event.target.value;
    this.setState({ [fieldName]: value });
  };

  setUpRecaptcha = () => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
      }
    );
  };

  resendComfirmPhoneCode = (event) => {
    event.preventDefault();
    this.setState({ resendCode: true, isUploading: true });
    const { userData, t } = this.props;
    this.setUpRecaptcha();

    const appVerifier = window.recaptchaVerifier;
    firebase
      .auth()
      .signInWithPhoneNumber("+855" + parseInt(userData.phone), appVerifier)
      .then((confirmationResult) => {
        this.setState({ resendCode: false, isUploading: false });
        window.confirmationResult = confirmationResult;
        this.showNotification(
          t("SEND_SMS_CODE.NOTIFICATION"),
          t("SEND_SMS_CODE.SEND_SMS_CODE_SUCCESSFULLY")
        );
      })
      .catch((error) => {
        this.setState({ resendCode: false, isUploading: false });
        this.setState({ errorMessage: error.message });
      });
  };

  checkSMSCode = () => {
    const { t } = this.props;
    const { code } = this.state;
    if (!code) {
      this.setState({ errorMessage: t("SEND_SMS_CODE.PLEASE_INPUT_SMS_CODE") });
      return;
    }
    this.setState({ isUploading: true });

    window.confirmationResult
      .confirm(code)
      .then((result) => {
        this.saveData();
      })
      .catch((error) => {
        this.setState({ errorMessage: error.message });
        this.setState({
          isUploading: false,
        });
      });
  };

  saveData = () => {
    const { userData } = this.props;

    this.setState({ isUploading: true });

    const data = {
      id: userData.id,
      fullname: userData.fullname,
      fullname_en: userData.fullname_en,
      phone: userData.phone,
      email: userData.email,
    };

    const formData = new FormData();
    for (const [key, value] of Object.entries(data)) {
      formData.append(key, value);
    }

    if (userData.s3_photo_url) {
      formData.append("s3_photo_file", userData.s3_photo_url);
    }

    this.changeUserData(formData);
  };

  changeUserData = (formData) => {
    const { t } = this.props;
    Service.post("user/userChangeData", formData)
      .then((response) => {
        if (response.status === 200) {
          this.setState({ isUploading: false });
          this.getUser();
          this.props.toggleModal();
          this.showNotification(
            t("SEND_SMS_CODE.NOTIFICATION"),
            t("GENERAL.THE_ACTION_HAS_BEEN_COMPLETED_SUCCESSFULLY")
          );
        }
      })
      .catch((error) => {
        this.setState({ errorMessage: error.message });
      });
  };

  getUser = () => {
    const { dispatch } = this.props;
    Service.getAll("user/getUser")
      .then((response) => {
        if (response.data.success) {
          const user = response.data.data;
          dispatch(getUserDetail(user));
          this.setState({ user });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  onCancel = () => {
    this.props.toggleModal();
  };

  render() {
    const { t, user } = this.props;
    const { isUploading, errorMessage } = this.state;
    return (
      <div className="text-center p-3">
        <div className="justify-content-md-center row">
          <div className="account__avatar">
            <Image
              src={user.s3_photo_url || PlaceholderProfile}
              alt="avatar"
              style={{ objectFit: "cover" }}
              fallback={<Shimmer width={300} height={300} />}
            />
          </div>
        </div>
        <CardTitle style={{ fontSize: 16 }} className="mb-1 mt-1">
          {user.fullname}
        </CardTitle>
        <form className="form login-form">
          <Alert color="danger" isOpen={!!errorMessage}>
            {errorMessage}
          </Alert>
          <Col md={12} sm={12}>
            <div className="form__form-group">
              <span className="form__form-group-label">
                {t("REGISTER.CODE_SMS")}
                <span className="red-text">*</span>
              </span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <HiOutlineMail />
                </div>
                <Field
                  name="code"
                  component="input"
                  type="number"
                  required
                  placeholder="xxxxxx"
                  className="input-without-border-radius"
                  onChange={this.onTextInput}
                />
              </div>
              <div
                onClick={(event) => this.resendComfirmPhoneCode(event)}
                className=" mt-3 mb-3 form__form-group"
                style={{ display: "block" }}
              >
                <p style={{ color: "#1183CA", fontSize: 14 }}>
                  {t("REGISTER.PLEASE_CHECK_SMS_CODE_IN_MOBILE_PHONE")}
                </p>
                <a
                  href=""
                  style={{
                    textDecoration: "underline",
                    color: "#CE902C",
                    fontSize: 12,
                  }}
                >
                  {t("REGISTER.RESEND_SMS_CODE")}
                </a>
              </div>
            </div>
          </Col>
          <Col md={12}>
            <div className="d-flex justify-content-end">
              <Button
                type="button"
                onClick={this.onCancel}
                disabled={isUploading}
              >
                {t("BUTTON.CANCEL")}
              </Button>
              <Button color="primary" onClick={this.checkSMSCode}>
                {isUploading ? (
                  <Spinner size="sm" animation="border" varaint="primary" />
                ) : (
                  t("BUTTON.EDIT")
                )}
              </Button>
            </div>
          </Col>
          <div id="recaptcha-container" />
        </form>
      </div>
    );
  }
}

VerifyPhoneNumber = reduxForm({
  form: "verify_phone_update_user",
})(VerifyPhoneNumber);

const mapStateToProps = (state) => {
  return {
    user: state.user.detail,
  };
};

export default connect(mapStateToProps)(
  withTranslation("common")(VerifyPhoneNumber)
);
