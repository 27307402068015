import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import LoadingView from "../../../components/LoadingView/LoadingView";
import ButtonModal from "../../DeleteModal/Modal";
import CustomPagination from "../../../components/CustomPagination/CustomPagination";
import NotificationSystem from "rc-notification";
import { BasicNotification } from "../../../components/Notification";
import { fetchSelectedMedias } from "../../../redux/actions/fetchApiActions";
import MediaCard from "./MediaCard";
import Service from "../../../config/Service";
import _ from "lodash";

let notification = null;
class AllMedia extends PureComponent {
  constructor() {
    super();
    this.state = {
      pageOfItems: 1,
      itemsPerPage: 12,
    };
  }

  componentDidMount() {
    NotificationSystem.newInstance(
      { style: { top: 65 } },
      (n) => (notification = n)
    );
  }

  showNotification = (rtl) => {
    const { t } = this.props;
    notification.notice({
      content: (
        <BasicNotification
          title={t("NOTIFICATION.SUBMITTED")}
          color="primary"
          message={t("NOTIFICATION.THANK_YOU_FOR_YOUR_FEEDBACK")}
        />
      ),
      duration: 5,
      closable: true,
      style: { top: 0, left: "calc(100vw - 100%)" },
      className: `right-up ${rtl}-support`,
    });
  };

  toggleDetail = (media) => {
    const { dispatch, history, page, filterValue, activeTab } = this.props;
    Service.get("getDetailMedia", media.id)
      .then((response) => {
        if (response.data.success) {
          const selectedMedia = response.data.data;
          dispatch(fetchSelectedMedias(selectedMedia));
          history.push({
            pathname: "/module/medias/edit",
            state: {
              pageOfItems: page,
              filterValue: filterValue,
              media: selectedMedia,
              activeTab: activeTab,
            },
          });
        }
      })
      .catch((error) => console.log("error", error));
  };

  // changeRating = () => {};

  viewMediaContent = (mediaURL) => {
    window.open(mediaURL);
  };

  render() {
    const {
      t,
      allMedias,
      itemsPerPage,
      isFetchingMedias,
      onChangePage,
      page,
      permissions,
    } = this.props;
    return (
      <div className="media">
        <Card className="media__margin-content">
          {isFetchingMedias ? (
            <LoadingView />
          ) : (
            <CardBody className="p-0">
              <Row>
                {allMedias.data &&
                  !_.isEmpty(allMedias.data) &&
                  allMedias.data.map((media) => (
                    <Col
                      key={media.id.toString()}
                      md={12}
                      xl={4}
                      lg={6}
                      sm={12}
                    >
                      <div className="media__card-wrapper">
                        <MediaCard
                          media={media}
                          permissions={permissions}
                          // changeRating={this.changeRating}
                          viewMediaContent={this.viewMediaContent}
                        />
                        <div className="media__btn-wrapper">
                          {permissions.approve && (
                            <div style={{ marginRight: 15 }}>
                              <ButtonModal
                                btnOutline
                                color="primary"
                                size="sm"
                                title={t(
                                  media.is_public
                                    ? "MEDIAS.WOULD_YOU_LIKE_TO_UNPUBLIC_THIS_MEDIA"
                                    : "MEDIAS.WOULD_YOU_LIKE_TO_PUBLIC_THIS_MEDIA"
                                )}
                                btn={t(
                                  media.is_public
                                    ? "BUTTON.UNPUBLIC"
                                    : "BUTTON.PUBLIC"
                                )}
                                message={media.title}
                                actionKey="approve"
                                actionModule="public_unpublic_medias"
                                actionId={media.id}
                                page={page}
                                isPublic={media.is_public ? "true" : "none"}
                                data={{
                                  media_id: media.id,
                                  is_public: media.is_public ? 0 : 1,
                                }}
                              />
                            </div>
                          )}
                          {permissions.edit && !media.is_public && (
                            <Button
                              size="sm"
                              color="primary"
                              onClick={() => this.toggleDetail(media)}
                            >
                              {t("BUTTON.EDIT")}
                            </Button>
                          )}
                          {permissions.delete && !media.is_public && (
                            <ButtonModal
                              isAbleToDelete={!media.is_public}
                              unableToDeleteMessage={t(
                                "MEDIAS.'PUBLIC_MEDIA_CANNOT_DELETE'"
                              )}
                              color="danger"
                              size="sm"
                              title={t(
                                "GENERAL.DO_YOU_WANT_TO_DELETE_THIS_RECORD"
                              )}
                              btn={t("BUTTON.DELETE")}
                              isPublic={media.is_public ? "true" : "none"}
                              message={media.title}
                              actionKey="delete"
                              actionModule="medias"
                              actionId={media.id}
                              page={page}
                            />
                          )}
                        </div>
                      </div>
                    </Col>
                  ))}
                {allMedias.data && _.isEmpty(allMedias.data) && (
                  <Col md={12}>
                    <h5 className="text-center p-3">
                      {t("GENERAL.EMPTY_DATA")}
                    </h5>
                  </Col>
                )}
              </Row>
              <div className="d-flex justify-content-end">
                <CustomPagination
                  itemsCount={allMedias.data && allMedias.total}
                  itemsToShow={itemsPerPage}
                  onChangePage={onChangePage}
                  page={page}
                />
              </div>
            </CardBody>
          )}
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    theme: state.theme,
    rtl: state.rtl,
    allMedias: state.fetchData.FETCH_MEDIAS,
  };
}

export default connect(mapStateToProps)(
  withRouter(withTranslation("common")(AllMedia))
);
