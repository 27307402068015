import React from "react";
import { CardTitle, CardText, Button } from "reactstrap";
import StarRatings from "react-star-ratings";
import { BsPeopleCircle } from "react-icons/bs";
import { AiOutlineBarcode, AiFillCalendar, AiFillTags } from "react-icons/ai";
import { withTranslation } from "react-i18next";
import moment from "moment";
import ButtonModal from "../../../DeleteModal/Modal";
import { FaPlay } from "react-icons/fa";
import Utilities from "../../../../shared/helpers/Utilities";

const MediaList = ({ t, media, viewDetail, viewMediaContent, user }) => {
  // const permissionMedia = Utilities.getPermission(user, "medias");
  return (
    <div className="media justify-content-start">
      <div className="media__margin-content">
        <div className="media__card-wrapper">
          <div className="media__card-row">
            {media.media_type === "mp4" ? (
              <div
                className="media__btn-cover"
                onClick={() => viewMediaContent(media.s3_media_url)}
              >
                <div className="media__cover-overlay">
                  <FaPlay color="white" className="media-play-icon" />
                </div>
                <img
                  className="media__cover"
                  src={media.s3_cover_media_url}
                  alt={media.title}
                />
              </div>
            ) : (
              <Button
                className="media__btn-cover"
                onClick={() => viewMediaContent(media.s3_media_url)}
              >
                <img
                  className="media__cover"
                  src={media.s3_cover_media_url}
                  alt={media.title}
                />
              </Button>
            )}

            <div className="media__text-column">
              <CardTitle className="mb-0 ellipsis-text">
                {media.title}
              </CardTitle>
              <StarRatings
                rating={media.like_value_avg}
                starHoverColor="#ffa900"
                starRatedColor="#ffa900"
                numberOfStars={5}
                starDimension="20px"
                starSpacing="1px"
                name={media.id}
              />
              <CardText>
                <span>
                  <BsPeopleCircle />{" "}
                </span>
                {media.author_fullname}
              </CardText>
              <CardText className="ellipsis-text">
                <span>
                  <AiFillTags />{" "}
                </span>
                {media.category_name}
              </CardText>
              <CardText>
                <span>
                  <AiFillCalendar />{" "}
                </span>
                {moment(media.created_at).format("DD MMMM YY")}
              </CardText>
              <CardText className="ellipsis-text">
                <span>
                  <AiOutlineBarcode />{" "}
                </span>
                {media.isbn}
              </CardText>
            </div>
          </div>
          <div className="media__btn-wrapper">
            {/* {permissionMedia.approve && (
              <div style={{ marginRight: 15 }}>
                <ButtonModal
                  btnOutline
                  color="primary"
                  size="sm"
                  title={t(
                    media.is_public
                      ? "MEDIAS.WOULD_YOU_LIKE_TO_UNPUBLIC_THIS_MEDIA"
                      : "MEDIAS.WOULD_YOU_LIKE_TO_PUBLIC_THIS_MEDIA"
                  )}
                  btn={t(media.is_public ? "BUTTON.UNPUBLIC" : "BUTTON.PUBLIC")}
                  message={media.title}
                  actionKey="approve"
                  actionModule="public_unpublic_medias"
                  actionId={media.id}
                  page={1}
                  data={{
                    media_id: media.id,
                    is_public: media.is_public ? 0 : 1,
                  }}
                />
              </div>
            )} */}
            <Button size="sm" color="primary" onClick={() => viewDetail(media)}>
              {t("BUTTON.DETIAL")}
            </Button>
            {/* <ButtonModal
              isAbleToDelete={!media.is_public}
              unableToDeleteMessage={t("MEDIAS.PUBLIC_MEDIA_CANNOT_DELETE")}
              color="danger"
              size="sm"
              title={t("GENERAL.DO_YOU_WANT_TO_DELETE_THIS_RECORD")}
              btn={t("BUTTON.DELETE")}
              message={media.title}
              actionKey="delete"
              actionModule="medias"
              actionId={media.id}
              page={1}
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation("common")(MediaList);
