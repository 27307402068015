import _, { forEach } from "lodash";

const getUniqueListBy = (originalArray, prop) => {
  let newArray = [];
  let lookupObject = {};

  for (var i in originalArray) {
    lookupObject[originalArray[i][prop]] = originalArray[i];
  }

  for (i in lookupObject) {
    newArray.push(lookupObject[i]);
  }
  return newArray;
};

const clearArray = (originalArray) => {
  while (originalArray.length) {
    originalArray.pop();
  }
};

const removeFromArrayOfHash = (p_array_of_hash, p_key, p_value_to_remove) => {
  return p_array_of_hash.filter((l_cur_row) => {
    return l_cur_row[p_key] !== p_value_to_remove;
  });
};

const createFormData = (dataArr) => {
  const data = new FormData();
  _.map(dataArr, (item) => {
    data.append(item.field, item.value);
  });
  return data;
};

const allRolesOptions = (t) => {
  return [
    {
      label: "---",
      value: "---",
    },
    {
      label: t("GENERAL.USER"),
      value: "0",
    },
    {
      label: t("GENERAL.AUTHOR"),
      value: "3",
    },
    {
      label: t("GENERAL.ADMIN"),
      value: "1",
    },
    {
      label: t("GENERAL.SUPERADMIN"),
      value: "2",
    },
  ];
};

const adminRolesOptions = (t) => {
  return [
    {
      label: t("GENERAL.AUTHOR"),
      value: "3",
    },
    {
      label: t("GENERAL.ADMIN"),
      value: "1",
    },
    {
      label: t("GENERAL.SUPERADMIN"),
      value: "2",
    },
  ];
};

const inArray = (elem, array) => {
  if (array.indexOf) {
    return array.indexOf(elem) > -1;
  }

  for (var i = 0, length = array.length; i < length; i++) {
    if (array[i] === elem) {
      return true;
    }
  }
  return false;
};

const concatString = (fieldNames) => {
  let fieldName = "";
  _.map(fieldNames, (item) => {
    if (item !== false) {
      fieldName = fieldName.concat(item.trim(), ",");
    }
  });
  return fieldName;
};

const getMatch = (permissionArr, Module) => {
  var matches = [];
  for (var i = 0; i < permissionArr.length; i++) {
    for (var e = 0; e < Module.length; e++) {
      if (permissionArr[i] === Module[e].key) matches.push(Module[e]);
    }
  }
  return matches;
};

const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const getPermission = (user, type) => {
  let key = "";
  switch (type) {
    case "medias":
      key = "BAu2DgRxQYCV9NBgn7yj";
      break;
    case "categories":
      key = "GXJyvs92fVBU3mk8DhzN";
      break;
    case "authors":
      key = "W3gNfqS9PpH2bFUnELsd";
      break;
    case "publishers":
      key = "d4xE9mATbjsZzRkVs2u5";
      break;
    case "publishyears":
      //key = "KRHw4nDbnKGsLFpE544q";
      key = "d4xE9mATbjsZzRkVs2u5";
      break;
    case "systemusers":
      key = "3Jprb7khmADSEgwGNjwJ";
      break;

    default:
      break;
  }
  const userLocalStorage = JSON.parse(localStorage.getItem("user"));
  if (!_.isEmpty(userLocalStorage)) {
    const { action_controls } = userLocalStorage;
    if (action_controls) {
      const modulePermission = action_controls.filter(
        (item) => item.module_key === key
      );
      if (modulePermission) {
        let permissions = {};
        forEach(modulePermission[0].control_modules, (element) => {
          permissions[element.action_type.toLowerCase()] = element.is_checked;
        });
        return permissions;
      }
      return {};
    }
    return {};
  }
  return {};
};

export default {
  inArray,
  getUniqueListBy,
  clearArray,
  removeFromArrayOfHash,
  createFormData,
  allRolesOptions,
  adminRolesOptions,
  concatString,
  getMatch,
  capitalize,
  getPermission,
};
