import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import TableView from "./components/TableUser";
import FilterView from "./components/FilterUser";
import Service from "../../config/Service";
import { fetchActionUsers } from "../../redux/actions/fetchApiActions";
import LoadingView from "../../components/LoadingView/LoadingView";
import Utilities from "../../shared/helpers/Utilities";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewComponent: false,
      pageOfItems: 1,
      itemsPerPage: 12,
      isFetchingData: true,
      permissions: null,
      filterValue: null,
    };
  }
  componentDidMount() {
    this.checkPermissionAction();

    const {
      location: { state },
    } = this.props.history;

    this.setState(
      {
        pageOfItems: (state && state.pageOfItems) || 1,
        filterValue: (state && state.filterValue) || {},
      },
      () => {
        this.getUserList();
      }
    );
  }

  checkPermissionAction = () => {
    const permissionData = Utilities.getPermission(
      this.props.user,
      "systemusers"
    );
    this.setState({
      permissions: permissionData,
    });
  };

  getUserList = () => {
    const { pageOfItems, itemsPerPage, filterValue } = this.state;
    let filterParams = "";

    if (filterValue) {
      const name = filterValue.filter_name || "";
      const email = filterValue.filter_email || "";
      const phone = filterValue.filter_phone || "";
      const role_id = filterValue.role_id ? filterValue.role_id.value : "";
      filterParams = `perPage=${itemsPerPage}&page=${pageOfItems}&name=${name}&phone=${phone}&email=${email}&role_id=${role_id}`;
    } else {
      filterParams = `perPage=${itemsPerPage}&page=${pageOfItems}`;
    }

    this.getSearchUser(filterParams);
  };

  refreshAfterCreate = () => {
    const { itemsPerPage } = this.state;
    this.setState({ pageOfItems: 1 });
    const filterParams = `perPage=${itemsPerPage}`;
    this.getSearchUser(filterParams);
  };

  toggleFilter = (value) => {
    const { itemsPerPage } = this.state;
    const name = value.filter_name || "";
    const email = value.filter_email || "";
    const phone = value.filter_phone || "";
    const role_id = value.role_id ? value.role_id.value : "";

    const filterParams = `perPage=${itemsPerPage}&name=${name}&phone=${phone}&email=${email}&role_id=${role_id}`;

    this.setState({ filterValue: value, pageOfItems: 1 });

    this.getSearchUser(filterParams);
  };

  getSearchUser = (filterParams) => {
    const { dispatch } = this.props;

    Service.getSearch("users", filterParams)
      .then((response) => {
        if (response) {
          this.setState({
            isFetchingData: false,
          });
          dispatch(fetchActionUsers(response.data));
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  onChangePage = async (pageOfItems) => {
    if (pageOfItems) {
      await this.setState({ pageOfItems });
      await this.getUserList();
    }
  };

  render() {
    const { t, theme, rtl } = this.props;
    const {
      pageOfItems,
      itemsPerPage,
      isFetchingData,
      permissions,
      filterValue,
    } = this.state;
    if (isFetchingData) {
      return <LoadingView />;
    } else {
      return (
        <Container className="todo-app">
          <Row>
            <Col md={12}>
              <h3 className="page-title">
                {t("GENERAL.SYSTEM_USER_MANAGEMENT")}
              </h3>
            </Col>
          </Row>
          <Row>
            <FilterView
              theme={theme}
              rtl={rtl.direction}
              addView={this.addView}
              toggleFilter={this.toggleFilter}
              permissions={permissions}
              filterValue={filterValue}
              pageOfItems={pageOfItems}
              itemsPerPage={itemsPerPage}
              refreshAfterCreate={this.refreshAfterCreate}
            />
          </Row>
          <Row>
            <TableView
              onChangePage={this.onChangePage}
              pageOfItems={pageOfItems}
              itemsPerPage={itemsPerPage}
              permissions={permissions}
              filterValue={filterValue}
            />
          </Row>
        </Container>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.theme,
    rtl: state.rtl,
    user: state.user,
  };
};

export default connect(mapStateToProps)(withTranslation("common")(Index));
