import React from "react";
import { NavLink } from "react-router-dom";
import { withTranslation } from "react-i18next";

const ContactUs = (props) => {
  const { t } = props;
  const image = {
      width: "30%",
      textAlign : "center"
    };
  const textCenter = {
    textAlign : "center"
  }
  return (
    <div className="account account--not-photo">
      <div className="account__wrapper">
          <ul class="nav">
            <li class="nav-item">
              <a class="nav-link active" href="/login">Login</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/Contact_Us">Contact Us</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/Privacy">Privacy</a>
            </li>
          </ul>
        <div className="account__card" style={textCenter}>
          <img style={image}  src={process.env.PUBLIC_URL + '/mptc-logo.png'} /> 
          <h3 className="account__title">
            <span className="account__logo"> {t("contact_us.mptc")}</span>
          </h3> <br/>
          <h4 className="account__title">
            <span className="account__logo"> {t("contact_us.general_department")}</span>
          </h4> <br/>
          <h4 className="account__title">
            <span className="account__logo"> {t("contact_us.software")}</span>
          </h4> <br/>
          <h5 >
            <span> {t("contact_us.d-library")}</span>
          </h5> <br/>
          <h5 >
            <span > {t("contact_us.mr_phat")}</span>
          </h5> <br/>
          <h5 >
            <span> {t("contact_us.mr_phat_phone")}</span>
          </h5> <br/>
          <h5>
            <span> {t("contact_us.mr_phat_email")}</span>
          </h5> <br/>
        </div>
      </div>
    </div>

  );
};

export default withTranslation("common")(ContactUs);
