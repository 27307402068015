import React from "react";
import { Route, Switch } from "react-router-dom";
import { Redirect } from "react-router-dom";
import MediaLists from "../../../Media/index";
import EditMedia from "../../../Media/components/EditMedia";
import CreateMedia from "../../../Media/components/CreateMedia";
import NotFound404 from "../../../DefaultPage/404page";

export default () => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (!user) {
    return <Redirect push to="/login" />;
  } else if (user) {
    const permissions = user.permissions.split(",");
    if (!permissions.includes("BAu2DgRxQYCV9NBgn7yj")) {
      return <Redirect push to="/module/permission" />;
    } else {
      return (
        <Switch>
          <Route path="/module/medias" component={MediaLists} exact />
          <Route path="/module/medias/edit" component={EditMedia} />
          <Route path="/module/medias/create" component={CreateMedia} exact />
          <Route component={NotFound404} />
        </Switch>
      );
    }
  }
};
