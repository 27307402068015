import React, { PureComponent } from "react";
import { Button, ButtonToolbar, Card, CardBody, Col, Table } from "reactstrap";
import { connect } from "react-redux";
import Modal from "../../DeleteModal/Modal";
import Pagination from "../../../components/CustomPagination/CustomPagination";
import { withTranslation } from "react-i18next";
import _ from "lodash";

class TableCollection extends PureComponent {
  constructor() {
    super();
    this.state = {};
  }

  toggleDetailView = (obj) => {
    this.props.detailView(obj);
  };

  render() {
    const {
      t,
      publishers,
      onChangePage,
      pageOfItems,
      itemsPerPage,
      filterValue,
      permissions,
    } = this.props;
    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <Table className="table--bordered">
              <thead>
                <tr>
                  <th>{t("GENERAL.NUMBER")}</th>
                  <th>{t("GENERAL.NAME")}</th>
                  <th className="text-center">{t("GENERAL.ACTION")}</th>
                </tr>
              </thead>
              <tbody>
                {publishers.data &&
                  !_.isEmpty(publishers.data) &&
                  publishers.data.map((item, i) => (
                    <tr key={i.toString()}>
                      <td>{i + 1 + (pageOfItems - 1) * itemsPerPage}</td>
                      <td>
                        <p className="project-member__name">{item.name}</p>
                        <p className="project-member__name">{item.name_en}</p>
                      </td>
                      <td>
                        <ButtonToolbar className="invoice__toolbar">
                          {permissions.view && (
                            <Button
                              onClick={() => this.toggleDetailView(item)}
                              color="primary"
                              size="sm"
                            >
                              {t("GENERAL.DETAIL")}
                            </Button>
                          )}
                          {permissions.delete && (
                            <Modal
                              isAbleToDelete={item.count_link_to_medias === 0}
                              unableToDeleteMessage={t(
                                "GENERAL.ITEM_CANNOT_DELETE"
                              )}
                              color="danger"
                              size="sm"
                              title={t(
                                "GENERAL.DO_YOU_WANT_TO_DELETE_THIS_RECORD"
                              )}
                              btn={t("BUTTON.DELETE")}
                              message={item.name}
                              actionKey="delete"
                              actionModule="publishers"
                              actionId={item.id}
                              page={pageOfItems}
                              itemsCount={publishers.data && publishers.total}
                              itemsPerPage={itemsPerPage}
                              filterValue={filterValue}
                            />
                          )}
                        </ButtonToolbar>
                      </td>
                    </tr>
                  ))}
                {_.isEmpty(publishers.data) && (
                  <tr>
                    <td
                      style={{ textAlign: "center", paddingTop: "50px" }}
                      colspan="4"
                    >
                      <h5>{t("GENERAL.EMPTY_DATA")}</h5>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            <div className="d-flex justify-content-end">
              <Pagination
                itemsCount={publishers.data && publishers.total}
                itemsToShow={itemsPerPage}
                page={pageOfItems}
                onChangePage={onChangePage}
              />
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.theme,
    rtl: state.rtl,
    publishers: state.fetchData.FETCH_PUBLISHERS,
  };
};
export default connect(mapStateToProps)(
  withTranslation("common")(TableCollection)
);
