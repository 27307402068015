import React from "react";
import { Route, Switch } from "react-router-dom";
import { Redirect } from "react-router-dom";
import DetailAuthor from "../../../Author/components/AuthorDetail";
import AuthorList from "../../../Author/index";
import NotFound404 from "../../../DefaultPage/404page";

export default () => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (!user) {
    return <Redirect push to="/login" />;
  } else if (user) {
    const permissions = user.permissions.split(",");
    if (!permissions.includes("W3gNfqS9PpH2bFUnELsd")) {
      return <Redirect push to="/module/permission" />;
    } else {
      return (
        <Switch>
          <Route path="/module/authors" component={AuthorList} exact />
          <Route
            path="/module/authors/:authorId/edit"
            component={DetailAuthor}
          />
          <Route component={NotFound404} />
        </Switch>
      );
    }
  }
};
