import React, { PureComponent } from "react";
import { Spinner, Col } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import { withTranslation } from "react-i18next";
import { HiOutlineMail } from "react-icons/hi";
import { Button, Alert } from "reactstrap";
import NotificationSystem from "rc-notification";
import { BasicNotification } from "../../../components/Notification";
import firebase from "../../../config/firebase";
import { withRouter } from "react-router";
import NewPasswordForm from "./NewPasswordForm";

let notification = null;

class VerifyPhoneForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      isUploading: false,
      resendCode: false,
      isVerifyCode: false,
    };
  }

  componentDidMount() {
    NotificationSystem.newInstance(
      { style: { top: 10 } },
      (n) => (notification = n)
    );
  }

  sendNotification = () => {
    return notification.notice({
      content: (
        <BasicNotification
          title="SMS code message"
          color="primary"
          message="Send phone code successfully."
        />
      ),
      duration: 5,
      closable: true,
      style: { top: 0, left: "calc(100vw - 100%)" },
      className: `right-up `,
    });
  };

  onTextInput = (event) => {
    const fieldName = event.target.name;
    const value = event.target.value;
    this.setState({ [fieldName]: value });
  };

  setUpRecaptcha = () => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
      }
    );
  };

  resendComfirmPhoneCode = (event) => {
    event.preventDefault();
    this.setState({ resendCode: true, isUploading: true });
    const { resetPasswordData } = this.props;
    this.setUpRecaptcha();

    const appVerifier = window.recaptchaVerifier;
    firebase
      .auth()
      .signInWithPhoneNumber(
        "+855" + parseInt(resetPasswordData.phone),
        appVerifier
      )
      .then((confirmationResult) => {
        this.setState({ resendCode: false, isUploading: false });
        window.confirmationResult = confirmationResult;
        this.sendNotification();
      })
      .catch((error) => {
        this.setState({
          resendCode: false,
          isUploading: false,
          errorMessage: error.message,
        });
      });
  };

  checkSMSCode = () => {
    const { t } = this.props;
    const { code } = this.state;
    if (!code) {
      this.setState({ errorMessage: t("SEND_SMS_CODE.PLEASE_INPUT_SMS_CODE") });
      return;
    }
    this.setState({
      isUploading: true,
      errorMessage: "",
    });
    window.confirmationResult
      .confirm(code)
      .then((result) => {
        console.log("result =>", result);
        this.setState({
          isVerifyCode: true,
          isUploading: false,
          errorMessage: "",
        });
      })
      .catch((error) => {
        this.setState({
          errorMessage: error.message,
          isUploading: false,
          isVerifyCode: false,
        });
      });
  };

  render() {
    const { t, resetPasswordData } = this.props;
    const { isUploading, errorMessage, isVerifyCode } = this.state;
    return (
      <Col md={12}>
        {!isVerifyCode && (
          <form className="form login-form">
            <Alert color="danger" isOpen={!!errorMessage}>
              {errorMessage}
            </Alert>
            <div className="form__form-group">
              <span className="form__form-group-label">
                {t("REGISTER.CODE_SMS")}
                <span className="red-text">*</span>
              </span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <HiOutlineMail />
                </div>
                <Field
                  name="code"
                  component="input"
                  type="number"
                  required
                  placeholder="xxxxxx"
                  className="input-without-border-radius"
                  onChange={this.onTextInput}
                />
              </div>
              <div onClick={this.resendComfirmPhoneCode} className="mt-3">
                <a
                  href=""
                  style={{
                    textDecoration: "underline",
                    color: "#CE902C",
                    fontSize: 12,
                  }}
                >
                  {t("REGISTER.RESEND_SMS_CODE")}
                </a>
              </div>
            </div>
            <Button
              className="account__btn"
              color="primary"
              onClick={this.checkSMSCode}
            >
              {isUploading ? (
                <Spinner size="sm" animation="border" varaint="primary" />
              ) : (
                t("BUTTON.VERIFY_SMS_CODE")
              )}
            </Button>
            <div id="recaptcha-container" />
          </form>
        )}
        {isVerifyCode && (
          <NewPasswordForm resetPasswordData={resetPasswordData} />
        )}
      </Col>
    );
  }
}

export default reduxForm({
  form: "reset_password_form",
})(withRouter(withTranslation("common")(VerifyPhoneForm)));
